import { toast } from "react-toastify";
import { initPasswordChange } from "../../api/common-api";
import UserStatus from "../UserStatus/UserStatus";
import GameContainer from "../layout/GameContainer";
import * as S from "./Settings.styles";
import { useTranslation } from "react-i18next";

const SettingsPage = () => {
  const handleInitChangePassword = async () => {
    await initPasswordChange()
      .then(() => toast.success("We've sent password change e-mail"))
      .catch(() => toast.info("Error occured"));
  };

  const { t } = useTranslation("translation", { keyPrefix: "payment" });

  return (
    <GameContainer backTo="/profile">
      <S.Wrapper>
        <S.ResetButtonContainer>
          <S.ResetButton onClick={() => handleInitChangePassword()}>
            {t("changePassword")}
          </S.ResetButton>
        </S.ResetButtonContainer>
        <UserStatus />
      </S.Wrapper>
    </GameContainer>
  );
};

export default SettingsPage;
