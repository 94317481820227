import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 10px 20px;
  border-radius: 24px;
  position: relative;
  gap: 16px;
  max-width: 500px;
  overflow-y: auto;
  min-height: 300px;
  @media (min-width: 768px) and (min-height: 600px) {
    padding: 20px 40px;
    gap: 32px;
    max-width: 1000px;
    overflow-y: initial;
  }
  @media (min-width: 1200px) and (min-height: 1024px) {
    padding: 40px 80px;
    overflow-y: initial;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
  justify-content: center;
`;

export const Label = styled.label`
  font-family: Nunito;
  font-size: ${(props) => props.theme.labelFontSize};
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  min-width: 140px;
  word-break: break-all;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Title = styled.h2`
  margin: 0;
  font-family: Nunito;
  font-size: ${(props) => props.theme.mobileHeaderFontSize};
  font-weight: 900;
  line-height: 43px;
  text-align: center;
  @media (min-height: 600px) {
    font-size: ${(props) => props.theme.headerFontSize};
    justify-content: center;
    align-items: center;
  }
`;

export const Input = styled.input`
  border: 3px solid rgba(224, 224, 224, 1);
  border-radius: 8px;
  padding: 10px 8px;
  font-family: Nunito;
  font-size: ${(props) => props.theme.labelFontSize};
  font-weight: 600;
  line-height: 24px;
  max-width: 372px;
  width: 100%;
  outline: none;
  &:focus {
    border: 3px solid rgb(117 93 243);
  }
  @media (min-height: 500px) {
    padding: 20px 16px;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 16px;
  @media (min-height: 600px) {
    gap: 32px;
  }
`;

export const SaveButton = styled.button<{ isDisabled: boolean }>`
  background-color: rgb(117 93 243);
  color: white;
  border-radius: 32px;
  max-width: 410px;
  width: 100%;
  padding: 12px 0;
  border: 3px solid white;
  cursor: ${({ isDisabled }) => (isDisabled ? "auto" : "pointer")};
  font-family: Nunito;
  font-size: ${(props) => props.theme.buttonFontSize};
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0.01em;
  transition: 300ms;
  opacity: ${({ isDisabled }) => (isDisabled ? "0.3" : "1")};
  &:hover&:not([disabled]) {
    box-shadow: 0 0 8px rgb(117 93 243);
  }
  @media (min-height: 500px) {
    padding: 24px 0;
  }
`;

export const BirdContainer = styled.div`
  position: absolute;
  top: -94px;
  left: 40px;
`;

export const StyledImage = styled.img`
  max-width: 100px;
  max-height: 106px;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
`;

export const SecondaryBirdContainer = styled.div`
  position: absolute;
  top: -94px;
  right: 40px;
`;

export const StyledFriendImage = styled.img`
  width: 80%;
  height: 80%;
  max-height: 100px;
  @media (min-height: 600px) {
    max-height: 140px;
  }
  @media (min-width: 1200px) {
    max-height: 200px;
  }
`;

export const NewProfileButton = styled.button`
  display: flex;
  position: relative;
  border: none;
  width: 100%;
  height: 100%;
  max-width: 100px;
  max-height: 100px;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: 400ms;
  flex: 1 1 160px;
  background-color: white;
  &:hover {
    box-shadow: 0 0 12px 12px rgb(254 171 7);
  }
  @media (min-height: 600px) {
    min-width: 140px;
    min-height: 140px;
    max-width: 140px;
    max-height: 140px;
  }
  @media (min-width: 1200px) {
    min-width: 200px;
    min-height: 200px;
    max-width: 200px;
    max-height: 200px;
    padding: 20px;
  }
`;

export const FriendsContainer = styled.div`
  display: flex;
  position: relative;
  gap: 16px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 600px;
  @media (min-width: 768px) and (min-height: 600px) {
    gap: 32px;
    max-width: none;
  }
`;

export const DeleteButton = styled.button`
  background-color: white;
  color: rgb(183, 0, 0);
  border-radius: 32px;
  max-width: 150px;
  width: 100%;
  padding: 12px 0;
  border: 3px solid rgb(183, 0, 0);
  cursor: pointer;
  font-family: Nunito;
  font-size: ${(props) => props.theme.labelFontSize};
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0.01em;
  transition: 300ms;
  &:hover {
    box-shadow: 0 0 8px rgb(183, 0, 0);
  }
`;
