import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 32px;
  background-color: white;
  width: 160px;
  height: 160px;
  position: relative;
  transition: 400ms;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px 0px rgba(85, 122, 31, 1);
  &:hover {
    box-shadow: 0px 12px 12px 0px rgba(85, 122, 31, 1);
  }
  @media (min-width: 768px) and (min-height: 600px) {
    width: 230px;
    height: 230px;
  }
  @media (min-width: 1124px) and (min-height: 600px) {
    width: 246px;
    height: 246px;
  }
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  width: 154px;
  height: 154px;
  z-index: 1;
  border-radius: 32px;
  overflow: hidden;
  @media (min-width: 768px) and (min-height: 600px) {
    width: 218px;
    height: 218px;
  }
  @media (min-width: 1124px) and (min-height: 600px) {
    width: 234px;
    height: 234px;
  }
`;

export const ImageBackground = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  z-index: 2;
  margin-bottom: 10px;
  background-color: white;
  padding: 0 4px;
  border-radius: 32px;
  width: 98%;
  box-shadow: 2px 2px 2px 2px rgba(85, 122, 31, 1);
  z-index: 2;
`;

export const Name = styled.h3`
  margin: 0;
  font-family: Nunito;
  font-size: ${(props) => props.theme.mobileTileFontSize};
  font-weight: 900;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: center;
  color: black;
  @media (min-width: 768px) and (min-height: 600px) {
    font-size: ${(props) => props.theme.tileFontSize};
  }
`;

interface ProgressContainerProps {
  progress: number;
  background: string;
  color: string;
}

export const ProgressContainer = styled.div<ProgressContainerProps>`
  display: flex;
  z-index: 2;
  height: 20px;
  justify-content: flex-end;
  padding-right: 3px; /// 6px
  align-items: center;
  margin-right: auto;
  background-color: ${({ background }) => background};
  width: ${({ progress }) => `${progress}%`};
  color: ${({ color }) => color};
  border-radius: 0 32px 32px 0;
`;

export const Progress = styled.span<{ $marginRight: string }>`
  font-family: Nunito;
  font-size: 14px;
  font-weight: 900;
  line-height: 24px;
  margin-right: ${({ $marginRight }) => $marginRight};
`;
