import { useEffect, useState } from "react";
import { handleLessonResult } from "../../../../api/common-api";
import { useParams } from "react-router-dom";
import ReactionSound from "../../reactionSound";
import GameSound from "../../../../assets/games/dobierz_w_pary.mp3";
import GameSoundEn from "../../../../assets/games/en/match_the_pair.mp3";
import GameSoundDe from "../../../../assets/games/de/Finde_das_passende_Gegenstuck-dopasuj_w_pary.mp3";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import GameReactionSound from "../../gameReactionSound";
import * as S from "./PairTogetherGame.styles";
import * as C from "../CommonGame.styles";
interface IPairTogetherGameProps {
  pairs: any[];
  handleShowResultRacoon: () => void;
  handleContinue: () => void;
  gameLectorSound: string;
  handleShowBuyModal: () => void;
}

const PairTogetherGame = (props: IPairTogetherGameProps) => {
  const { pairs = [], handleShowResultRacoon, handleShowBuyModal } = props;
  const [selectedImageIndex, setSelectedImageIndex] = useState("");
  const [selectedWordIndex, setSelectedWordIndex] = useState("");
  const [selectedSecondaryImageIndex, setSelectedSecondaryImageIndex] =
    useState("");
  const [alreadyMatched, setAlreadyMatched] = useState<any[]>([]);

  const [isActionDisabled, setIsActionDisabled] = useState(false);

  const { t } = useTranslation("translation", {
    keyPrefix: "games.pairTogether",
  });
  const { language } = i18n;

  const correctGameSound =
    language === "pl"
      ? GameSound
      : language === "en"
      ? GameSoundEn
      : GameSoundDe;

  const params = useParams();
  const { id } = params;

  const handleSelectImage = (value: any, id: string) => {
    if (isActionDisabled) {
      return null;
    }
    if (alreadyMatched.includes(id)) return;
    if (selectedImageIndex && selectedImageIndex !== id) {
      setSelectedImageIndex("");
      setSelectedSecondaryImageIndex("");
      setSelectedWordIndex("");
      GameReactionSound(false);
      return;
    }
    if (selectedImageIndex === id) {
      setSelectedImageIndex("");
      setSelectedSecondaryImageIndex("");
      setSelectedWordIndex("");
      GameReactionSound(false);
      return;
    }
    setSelectedImageIndex(id);
  };

  const handleSelectSecondaryImage = (value: any, id: string) => {
    if (isActionDisabled) {
      return null;
    }
    if (alreadyMatched.includes(id)) return;
    if (selectedSecondaryImageIndex && selectedSecondaryImageIndex !== id) {
      setSelectedImageIndex("");
      setSelectedSecondaryImageIndex("");
      setSelectedWordIndex("");
      GameReactionSound(false);
      return;
    }
    if (selectedSecondaryImageIndex === id) {
      setSelectedImageIndex("");
      setSelectedSecondaryImageIndex("");
      setSelectedWordIndex("");
      GameReactionSound(false);
      return;
    }
    setSelectedSecondaryImageIndex(id);
  };
  const handleSelectWord = (value: any, id: string) => {
    if (isActionDisabled) {
      return null;
    }
    if (alreadyMatched.includes(id)) return;
    if (selectedWordIndex && selectedWordIndex !== id) {
      setSelectedImageIndex("");
      setSelectedSecondaryImageIndex("");
      setSelectedWordIndex("");
      GameReactionSound(false);
      return;
    }
    if (selectedWordIndex === id) {
      setSelectedImageIndex("");
      setSelectedSecondaryImageIndex("");
      setSelectedWordIndex("");
      GameReactionSound(false);
      return;
    }
    setSelectedWordIndex(id);
  };

  useEffect(() => {
    if (
      !selectedImageIndex ||
      !(selectedWordIndex || selectedSecondaryImageIndex)
    ) {
      return;
    }
    const maybeCorrect = pairs.find((p: any) => {
      return (
        (p.id === selectedImageIndex && p.id === selectedWordIndex) ||
        (p.id === selectedSecondaryImageIndex && p.id === selectedImageIndex)
      );
    });
    if (maybeCorrect) {
      setAlreadyMatched([
        ...alreadyMatched,
        selectedImageIndex,
        selectedSecondaryImageIndex,
      ]);
      GameReactionSound(true);
    }
    if (!maybeCorrect) {
      GameReactionSound(false);
    }
    setSelectedImageIndex("");
    setSelectedWordIndex("");
    setSelectedSecondaryImageIndex("");
  }, [
    selectedImageIndex,
    selectedWordIndex,
    pairs,
    alreadyMatched,
    selectedSecondaryImageIndex,
  ]);

  const handleGameResult = async () => {
    id && (await handleLessonResult(id));
  };

  useEffect(() => {
    const allMatched =
      pairs.length > 1 &&
      pairs.every((p: any) => alreadyMatched.includes(p.id));
    if (allMatched) {
      handleGameResult();
      handleShowResultRacoon();
      setIsActionDisabled(true);
      handleShowBuyModal();
      ReactionSound(true);
    }
  }, [pairs, alreadyMatched]);

  return (
    <C.Wrapper>
      <C.TitleContainer>
        <C.StyledTitle>{t("matchPair")}</C.StyledTitle>
      </C.TitleContainer>
      {!isActionDisabled && (
        <div style={{ display: "none" }}>
          <audio controls src={correctGameSound} autoPlay />
        </div>
      )}
      <S.PairsContainer>
        {pairs.map((p: any, index: number) => {
          if (p.imgSrc2) {
            return (
              <S.ImgContainer
                isActionDisabled={isActionDisabled}
                isSelected={selectedSecondaryImageIndex === p.id}
                onClick={() => handleSelectSecondaryImage(p.imgSrc2, p.id)}
                isMatched={alreadyMatched.includes(p.id)}
                key={index}
              >
                <S.StyledImg src={p.imgSrc2} />
              </S.ImgContainer>
            );
          } else if (p.word) {
            return (
              <S.WordContainer
                isActionDisabled={isActionDisabled}
                onClick={() => handleSelectWord(p.word, p.id)}
                isSelected={selectedWordIndex === p.id}
                isResult={alreadyMatched.includes(p.id)}
                key={index}
              >
                <S.Word>{p.word}</S.Word>
              </S.WordContainer>
            );
          }
          return (
            <S.ImgContainer
              isActionDisabled={isActionDisabled}
              isSelected={selectedImageIndex === p.id}
              onClick={() => handleSelectImage(p.imgSrc, p.id)}
              isMatched={alreadyMatched.includes(p.id)}
              key={index}
            >
              <S.StyledImg src={p.imgSrc} />
            </S.ImgContainer>
          );
        })}
      </S.PairsContainer>
    </C.Wrapper>
  );
};

export default PairTogetherGame;
