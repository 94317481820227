import { useState } from "react";
import { initPasswordReset } from "../../api/common-api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import GameContainer from "../layout/GameContainer";
import BrownBird from "../../assets/common/BrownBird.svg";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useTranslation } from "react-i18next";
import * as S from "./login.styles";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleResetPassword = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    const res = await initPasswordReset(email);
    if (res.isError) {
      toast(res.message);
    } else {
      setIsLoading(false);
      navigate("/");
      toast.success("We have sent an email");
    }
    setIsLoading(false);
  };

  const isButtonDisabled = !email;

  const isTablet = useMediaQuery("(min-width: 768px)");
  const isHigherThanHorizontalPhone = useMediaQuery("(min-height: 700px)");

  const { t } = useTranslation("translation", { keyPrefix: "loginForm" });

  return (
    <GameContainer showLogo isLoading={isLoading} backTo="/login">
      <S.Wrapper onSubmit={(e) => handleResetPassword(e)}>
        {isTablet && isHigherThanHorizontalPhone && (
          <S.BirdContainer>
            <S.StyledImage src={BrownBird} />
          </S.BirdContainer>
        )}
        <S.TitleContainer>
          <S.Title>Reset hasła</S.Title>
        </S.TitleContainer>
        <S.InputsContainer>
          <S.Row>
            <S.Label>{t("email")}</S.Label>
            <S.Input
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
              autoFocus
            />
          </S.Row>
        </S.InputsContainer>
        <S.Actions>
          <S.StyledButton
            type="submit"
            disabled={isButtonDisabled}
            isDisabled={isButtonDisabled}
          >
            {t("loginText")}
          </S.StyledButton>
        </S.Actions>
      </S.Wrapper>
    </GameContainer>
  );
};

export default ForgotPassword;
