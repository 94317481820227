import { useNavigate } from "react-router-dom";
import * as S from "./categoryContainer.styles";

export interface ICategoryProps {
  id: string;
  name: string;
  imgSrc: string;
}

const CategoryContainer = (props: ICategoryProps) => {
  const { name, id, imgSrc } = props;
  const navigate = useNavigate();

  return (
    <S.Wrapper onClick={() => navigate(`/category/${id}`)}>
      <S.ImageContainer>
        <S.StyledImage src={imgSrc} alt="" />
      </S.ImageContainer>
      <S.NameContainer>
        <S.Name>{name}</S.Name>
      </S.NameContainer>
    </S.Wrapper>
  );
};

export default CategoryContainer;
