import styled from "styled-components";

export const SubscriptionInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* position: absolute;
  bottom: 10px; */
  gap: 8px;
  @media (min-height: 600px) {
    flex-direction: column;
  }
  @media (min-width: 768px) and (min-height: 600px) {
    bottom: 48px;
  }
`;

export const StatusContainer = styled.div`
  font-weight: 700;
`;

export const Status = styled.span<{ isActive: boolean }>`
  font-size: 16px;
  font-weight: 700;
  color: ${({ isActive }) => (isActive ? "green" : "red")};
`;

export const ExpirationDate = styled.span`
  font-weight: 700;
`;

export const BuyNowButton = styled.button`
  font-family: Nunito;
  font-weight: 700;
  display: flex;
  border: none;
  width: 100%;
  height: 100%;
  max-width: 140px;
  max-height: 140px;
  border-radius: 32px;
  padding: 8px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: 400ms;
  background-color: rgb(117 93 243);
  color: white;
  &:hover {
    box-shadow: 0 0 12px 12px rgb(254 171 7);
  }
  @media (min-width: 1200px) {
    max-width: 200px;
    max-height: 200px;
    padding: 20px;
  }
`;
