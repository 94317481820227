import { useContext, useEffect, useState } from "react";
import { IProfile } from "../../utils/types";
import { useNavigate } from "react-router-dom";
import GameContainer from "../layout/GameContainer";
import Smiley from "../../assets/smiley.png";
import Plus from "../../assets/common/WhitePlus.svg";
import PinkBird from "../../assets/common/PinkBird.svg";
import useMediaQuery from "../../hooks/useMediaQuery";
import { AuthContext } from "../../App";
import { useTranslation } from "react-i18next";
import {
  deleteSelectedProfile,
  getAllProfiles,
  selectProfile,
} from "../../api/profile";
import { toast } from "react-toastify";
import * as S from "./profile.styles";

const Profile = () => {
  const authContext = useContext(AuthContext);

  const {
    setProfilePicture,
    setCurrentProfile,
    setCurrentProfileId,
    setCurrentProfileFontSize,
    setCurrentProfileLanguage,
    currentProfileId,
  } = authContext;

  const [profiles, setProfiles] = useState<IProfile[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUserActive, setIsUserActive] = useState(false);

  const { t, i18n } = useTranslation("translation", { keyPrefix: "profile" });

  const navigate = useNavigate();

  const clearCurrentProfile = async () => {
    if (!currentProfileId) {
      return;
    }
    await deleteSelectedProfile();
    setProfilePicture("");
    setCurrentProfile("");
    setCurrentProfileId("");
    setCurrentProfileFontSize("");
    setCurrentProfileLanguage("");
  };

  const getProfiles = async () => {
    setIsLoading(true);
    try {
      const { profiles, isActive } = await getAllProfiles();
      if (profiles.isError) {
        toast(profiles.message);
      }
      setProfiles(profiles);
      setIsUserActive(isActive);
    } catch (e: any) {
      console.log({ e });
      navigate("/");
    }

    setIsLoading(false);
  };

  useEffect(() => {
    localStorage.setItem("isLoggedIn", "true");
    getProfiles();
    clearCurrentProfile();
  }, []);

  const handleGoToNewProfile = () => {
    navigate("/profile/new");
  };

  const handleEditProfile = (id: string) => {
    navigate(`/profile/${id}`);
  };

  const handleSelectProfile = async (
    id: string,
    name: string,
    img: string,
    defaultFontSize: string,
    defaultLanguage: string
  ) => {
    if (!id) {
      return;
    }
    setCurrentProfile(name);
    setProfilePicture(img);
    setCurrentProfileId(id);
    setCurrentProfileFontSize(defaultFontSize);
    setCurrentProfileLanguage(defaultLanguage);
    await selectProfile(id);
    i18n.changeLanguage(defaultLanguage);
    navigate(`/category`);
  };

  const canAddProfile = profiles.length < 3;

  const isTablet = useMediaQuery("(min-width: 768px)");
  const isHigherThanHorizontalPhone = useMediaQuery("(min-height: 700px)");

  const addNewProfileText = isHigherThanHorizontalPhone
    ? `${t("newProfile")}`
    : "Nowy";

  const handleNavigateToCms = () => navigate("/cms");

  const storedLoggedInInfo = localStorage.getItem("isLoggedIn");
  const storedAdminInfo = localStorage.getItem("isAdmin");

  const storedIsLoggedIn = JSON.parse(storedLoggedInInfo || "false");
  const storedIsAdminInfo = JSON.parse(storedAdminInfo || "false");

  const isAdmin = (storedIsLoggedIn && storedIsAdminInfo) || false;

  const isDesktop = useMediaQuery("(min-width: 1200px)");

  const handleGoToPayment = () => {
    navigate(`/payment`);
  };

  return (
    <GameContainer showLogo isLoading={isLoading} backTo="/">
      <S.Wrapper>
        {isTablet && isHigherThanHorizontalPhone && (
          <S.BirdContainer>
            <S.StyledImage src={PinkBird} />
          </S.BirdContainer>
        )}
        <S.Profiles>
          {profiles &&
            profiles.length > 0 &&
            profiles.map((p: IProfile, index: number) => (
              <S.ProfileContainer key={index}>
                <S.ProfileBox
                  key={index}
                  onClick={() =>
                    handleSelectProfile(
                      p.id,
                      p.name,
                      p.picture,
                      p.defaultFontSize,
                      p.defaultLanguage
                    )
                  }
                >
                  <S.ProfileImage
                    src={p.picture ? p.picture : Smiley}
                    alt="friend image"
                  />
                </S.ProfileBox>
                <S.NameContainer>
                  <S.Name>{p.name}</S.Name>
                </S.NameContainer>
                <S.EditButton onClick={() => handleEditProfile(p.id)}>
                  {t("edit")}
                </S.EditButton>
              </S.ProfileContainer>
            ))}
          {canAddProfile && (
            <S.NewProfileButton
              key={"new-profile"}
              onClick={() => handleGoToNewProfile()}
            >
              <S.AddNewContainer>
                {isDesktop && <S.PlusImage src={Plus} />}
                <S.AddNewProfileText>{addNewProfileText}</S.AddNewProfileText>
              </S.AddNewContainer>
            </S.NewProfileButton>
          )}
        </S.Profiles>
      </S.Wrapper>
      {isAdmin && (
        <div style={{ marginTop: 16 }}>
          <S.CmsButton onClick={() => handleNavigateToCms()}>
            Panel Admina
          </S.CmsButton>
        </div>
      )}
      {!isUserActive && (
        <div style={{ marginTop: 16 }}>
          <S.CmsButton
            onClick={() => handleGoToPayment()}
            style={{
              padding: "12px 20px",
              fontWeight: 700,
              fontSize: 32,
              borderRadius: 20,
            }}
          >
            {t("buy")}
          </S.CmsButton>
        </div>
      )}
    </GameContainer>
  );
};

export default Profile;
