import { ISelectOption } from "./types";

export const languages: ISelectOption[] = [
  {
    value: "pl",
    label: "Polski",
  },
  {
    value: "en",
    label: "Angielski",
  },
  {
    value: "de",
    label: "Niemiecki",
  },
];
