import styled from "styled-components";

export const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 32px;
  max-height: 300px;
  max-width: 300px;
  @media (min-width: 768px) {
    max-height: 600px;
    max-width: 600px;
  }
`;

export const StyledPickImg = styled(StyledImg)`
  cursor: pointer;
  border: 6px solid transparent;
  max-width: 186px;
  max-height: 186px;
  @media (min-width: 768px) {
    max-width: 200px;
    max-height: 200px;
  }
  @media (min-width: 1200px) {
    max-width: 300px;
    max-height: 300px;
  }
  @media (min-width: 1300px) {
    max-width: 400px;
    max-height: 400px;
  }
`;

export const Button = styled.button`
  background-color: rgb(117 93 243);
  color: white;
  border-radius: 32px;
  max-width: 410px;
  width: 100%;
  padding: 24px 16px;
  border: 3px solid white;
  cursor: pointer;
  font-family: Nunito;
  font-size: ${(props) => props.theme.buttonFontSize};
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0.01em;
  transition: 300ms;
  &:hover {
    box-shadow: 0 0 8px rgb(117 93 243);
  }
  margin: 0 auto;
`;

export const TimerContainer = styled.div`
  margin: 0 auto;
  font-family: Nunito;
  font-size: ${(props) => props.theme.infoTextFontSize};
  font-weight: 900;
  line-height: 28px;
  background-color: rgb(117 93 243);
  padding: 24px 16px;
  border-radius: 32px;
`;

export const Timer = styled.span`
  color: white;
`;

export const ImgContainer = styled.div<{ isExample?: boolean }>`
  position: relative;
  border: 6px solid white;
  box-shadow: 0px 4px 4px 0px rgba(85, 122, 31, 1);
  display: flex;
  border-radius: 32px;
  background-color: white;
  width: 100%;
  height: 100%;
  max-height: ${({ isExample }) => (isExample ? "300px" : "186px")};
  max-width: ${({ isExample }) => (isExample ? "300px" : "186px")};
  min-width: ${({ isExample }) => (isExample ? "300px" : "186px")};
  transition: 400ms;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
  margin: 0 auto;
  @media (min-width: 768px) {
    max-height: ${({ isExample }) => (isExample ? "600px" : "200px")};
    max-width: ${({ isExample }) => (isExample ? "600px" : "200px")};
    min-width: ${({ isExample }) => (isExample ? "600px" : "200px")};
  }
  @media (min-width: 1200px) {
    max-height: ${({ isExample }) => (isExample ? "600px" : "300px")};
    max-width: ${({ isExample }) => (isExample ? "600px" : "300px")};
    min-width: ${({ isExample }) => (isExample ? "600px" : "300px")};
  }
  @media (min-width: 1300px) {
    max-height: ${({ isExample }) => (isExample ? "600px" : "400px")};
    max-width: ${({ isExample }) => (isExample ? "600px" : "400px")};
    min-width: ${({ isExample }) => (isExample ? "600px" : "400px")};
  }
`;

export const PickImgContainer = styled(ImgContainer)<{
  isOffset: boolean;
  isSelected: boolean;
  isActionDisabled: boolean;
}>`
  cursor: ${({ isActionDisabled }) => (isActionDisabled ? "auto" : "pointer")};
  margin: 0%;
  border: ${({ isSelected }) =>
    isSelected ? "6px solid #ffb800" : "6px solid white"};
  &:hover {
    border: ${({ isActionDisabled }) =>
      isActionDisabled ? "6px solid white" : "6px solid #ffb800"};
  }
  @media (min-width: 768px) and (min-height: 600px) {
    margin-top: ${({ isOffset }) => (isOffset ? "80px" : "0")};
  }
`;

export const Result = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 32px;
  opacity: 0.9;
  max-width: 186px;
  max-height: 186px;
  @media (min-width: 1200px) {
    max-width: 300px;
    max-height: 300px;
  }
  @media (min-width: 1300px) {
    max-width: 400px;
    max-height: 400px;
  }
`;
