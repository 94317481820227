import { instance } from "./common-api";

export const createNewProfile = async (params: {
  name: string;
  age: string;
  gender: string;
  picture: string;
  defaultFontSize: string;
}): Promise<any> => {
  const res = await instance.post(`/profile/`, { ...params });
  return res.data;
};

export const getAllProfiles = async (): Promise<any> => {
  try {
    const res = await instance.get(`/profile/all`);
    return res.data;
  } catch (error: any) {
    console.log(JSON.stringify(error));
    return {
      message:
        error.response?.data || "There is an error with getting profiles",
    };
  }
};

export const deleteProfile = async (id: string): Promise<any> => {
  const res = await instance.delete(`/profile/${id}`);
  return res.data;
};

export const getProfileDetails = async (id: string): Promise<any> => {
  const res = await instance.get(`/profile/${id}`);
  return res.data;
};

export const updateProfile = async (
  id: string,
  params: {
    name: string;
    age: string;
    gender: string;
    picture: string;
    defaultFontSize: string;
  }
): Promise<any> => {
  const res = await instance.post(`/profile/${id}`, { ...params });
  return res.data;
};

export const selectProfile = async (id: string): Promise<any> => {
  const res = await instance.post(`/profile/select/${id}`);
  return res.data;
};

export const deleteSelectedProfile = async (): Promise<any> => {
  const res = await instance.delete(`/profile/select/delete`);
  return res.data;
};

export const getUserStatusInfo = async (): Promise<any> => {
  const res = await instance.get(`/user/status`);
  return res.data;
};

export const setProfileDefaultLanguage = async (
  id: string,
  language: string
): Promise<any> => {
  const res = await instance.post(`/profile/lng/${id}`, {
    language,
  });
  return res.data;
};

export const getSubscirptionInfo = async (): Promise<any> => {
  const res = await instance.post(`/payment/create-portal-session`);
  return res.data;
};
