import { Navigate, Outlet } from "react-router-dom";

interface IProtectedRouteProps {
  isLoggedIn: boolean;
  navigateTo: string;
  children?: JSX.Element;
}

const LoggedInRoute = (props: IProtectedRouteProps) => {
  const { isLoggedIn, navigateTo, children } = props;
  if (!isLoggedIn) {
    return <Navigate to={navigateTo} replace />;
  }

  return children ? children : <Outlet />;
};

export default LoggedInRoute;
