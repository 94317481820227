import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  deleteLesson,
  getSubjectDetails,
  updateSubjectById,
} from "../../api/cms";
import GameContainer from "../layout/GameContainer";
import * as S from "./cms.styles";
import { Popconfirm } from "antd";

const emptySubject = {
  id: "",
  name: "",
  categoryLevelId: "",
  created_at: "",
  updated_at: "",
};

const SubjectForm = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { pathname } = location;

  const [file, setFile] = useState<File>();
  const [isLoading, setIsLoading] = useState(false);
  const { id: subjectId } = params;
  const [subjectData, setSubjectData] = useState<any>(emptySubject);

  const fetchSubjectDetails = async (id: string) => {
    const res = await getSubjectDetails(id);
    setSubjectData(res);
  };

  const { name, lessons = [], level, categoryName } = subjectData;

  useEffect(() => {
    if (subjectId) {
      fetchSubjectDetails(subjectId);
    }
  }, [pathname, lessons.length]);

  const updateSubject = async () => {
    if (!subjectId || !name) {
      return;
    }
    setIsLoading(true);
    await updateSubjectById({ id: subjectId, name }, file);
    setIsLoading(false);
  };

  const addNewLesson = () => navigate(`/cms/subject/${subjectId}/lesson`);
  const editLesson = (lessonId: string) =>
    navigate(`/cms/subject/${subjectId}/lesson/${lessonId}`);

  const handleDeleteLesson = async (id: string) => {
    setIsLoading(true);
    await deleteLesson(id);
    subjectId && (await fetchSubjectDetails(subjectId));
    setIsLoading(false);
  };

  return (
    <GameContainer isLoading={isLoading}>
      <S.Wrapper>
        <S.Title>
          {categoryName} {level}
        </S.Title>
        <S.Title>Edycja tematu</S.Title>
        <S.Group>
          <S.Row>
            <S.Label>Nazwa</S.Label>
            <S.Input
              value={name}
              onChange={(e: any) =>
                setSubjectData({ ...subjectData, name: e.target.value })
              }
            />
          </S.Row>
          <S.Row>
            <S.Label>Nowy obrazek</S.Label>
            <S.Input
              onChange={(e: any) => setFile(e.target.files[0])}
              type="file"
            />
          </S.Row>
        </S.Group>
        <S.Button onClick={() => updateSubject()}>Zapisz zmiany</S.Button>
        <S.Title>Lekcje</S.Title>
        <S.Button onClick={() => addNewLesson()}>Dodaj lekcje</S.Button>
        <S.Group>
          {lessons.map((l: any, index: number) => (
            <S.RowContainer key={index}>
              {index + 1}.
              <S.SingleSubject onClick={() => editLesson(l.id)}>
                {l.name}
              </S.SingleSubject>
              <Popconfirm
                title="Usunać lekcje?"
                onConfirm={() => handleDeleteLesson(l.id)}
              >
                <S.DeleteButton>Usuń</S.DeleteButton>
              </Popconfirm>
            </S.RowContainer>
          ))}
        </S.Group>
      </S.Wrapper>
    </GameContainer>
  );
};

export default SubjectForm;
