import GameContainer from "../layout/GameContainer";
import {
  createNewPaymentSession,
  getPricesOptions,
} from "../../api/common-api";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./Payment.styles";

interface IOption {
  price: number;
  priceId: string;
  currency: string;
  interval: string;
  intervalCount: number;
}

const Payment = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<IOption[]>([]);

  const handlePayment = async (option: IOption) => {
    setIsLoading(true);
    const { price, priceId, currency, interval, intervalCount } = option;
    const basicDayValue = interval === "year" ? 360 : 30;
    const payDays = basicDayValue * intervalCount;
    const { id, url } = await createNewPaymentSession({
      option: {
        priceId: priceId,
        payDays,
        price: price.toString(),
        currency,
      },
    });
    if (id) {
      return navigate(`/payment/${id}`);
    } else if (url) {
      window.location.href = url;
    }
    setIsLoading(false);
  };

  const productKey = process.env.REACT_APP_STRIPE_PRODUCT_KEY || "";

  const getPriceOptions = async () => {
    setIsLoading(true);
    try {
      const options = await getPricesOptions(productKey);
      setOptions(options);
    } catch (e: any) {
      navigate("/profile");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getPriceOptions();
  }, []);

  const { t } = useTranslation("translation", { keyPrefix: "payment" });

  const renderSymbol = (currency: string) => {
    if (!currency) {
      return "";
    }
    if (currency === "gbp") {
      return "£";
    } else if (currency === "pln") {
      return "zł";
    } else if (currency === "usd") {
      return "$";
    } else if ((currency = "eur")) {
      return "€";
    }
  };

  return (
    <GameContainer isLoading={isLoading}>
      <S.Wrapper>
        <S.HeaderContainer>
          <S.StyledHeader> {t("choose")}</S.StyledHeader>
        </S.HeaderContainer>
        <S.ButtonsContainer>
          {options.map((o, index: number) => {
            if (o.currency !== "pln") {
              return (
                <S.StyledButton
                  onClick={() => handlePayment(o)}
                  disabled={isLoading}
                  isDisabled={isLoading}
                  key={index}
                >
                  {o.intervalCount} {o.interval} - {renderSymbol(o.currency)}{" "}
                  {o.price}{" "}
                </S.StyledButton>
              );
            }
            return (
              <S.StyledButton
                onClick={() => handlePayment(o)}
                disabled={isLoading}
                isDisabled={isLoading}
              >
                {o.intervalCount} {o.interval} - {o.price}{" "}
                {renderSymbol(o.currency)}
              </S.StyledButton>
            );
          })}
          {/* <S.StyledButton
            onClick={() => handlePayment(priceOptions[0])}
            disabled={isLoading}
            isDisabled={isLoading}
          >
            Miesiąc
          </S.StyledButton>
          <S.StyledButton
            onClick={() => handlePayment(priceOptions[1])}
            disabled={isLoading}
            isDisabled={isLoading}
          >
            Kwartał
          </S.StyledButton>
          <S.StyledButton
            onClick={() => handlePayment(priceOptions[2])}
            disabled={isLoading}
            isDisabled={isLoading}
          >
            Rok
          </S.StyledButton> */}
        </S.ButtonsContainer>
      </S.Wrapper>
    </GameContainer>
  );
};

export default Payment;
