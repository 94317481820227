import styled from "styled-components";

interface IStyledWordProps {
  isResult: boolean;
  resultBackgroundColor: string;
  isSelected: boolean;
  isActionDisabled: boolean;
}

export const WordContainer = styled.div<IStyledWordProps>`
  display: flex;
  background-color: ${({ isResult, resultBackgroundColor }) =>
    isResult ? resultBackgroundColor : "#dffcff"};
  width: 100%;
  height: 100%;
  max-width: 186px;
  max-height: 186px;
  min-height: 150px;
  aspect-ratio: 1/1;
  cursor: ${({ isActionDisabled }) => (isActionDisabled ? "auto" : "pointer")};
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  border: ${({ isSelected }) =>
    isSelected ? "6px solid #ffb800" : "6px solid white"};
  box-shadow: 0px 4px 4px 0px #557a1f;
  transition: 400ms;
  position: relative;
  &:hover {
    border: ${({ isActionDisabled }) =>
      isActionDisabled ? "6px solid white" : "6px solid #ffb800"};
  }
  z-index: 1;
  @media (min-width: 1024px) {
    max-width: 240px;
    max-height: 240px;
  }
  @media (min-width: 1200px) {
    max-width: 488px;
    max-height: 488px;
  }
`;

export const Word = styled.span`
  display: flex;
  text-align: center;
  color: #153a5c;
  font-size: ${(props) => props.theme.mobileWordFontSize};
  font-weight: 900;
  z-index: 2;
  line-height: 120%;
  @media (min-width: 1024px) {
    font-size: ${(props) => props.theme.wordFontSize};
  }
`;

export const EmptyContainer = styled.div`
  padding-top: 60px;
  width: 70px;
`;

export const Result = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 32px;
`;
