import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  @media (min-width: 768px) and (min-height: 600px) {
    padding: 16px;
    gap: 32px;
    width: 100%;
  }
`;

export const TitleContainer = styled.div`
  margin: 0 auto;
  max-width: 700px;
  @media (min-width: 768px) and (min-height: 500px) {
    margin: 0 auto 16px;
  }
  @media (min-width: 1400px) and (min-height: 800px) {
    max-width: none;
  }
`;

export const StyledTitle = styled.h3`
  margin: 0;
  color: #153a5c;
  text-align: center;
  font-size: ${(props) => props.theme.smallHeaderFontSize};
  font-family: Nunito;
  font-weight: 900;
  @media (min-width: 1024px) {
    font-size: ${(props) => props.theme.headerFontSize};
  }
  @media (min-width: 1200px) {
    font-size: ${(props) => props.theme.bigHeaderFontSize};
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  gap: 48px;
  align-items: flex-start;
  justify-content: center;
`;

export const ColumnContentContainer = styled(ContentContainer)`
  flex-direction: column;
  align-items: center;
  gap: 16px;
  @media (min-width: 768px) and (min-height: 700px) {
    gap: 48px;
  }
`;

export const WordContainer = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 186px;
  @media (min-width: 1024px) {
    max-width: 240px;
  }
  @media (min-width: 1300px) {
    max-width: 400px;
  }
`;
