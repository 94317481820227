import { useEffect, useState } from "react";
import { createNewSubject, getCmsData } from "../../api/common-api";
import Loader from "../common/loader/loader";
import { ICategory, ISelectOption } from "../../utils/types";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import GameContainer from "../layout/GameContainer";
import { Popconfirm } from "antd";
import { deleteSubject } from "../../api/cms";
import * as S from "./cms.styles";

const SubjectList = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [categories, setAllCategories] = useState<ICategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<ISelectOption>();
  const [categoryLevels, setAllCategoryLevels] = useState<any[]>([]);
  const [selectedCategoryLevel, setSelectedCategoryLevel] =
    useState<ISelectOption>();
  const [newSubjectName, setNewSubjectName] = useState("");
  const [file, setFile] = useState<File>();
  const [allSubjects, setAllSubjects] = useState<any[]>([]);

  const categoriesOptions = categories.map((c: ICategory) => ({
    value: c.id,
    label: c.name,
  }));

  const getCmsDatas = async () => {
    const res = await getCmsData();
    const { categories, categoryLevels, subjects } = res;
    setAllCategories(categories);
    setAllCategoryLevels(categoryLevels);
    setAllSubjects(subjects);
  };

  useEffect(() => {
    setIsLoading(true);
    getCmsDatas();
    setIsLoading(false);
  }, []);

  const selectedCategoryOptions = () => {
    if (selectedCategory) {
      const selectedCategoryLevel = categoryLevels.find(
        (x) => x.id === selectedCategory.value
      );
      if (!selectedCategoryLevel) {
        return [];
      }
      const { levels } = selectedCategoryLevel;
      const availableSelectedCategoryOptions = levels.map((l: any) => ({
        value: l.id,
        label: l.level,
      }));
      return availableSelectedCategoryOptions;
    } else {
      return [];
    }
  };

  const handleAddNewSubject = async () => {
    if (!newSubjectName || !selectedCategoryLevel) {
      return;
    }
    setIsLoading(true);
    await createNewSubject({
      name: newSubjectName,
      categoryLevelId: selectedCategoryLevel.value,
      file,
    });
    await getCmsDatas();
    setNewSubjectName("");
    setIsLoading(false);
  };

  const addNewButtonText = isLoading ? <Loader /> : "Dodaj";

  const handleNavigateToSubject = (id: string) => {
    navigate(`/cms/subject/${id}`);
  };

  const isAddNewSubjectButtonDisabled =
    !selectedCategory || !selectedCategoryLevel || !newSubjectName || !file;

  const handleDeleteSubject = async (id: string) => {
    setIsLoading(true);
    await deleteSubject(id);
    await getCmsDatas();
    setIsLoading(false);
  };

  return (
    <GameContainer isLoading={isLoading} backTo="/cms">
      <S.Wrapper>
        <S.Content>
          <S.Group>
            <S.Title>Dodaj kategorie do nauki</S.Title>
            <S.Row>
              <S.Label>Kategoria</S.Label>
              <Select
                options={categoriesOptions}
                value={selectedCategory}
                onChange={(e: any) => setSelectedCategory(e)}
              />
            </S.Row>
            <S.Row>
              <S.Label>Poziom</S.Label>
              <Select
                required
                options={selectedCategoryOptions()}
                value={selectedCategoryLevel}
                onChange={(e: any) => setSelectedCategoryLevel(e)}
                isDisabled={!selectedCategory?.value}
              />
            </S.Row>
            <S.Row>
              <S.Label>Nazwa</S.Label>
              <S.Input
                required
                value={newSubjectName}
                onChange={(e: any) => setNewSubjectName(e.target.value)}
              />
            </S.Row>
            <S.Row>
              <S.Label>Obrazek</S.Label>
              <S.Input
                required
                onChange={(e: any) => setFile(e.target.files[0])}
                type="file"
              />
            </S.Row>
            <S.Button
              onClick={() => handleAddNewSubject()}
              disabled={isAddNewSubjectButtonDisabled}
            >
              {addNewButtonText}
            </S.Button>
          </S.Group>
          <S.Group>
            <S.Title>Kategorie do nauki</S.Title>
            <S.Container>
              {allSubjects.map((c: any, index: number) => (
                <S.RowContainer
                  key={index}
                  style={{
                    maxHeight: 200,
                    border: "1px solid rgb(117 93 243)",
                  }}
                >
                  <S.Name>{c.categoryName}</S.Name>
                  <S.Index>{c.level}</S.Index>
                  <S.SubjectContainer>
                    {c.subjects.map((a: any, index: number) => (
                      <S.RowContainer>
                        <S.SingleSubject
                          key={index}
                          onClick={() => handleNavigateToSubject(a.id)}
                        >{`${a.name}`}</S.SingleSubject>
                        <Popconfirm
                          title="Usunać temat?"
                          onConfirm={() => handleDeleteSubject(a.id)}
                        >
                          <S.Button>Usuń</S.Button>
                        </Popconfirm>
                      </S.RowContainer>
                    ))}
                  </S.SubjectContainer>
                </S.RowContainer>
              ))}
            </S.Container>
          </S.Group>
        </S.Content>
      </S.Wrapper>
    </GameContainer>
  );
};

export default SubjectList;
