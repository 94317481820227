import { useEffect, useState } from "react";
import { getSubscirptionInfo } from "../../api/profile";
import { useNavigate } from "react-router-dom";
import LogoLoader from "../common/loader/logoLoader";
import GameContainer from "../layout/GameContainer";

const Subscription = () => {
  const [redirectUrl, setRedirectUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const getSubInfo = async () => {
    setIsLoading(true);
    try {
      const res = await getSubscirptionInfo();
      setRedirectUrl(res);
    } catch (error: any) {
      console.log({ error });
      navigate("/settings");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getSubInfo();
  }, []);

  useEffect(() => {
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  }, [redirectUrl]);

  if (isLoading) {
    return (
      <GameContainer>
        <LogoLoader isBig />
      </GameContainer>
    );
  }

  return null;
};

export default Subscription;
