import { IManadalaRow } from "./lessonForm";
import * as S from "./cms.styles";

interface IMandalaRowExtended extends IManadalaRow {
  index: number;
  onDelete: (index: number) => void;
  onChange: (e: any, rowId: string) => void;
}

const MandalaRow = (props: IMandalaRowExtended) => {
  const { id, isCorrect = false, index, image, onDelete, onChange } = props;
  return (
    <S.GameRowContainer>
      <input
        name="image"
        defaultValue={image}
        onChange={(e: any) => onChange(e, id)}
        type="file"
      />
      <div>Poprawna?</div>
      <input
        name="isCorrect"
        type="checkbox"
        checked={isCorrect}
        onChange={(e: any) => onChange(e, id)}
      />
      <S.DeleteButton type="button" onClick={() => onDelete(index)}>
        usuń
      </S.DeleteButton>
    </S.GameRowContainer>
  );
};

export default MandalaRow;
