import { useNavigate } from "react-router-dom";
import GameContainer from "../layout/GameContainer";
import { useTranslation } from "react-i18next";
import * as S from "./Confirmation.styles";

const Confirmation = () => {
  const navigate = useNavigate();

  const { t } = useTranslation("translation", { keyPrefix: "confirmation" });

  return (
    <GameContainer backTo="/">
      <S.Wrapper>
        <S.Header>{t("done")}</S.Header>
        <S.Body>{t("body")}</S.Body>
        <S.StyledButton onClick={() => navigate("/")}>
          {t("goBack")}
        </S.StyledButton>
      </S.Wrapper>
    </GameContainer>
  );
};

export default Confirmation;
