import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import GameContainer from "../layout/GameContainer";
import LogoLoader from "../common/loader/logoLoader";
import { toast } from "react-toastify";
import { verifyUser } from "../../api/common-api";
import * as S from "./ValidateAccount.styles";

const ValidateAccont = () => {
  const navigate = useNavigate();
  const params: any = useParams();
  const { verificationCode } = params;
  useEffect(() => {
    if (!verificationCode) {
      return navigate("/");
    }

    const verify = async () => {
      const res = await verifyUser(verificationCode).catch(() => {
        toast.warn("Verification code is incorrect or already used");
        navigate("/");
      });

      if (!res) return navigate("/login");

      toast.success("Account activated correctly");
      navigate("/start");
    };
    verify();
  }, [verificationCode]);

  return (
    <GameContainer>
      <S.Wrapper>
        <LogoLoader />
      </S.Wrapper>
    </GameContainer>
  );
};
export default ValidateAccont;
