import { useEffect, useState } from "react";
import { getCategoryLevelSubjectsById } from "../../api/common-api";
import { useParams } from "react-router-dom";
import SubjectContainer, { ISubjectProps } from "./subjectContainer";
import GameContainer from "../layout/GameContainer";
import * as S from "./pickSubjectForm.styles";
import { useTranslation } from "react-i18next";

const PickSubjectForm = () => {
  const [categoryName, setCategoryName] = useState("");
  const [level, setLevel] = useState("");
  const [subjects, setSubjects] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryId, setCategoryId] = useState("");

  const params = useParams();
  const { id } = params;

  const getCategoryLevels = async () => {
    if (id) {
      setIsLoading(true);
      const res = await getCategoryLevelSubjectsById(id);
      const { categoryName, level, subjects, categoryId } = res;
      setLevel(level);
      setSubjects(subjects);
      setCategoryName(categoryName);
      setCategoryId(categoryId);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCategoryLevels();
  }, [id]);

  const renderSubjects = () =>
    subjects &&
    subjects.map((c: ISubjectProps, index: number) => (
      <SubjectContainer {...c} index={index} key={index} />
    ));

  const { t } = useTranslation("translation", { keyPrefix: "categoryLevel" });

  return (
    <GameContainer isLoading={isLoading} backTo={`/category/${categoryId}`}>
      <S.Wrapper>
        <S.HeaderContainer>
          <S.StyledHeader>
            {categoryName}
            <S.StyledSubHeader>
              {t("level")} {level}
            </S.StyledSubHeader>
          </S.StyledHeader>
        </S.HeaderContainer>
        <S.SubjectsContainer className="color-scroll">
          {renderSubjects()}
        </S.SubjectsContainer>
      </S.Wrapper>
    </GameContainer>
  );
};

export default PickSubjectForm;
