import { FontSizeTheme } from "./types";

export const themeSettings = (userCurrentFontSize: string) => {
  switch (userCurrentFontSize) {
    case FontSizeTheme.SMALL:
      return {
        fontSize: "16px",
        mobileHeaderFontSize: "20px",
        headerFontSize: "30px",
        smallHeaderFontSize: "28px",
        mobileBigHeaderFontSize: "36px",
        bigHeaderFontSize: "48px",
        subheaderFontSize: "14px",
        buttonFontSize: "18px",
        mobileInfoTextFontSize: "16px",
        infoTextFontSize: "26px",
        labelFontSize: "18px",
        mobileTileFontSize: "12px",
        tileFontSize: "20px",
        mobileWordFontSize: "36px",
        wordFontSize: "54px",
      };
    case FontSizeTheme.AVERAGE:
      return {
        fontSize: "18px",
        mobileHeaderFontSize: "22px",
        headerFontSize: "36px",
        smallHeaderFontSize: "30px",
        mobileBigHeaderFontSize: "40px",
        bigHeaderFontSize: "44px",
        subheaderFontSize: "16px",
        buttonFontSize: "20px",
        mobileInfoTextFontSize: "18px",
        infoTextFontSize: "30px",
        labelFontSize: "20px",
        mobileTileFontSize: "14px",
        tileFontSize: "22px",
        mobileWordFontSize: "38px",
        wordFontSize: "56px",
      };
    case FontSizeTheme.BIG:
      return {
        fontSize: "20px",
        mobileHeaderFontSize: "24px",
        headerFontSize: "38px",
        smallHeaderFontSize: "32px",
        mobileBigHeaderFontSize: "44px",
        bigHeaderFontSize: "50px",
        subheaderFontSize: "18px",
        buttonFontSize: "22px",
        mobileInfoTextFontSize: "20px",
        infoTextFontSize: "32px",
        labelFontSize: "22px",
        mobileTileFontSize: "16px",
        tileFontSize: "24px",
        mobileWordFontSize: "40px",
        wordFontSize: "58px",
      };
    default:
      return {
        fontSize: "18px",
        mobileHeaderFontSize: "22px",
        headerFontSize: "36px",
        smallHeaderFontSize: "30px",
        mobileBigHeaderFontSize: "40px",
        bigHeaderFontSize: "44px",
        subheaderFontSize: "16px",
        buttonFontSize: "20px",
        mobileInfoTextFontSize: "18px",
        infoTextFontSize: "30px",
        labelFontSize: "20px",
        mobileTileFontSize: "14px",
        tileFontSize: "22px",
        mobileWordFontSize: "38px",
        wordFontSize: "56px",
      };
  }
};
