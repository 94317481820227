import { useEffect, useState } from "react";
import { ICategory } from "../../utils/types";
import CategoryContainer, { ICategoryProps } from "./categoryContainer";
import GameContainer from "../layout/GameContainer";
import { useTranslation } from "react-i18next";
import { getAllCategories } from "../../api/category";
import * as S from "./pickCategoryForm.styles";

const PickCategoryForm = () => {
  const [categories, setAllCategories] = useState<ICategory[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const {
    t,
    i18n: { language },
  } = useTranslation("translation", { keyPrefix: "category" });

  const getCategories = async () => {
    setIsLoading(true);
    const res = await getAllCategories(language);
    setAllCategories(res);
    setIsLoading(false);
  };

  useEffect(() => {
    getCategories();
  }, [language]);

  const renderCategories = () =>
    categories.map((c: ICategoryProps, index: number) => (
      <CategoryContainer {...c} key={index} />
    ));

  return (
    <GameContainer showLogo isLoading={isLoading} backTo="/profile">
      <S.Wrapper>
        <S.HeaderContainer>
          <S.StyledHeader>{t("invite")}</S.StyledHeader>
        </S.HeaderContainer>
        <S.CategoriesContainer className="color-scroll">
          {renderCategories()}
        </S.CategoriesContainer>
      </S.Wrapper>
    </GameContainer>
  );
};

export default PickCategoryForm;
