import { useState } from "react";
import { register } from "../../api/common-api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import GameContainer from "../layout/GameContainer";
import LightBird from "../../assets/common/LightBrid.svg";
import Eye from "../../assets/common/Eye.svg";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useTranslation } from "react-i18next";
import * as S from "./register.styles";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [inputType, setInputType] = useState("password");
  const [isPasswordInputFocused, setIsPasswordInputFocused] = useState(false);

  const { i18n } = useTranslation();

  const { language } = i18n;
  const navigate = useNavigate();

  const handleRegister = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    const res = await register({ email, password, defaultLng: language });
    if (res.isError) {
      toast(res.message);
    } else {
      toast("We've sent verification e-mail");
      navigate("/login");
    }
    setIsLoading(false);
  };

  const isButtonDisabled = !email || !password || isLoading;

  const handleChangeInputType = () =>
    inputType === "password" ? setInputType("text") : setInputType("password");

  const isTablet = useMediaQuery("(min-width: 768px)");
  const isHigherThanHorizontalPhone = useMediaQuery("(min-height: 700px)");

  const { t } = useTranslation("translation", { keyPrefix: "registerForm" });

  return (
    <GameContainer showLogo showRacoon backTo="/start">
      <S.Wrapper onSubmit={(e) => handleRegister(e)}>
        {isTablet && isHigherThanHorizontalPhone && (
          <S.BirdContainer>
            <S.StyledImage src={LightBird} />
          </S.BirdContainer>
        )}
        <S.TitleContainer>
          <S.Title>{t("register")}</S.Title>
        </S.TitleContainer>
        <S.InputsContainer>
          <S.Row>
            <S.Label>{t("email")}</S.Label>
            <S.Input
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
              autoFocus
              type="email"
              required
            />
          </S.Row>
        </S.InputsContainer>
        <S.Row>
          <S.Label>{t("password")}</S.Label>
          <S.PassowrdInputContainer isFocused={isPasswordInputFocused}>
            <S.PasswordInput
              onFocus={() => setIsPasswordInputFocused(true)}
              onBlur={() => setIsPasswordInputFocused(false)}
              value={password}
              onChange={(e: any) => setPassword(e.target.value)}
              type={inputType}
              required
            />
            <S.StyledEye src={Eye} onClick={() => handleChangeInputType()} />
          </S.PassowrdInputContainer>
        </S.Row>
        <S.Actions>
          <S.StyledButton
            type="submit"
            disabled={isButtonDisabled}
            isDisabled={isButtonDisabled}
          >
            {t("register")}
          </S.StyledButton>
        </S.Actions>
      </S.Wrapper>
    </GameContainer>
  );
};

export default Register;
