import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  overflow: auto;
  max-height: 80vh;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  @media (min-width: 700px) and (min-height: 600px) {
    margin-bottom: 40px;
    margin-top: 0p;
  }
`;

export const StyledHeader = styled.h2`
  display: flex;
  flex-direction: column;
  margin: 0;
  font-family: Nunito;
  font-weight: 900;
  letter-spacing: 0px;
  text-align: center;
  font-size: ${(props) => props.theme.mobileBigHeaderFontSize};
  @media (min-width: 1200px) {
    font-size: ${(props) => props.theme.bigHeaderFontSize};
  }
`;

export const StyledSubHeader = styled.div`
  margin-left: auto;
  color: white;
  background: rgba(155, 81, 224, 1);
  font-family: Nunito;
  font-size: ${(props) => props.theme.subheaderFontSize};
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  border-radius: 8px;
  padding: 2px 8px;
`;

export const StyledSecondarySubHeader = styled.div`
  margin-right: auto;
  color: white;
  background: #6fcf97;
  font-family: Nunito;
  font-size: ${(props) => props.theme.subheaderFontSize};
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  border-radius: 8px;
  padding: 2px 8px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin: 0 auto;
  overflow-x: auto;
  @media (min-height: 500px) {
    flex-direction: column;
  }
`;

export const LessonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  overflow-x: auto;
  padding-bottom: 12px;
  width: 100%;
  @media (min-width: 700px) and (min-height: 700px) {
    min-height: 248px;
  }
  @media (min-width: 1024px) and (min-height: 1024px) {
    max-height: 100%;
    justify-content: flex-start;
    gap: 12px;
  }
`;

export const SubHeadersContainer = styled.div`
  display: flex;
`;

export const PlayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 32px;
  background-color: #153a5c;
  width: 140px;
  height: 140px;
  position: relative;
  transition: 400ms;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px 0px rgba(85, 122, 31, 1);
  @media (min-width: 1024px) and (min-height: 800px) {
    width: 200px;
    height: 200px;
  }
  @media (min-width: 1200px) and (min-height: 800px) {
    width: 246px;
    height: 246px;
  }
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

export const Name = styled.h3`
  margin: 0;
  font-family: Nunito;
  font-size: ${(props) => props.theme.mobileHeaderFontSize};
  font-weight: 900;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: center;
  color: white;
  @media (min-width: 700px) and (min-height: 600px) {
    font-size: ${(props) => props.theme.smallHeaderFontSize};
  }
`;

export const GameWrapper = styled(PlayWrapper)`
  background-color: #13b89a;
`;

export const CmsButton = styled.button`
  font-family: Nunito;
  color: white;
  display: flex;
  justify-content: center;
  border: none;
  cursor: pointer;
  font-size: ${(props) => props.theme.buttonFontSize};
  font-weight: 800;
  align-items: center;
  background-color: rgb(117 93 243);
  border-radius: 8px;
  padding: 2px 4px;
  transition: 400ms;
  margin: 0 auto;
  &:hover {
    box-shadow: 0 0 12px 12px rgb(254 171 7);
  }
`;
