import { IImageTextPair } from "./lessonForm";
import * as S from "./cms.styles";

interface IPairTogetherRow extends IImageTextPair {
  index: number;
  onDelete: (index: number) => void;
  onChange: (e: any, rowId: string) => void;
}

const PairTogetherRow = (props: IPairTogetherRow) => {
  const { id, word, index, image, image2, onDelete, onChange } = props;
  return (
    <S.GameRowContainer>
      <S.Container>
        <S.Text>Obrazek lub słowo</S.Text>
        <input
          name="image2"
          defaultValue={image2}
          onChange={(e: any) => onChange(e, id)}
          type="file"
        />
        <input
          name="word"
          value={word}
          onChange={(e: any) => onChange(e, id)}
          type="text"
        />
      </S.Container>
      <S.Container>
        <S.Text>Obrazek do pary</S.Text>
        <input
          name="image"
          defaultValue={image}
          onChange={(e: any) => onChange(e, id)}
          type="file"
        />
      </S.Container>
      <S.DeleteButton type="button" onClick={() => onDelete(index)}>
        usuń
      </S.DeleteButton>
    </S.GameRowContainer>
  );
};

export default PairTogetherRow;
