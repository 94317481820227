import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ImageWrapper = styled.div`
  position: relative;
`;

export const StyledLogo = styled.img<{ $isBig?: boolean }>`
  object-fit: cover;
  max-height: ${({ $isBig }) => ($isBig ? "none" : "200px")};
  min-height: ${({ $isBig }) => ($isBig ? "200px" : "none")};
  @media (min-width: 1150px) {
    min-height: ${({ $isBig }) => ($isBig ? "300px" : "none")};
  }
`;
