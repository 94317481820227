export interface IProfile {
  id: string;
  userId: string;
  name: string;
  age: string;
  gender: string;
  picture: string;
  defaultFontSize: string;
  defaultLanguage: string;
  created_at: Date;
  updated_at: Date;
}

export interface ICategory {
  id: string;
  name: string;
  imgSrc: string;
  created_at: Date;
  updated_at: Date;
}

export interface ISelectOption {
  value: string;
  label: string;
}

export interface ICategoryLevel {
  id: string;
  level: number;
  categoryId: string;
  created_at: Date;
  updated_at: Date;
}

export enum FontSizeTheme {
  SMALL = "SMALL",
  AVERAGE = "AVERAGE",
  BIG = "BIG",
}
