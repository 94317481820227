import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: 2;
  align-items: center;
`;

export const AvatarWrapper = styled.div`
  display: flex;
  z-index: 2;
  border-radius: 50%;
  background: white;
  position: relative;
  min-width: 38px;
  max-width: 38px;
  max-height: 38px;
  min-height: 38px;
  @media (min-width: 1024px) {
    min-width: 74px;
    max-width: 74px;
    max-height: 74px;
    min-height: 74px;
  }
  transition: 400ms;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 12px 12px rgb(254 171 7);
  }
`;

export const Avatar = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

export const NameContainer = styled.div`
  margin-right: 8px;
  @media (min-width: 1024px) {
    margin-right: 16px;
  }
`;

export const Name = styled.span`
  font-family: Nunito;
  font-size: ${(props) => props.theme.mobileInfoTextFontSize};
  font-weight: 900;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: center;
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
  @media (min-width: 1024px) {
    font-size: ${(props) => props.theme.infoTextFontSize};
  }
`;
