import { useLocation, useNavigate } from "react-router-dom";
// import Fox from "../../assets/common/Fox.svg";
import Logo from "../../assets/common/Logo2.png";
import BackButton from "../../assets/common/BackButton.svg";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useContext } from "react";
import { AuthContext } from "../../App";
import ProfileAvatar from "../ProfileAvatar/ProfileAvatar";
import LogoLoader from "../common/loader/logoLoader";
import LocaleSwitcher from "../LocaleSwitcher/LocaleSwitcher";
import { logout } from "../../api/common-api";
import { useTranslation } from "react-i18next";
import * as S from "./game-container.styles";

interface IGameContainerProps {
  children: JSX.Element | JSX.Element[];
  isLoading?: boolean;
  isNav?: boolean;
  hideCloseButton?: boolean;
  backTo?: string;
  showRacoon?: boolean;
  showLogo?: boolean;
  showFox?: boolean;
  showRacoonWithResult?: boolean;
  isContinue?: boolean;
  isGame?: boolean;
}

const GameContainer = ({
  children,
  isLoading = false,
  isNav = true,
  hideCloseButton = true,
  backTo,
  // showRacoon = false,
  showLogo = false,
  showFox = false,
  // showRacoonWithResult = false,
  isContinue = false,
  isGame = false,
}: IGameContainerProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { pathname } = location;

  const authContext = useContext(AuthContext);

  const {
    currentProfileId,
    setIsLoggedIn,
    setCurrentProfileId,
    setCurrentProfileFontSize,
    setCurrentProfileLanguage,
  } = authContext;

  const handleGoBack = () => {
    if (backTo) {
      navigate(backTo);
    } else {
      navigate(-1);
    }
  };

  const { t } = useTranslation("translation", { keyPrefix: "profile" });
  const handleGoToSettings = () => navigate("/settings");

  // const isWideDesktop = useMediaQuery("(min-width: 1460px)");
  const isHigherThanHorizontalPhone = useMediaQuery("(min-height: 700px)");

  // const showRacoonCondition = isWideDesktop && showRacoon;
  // const showFoxCondition = isWideDesktop && showFox;
  // const showRacoonWithResultCondition = showRacoonWithResult;
  const isUserOnProfile = pathname === "/profile";

  // const renderRacoon = () => {
  //   if (showRacoonWithResultCondition) {
  //     return (
  //       <S.RacoonContainer>
  //         <S.StyledImage src={Racoon} loading="lazy" />
  //       </S.RacoonContainer>
  //       // <S.RacoonContainer>
  //       //   {/* <S.RacoonWithSpeachBubbleContainer> */}
  //       //   <S.BubbleContainer>
  //       //     {/* <S.SpeachBubbleContainer> */}
  //       //     <S.SpeachBubbleText>Wspaniale</S.SpeachBubbleText>
  //       //     <S.SpeachBubble src={SpeachBubble} loading="lazy" />
  //       //     {/* </S.SpeachBubbleContainer> */}
  //       //   </S.BubbleContainer>
  //       //   <S.StyledImage src={Racoon} loading="lazy" />
  //       //   {/* </S.RacoonWithSpeachBubbleContainer> */}
  //       // </S.RacoonContainer>
  //     ); ///TODO TBD
  //   } else if (showRacoonCondition) {
  //     return (
  //       <S.RacoonContainer>
  //         <S.StyledImage src={Racoon} loading="lazy" />
  //       </S.RacoonContainer>
  //     );
  //   }
  // };

  const handleLogout = async () => {
    await logout();
    localStorage.clear();
    setIsLoggedIn(false);
    setCurrentProfileId("");
    setCurrentProfileLanguage("");
    setCurrentProfileFontSize("");
    navigate("/start");
  };

  const handleHomeRedirect = () => {
    if (pathname.includes("cms")) {
      navigate("/cms/subjects");
    } else {
      navigate("/category");
    }
  };
  return (
    <S.Background>
      <S.AvatarAndLocaleContainer>
        {isUserOnProfile && <S.GearIcon onClick={() => handleGoToSettings()} />}
        {currentProfileId && <ProfileAvatar />}
        <LocaleSwitcher />
      </S.AvatarAndLocaleContainer>
      {isContinue && (
        <S.ContinueNav>
          <S.BackButton
            onClick={() => handleGoBack()}
            src={BackButton}
            isRotated
          />
        </S.ContinueNav>
      )}
      {isNav && (
        <S.Nav>
          <S.BackButton onClick={() => handleGoBack()} src={BackButton} />
          {!hideCloseButton && <S.CloseButton>X</S.CloseButton>}
          <S.HomeButtonContainer>
            <S.HomeButton onClick={() => handleHomeRedirect()} />
          </S.HomeButtonContainer>
        </S.Nav>
      )}
      {showLogo && isHigherThanHorizontalPhone && (
        <S.LogoContainer>
          <S.Logo src={Logo} loading="lazy" />
        </S.LogoContainer>
      )}
      <S.Wrapper $isGame={isGame}>
        {isLoading ? <LogoLoader isBig /> : <>{children}</>}
      </S.Wrapper>
      {/* {renderRacoon()} */}
      {/* {showFoxCondition && (
        <S.FoxContainer>
          <S.StyledImage src={Fox} loading="lazy" />
        </S.FoxContainer>
      )} */}
      {isUserOnProfile && (
        <S.LogoutWrapper>
          <S.LogoutButton onClick={() => handleLogout()}>
            {t("logout")}
          </S.LogoutButton>
        </S.LogoutWrapper>
      )}
    </S.Background>
  );
};

export default GameContainer;
