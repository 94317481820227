import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  max-height: 80vh;
  overflow: auto;
`;

export const Title = styled.div`
  font-family: Poppins;
  margin-right: 20px;
  font-size: 24px;
  padding: 10px;
`;

export const Name = styled.label`
  font-family: Poppins;
  font-size: 24px;
  padding: 10px;
  /* width: 180px; */
`;

export const Index = styled(Name)`
  width: 20px;
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`;

export const SingleSubject = styled.div`
  background-color: rgb(117 93 243);
  border: 1px solid white;
  padding: 4px 6px;
  cursor: pointer;
  color: white;
  border-radius: 16px;
  transition: 300ms;
  &:hover {
    box-shadow: 0 0 4px rgb(117 93 243);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
`;

export const RowContainer = styled(Container)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button`
  font-family: Poppins;
  background-color: white;
  font-size: 24px;
  border: 1px solid rgb(117 93 243);
  border-radius: 16px;
  transition: 300ms;
  padding: 4px 8px;
  max-width: 200px;
  &:hover&:not([disabled]) {
    box-shadow: 0 0 4px rgb(117 93 243);
    cursor: pointer;
  }
`;

export const DeleteButton = styled(Button)`
  font-size: 16px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 80vh;
  overflow: auto;
  @media (min-width: 1100px) {
    flex-direction: row;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.label`
  font-family: Poppins;
  background-color: rgb(117 93 243);
  width: 180px;
  margin-right: 20px;
  font-size: 24px;
  padding: 10px;
  color: white;
  border-radius: 16px;
`;

export const Input = styled.input`
  font-family: Poppins;
  min-width: 160px;
  font-size: 24px;
  padding: 10px;
  border-radius: 16px;
`;

export const GameTypesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`;

export const Text = styled.div`
  color: rgba(21, 58, 92, 1);
`;

export const GameRowContainer = styled(RowContainer)`
  border: 2px solid rgb(117 93 243);
  border-radius: 16px;
  padding: 8px;
`;

export const SubjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow: auto;
`;
