import styled from "styled-components";
import { BsFillArrowRightCircleFill } from "react-icons/bs";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  margin: auto;
  position: relative;
  width: 80%;
  justify-content: center;
  @media (min-height: 600px) {
    width: 100%;
    max-width: 1200px;
  }
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0 32px;
`;

export const VideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const StartVideoButton = styled.button``;

export const MuteVideoButton = styled.button``;

export const ActionButtonContainer = styled.div<{ $topHeight: number }>`
  display: flex;
  position: absolute;
  z-index: 10;
  right: -30px;
  top: ${({ $topHeight }) => `${$topHeight}px`};
  gap: 4px;
`;

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 3px solid rgb(117 93 243);
  background-color: white;
  width: 60px;
  height: 60px;
  cursor: pointer;
  transition: 400ms;
  border-radius: 50%;
  &:hover {
    filter: drop-shadow(0 0 12px rgb(254 171 7));
  }
`;

export const StyledNextIcon = styled(BsFillArrowRightCircleFill)`
  color: rgb(117 93 243);
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const CmsButton = styled.button`
  font-family: Nunito;
  color: white;
  display: flex;
  justify-content: center;
  border: none;
  cursor: pointer;
  font-size: ${(props) => props.theme.buttonFontSize};
  font-weight: 800;
  align-items: center;
  background-color: rgb(117 93 243);
  border-radius: 8px;
  padding: 2px 4px;
  transition: 400ms;
  margin: 0 auto;
  &:hover {
    box-shadow: 0 0 12px 12px rgb(254 171 7);
  }
`;
