import { ISoundsRow } from "./lessonForm";
import * as S from "./cms.styles";

interface ISoundsRowExtended extends ISoundsRow {
  index: number;
  onDelete: (index: number) => void;
  onChange: (e: any, rowId: string) => void;
}

const SoundsRow = (props: ISoundsRowExtended) => {
  const { id, index, image, onDelete, onChange, isCorrect } = props;
  return (
    <S.GameRowContainer>
      <input
        name="image"
        defaultValue={image}
        onChange={(e: any) => onChange(e, id)}
        type="file"
      />
      <div>Poprawne?</div>
      <input
        name="isCorrect"
        type="checkbox"
        checked={isCorrect}
        onChange={(e: any) => onChange(e, id)}
      />
      <button type="button" onClick={() => onDelete(index)}>
        usuń
      </button>
    </S.GameRowContainer>
  );
};

export default SoundsRow;
