import { useNavigate } from "react-router-dom";
import * as S from "./lessonContainer.styles";
import { useTranslation } from "react-i18next";

export interface ILessonProps {
  id: string;
  name: string;
  index: number;
  progress: boolean;
  imgSrc: string;
}

const LessonContainer = (props: ILessonProps) => {
  const { index, name, id, progress, imgSrc } = props;
  const navigate = useNavigate();

  ///progress jest boolean

  const { t } = useTranslation("translation", { keyPrefix: "lesson" });

  const backgroundColor = "rgba(255, 184, 0, 1)";
  const color = "rgba(21, 58, 92, 1)";
  const progressValue = progress ? 100 : 0;

  return (
    <S.Wrapper onClick={() => navigate(`/lesson/${id}`)}>
      <S.InnerWrapper>
        <S.ImageBackground src={imgSrc} alt="" rel="preload" loading="lazy" />
        <S.NameContainer $isGame={false}>
          <S.Name>
            {index + 1}. {name}
          </S.Name>
        </S.NameContainer>
        <S.ProgressContainer
          background={backgroundColor}
          progress={progressValue}
          color={color}
        >
          {progress && <S.Progress>100%</S.Progress>}
        </S.ProgressContainer>
      </S.InnerWrapper>
    </S.Wrapper>
  );
};

export default LessonContainer;
