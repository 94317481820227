import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getGameDataByLessonId,
  getLessonDetailsById,
  handleLessonResult,
} from "../../api/common-api";
import VideoPlayer from "./videoPlayer";
import PickCorrectGame from "./gameTypes/pickCorrectGame/PickCorrectGame";
import GameContainer from "../layout/GameContainer";
import { gameTypes } from "../cms/lessonForm";
import PairTogetherGame from "./gameTypes/pairTogetherGame/PairTogetherGame";
import MandalaGame from "./gameTypes/mandalaGame/MandalaGame";
import CorrectOrderGame from "./gameTypes/correctOrder/CorrectOrderGame";
import SoundsGame from "./gameTypes/soundsGame/SoundsGame";
import { toast } from "react-toastify";
import * as S from "./lesson.styles";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";

interface ILessonProps {
  isOnlyGame: boolean;
}

const Lesson = (props: ILessonProps) => {
  const { isOnlyGame = false } = props;
  const [subjectId, setSubjectId] = useState("");
  const [gameData, setGameData] = useState<any[]>([]);
  // const [value, setValue] = useState<any[]>([]);
  const [url, setUrl] = useState<any[]>([]);
  const [gameType, setGameType] = useState("");
  const [gameLectorSound, setGameLectorSound] = useState("");
  const [isUserActive, setIsUserActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation("translation", { keyPrefix: "profile" });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [arrowTopHeight, setArrowTopHeight] = useState(0);

  const handleOpenModal = () => {
    if (!isUserActive) {
      return setIsModalOpen(true);
    } else {
      return;
    }
  };
  const params = useParams();
  const navigate = useNavigate();
  const { id } = params;

  const getLessons = async () => {
    if (!id) {
      return;
    }
    setIsLoading(true);
    const res = await getLessonDetailsById(id);
    if (res.isError) {
      toast.warn(
        "User got no access to paid part of the app. Please update your subscription"
      );
      return navigate("/profile");
    }
    const { subjectId, url, gameType, gameLectorSound, isUserActive } = res;
    setSubjectId(subjectId);
    setIsUserActive(isUserActive);
    // setValue(value);
    setUrl(url);
    setVideoSteps(url.length);
    // setTextSteps(value.length);
    setGameLectorSound(gameLectorSound);
    setGameType(gameType);
    const gameRes = await getGameDataByLessonId(id);
    setGameData(gameRes);
    setIsLoading(false);
    if (url.length === 0) {
      setActiveGame(true);
    }
  };

  useEffect(() => {
    getLessons();
  }, [id]);

  const [videoSteps, setVideoSteps] = useState(url.length || 0);
  const [activeVideoStep, setActiveVideoStep] = useState(1);
  const [activeGame, setActiveGame] = useState(false);

  const [isResultRacoonVisible, setIsResultRacoonVisible] = useState(false);

  const nextVideoButtonText =
    activeVideoStep === 0 ? (
      "Rozpocznij"
    ) : activeVideoStep === videoSteps ? (
      <S.StyledNextIcon />
    ) : (
      <S.StyledNextIcon />
    );

  const isShowingVideo = videoSteps > 0 && activeVideoStep > 0 && !activeGame;

  const showPreviousVideoButton = activeVideoStep > 1;

  const handlePreviousVideoStep = () => {
    if (activeVideoStep === 1) {
      return;
    } else {
      setActiveVideoStep(activeVideoStep - 1);
    }
  };

  const handleBackFromGame = () => {
    setActiveGame(false);
  };

  const goBackToSubject = () => {
    navigate(`/subject/${subjectId}`);
  };

  const gamePicker = (gameType: gameTypes) => {
    switch (gameType) {
      case gameTypes.PICK_CORRECT:
        return (
          <PickCorrectGame
            words={gameData}
            handleShowResultRacoon={() => setIsResultRacoonVisible(true)}
            handleContinue={() => goBackToSubject()}
            gameLectorSound={gameLectorSound}
            handleShowBuyModal={() => handleOpenModal()}
          />
        );
      case gameTypes.PAIR_TOGETHER:
        return (
          <PairTogetherGame
            pairs={gameData}
            handleShowResultRacoon={() => setIsResultRacoonVisible(true)}
            handleContinue={() => goBackToSubject()}
            gameLectorSound={gameLectorSound}
            handleShowBuyModal={() => handleOpenModal()}
          />
        );
      case gameTypes.MANDALAS:
        return (
          <MandalaGame
            mandalas={gameData}
            handleShowResultRacoon={() => setIsResultRacoonVisible(true)}
            handleContinue={() => goBackToSubject()}
            gameLectorSound={gameLectorSound}
            handleShowBuyModal={() => handleOpenModal()}
          />
        );
      case gameTypes.CORRECT_ORDER:
        return (
          <CorrectOrderGame
            data={gameData}
            handleShowResultRacoon={() => setIsResultRacoonVisible(true)}
            handleContinue={() => goBackToSubject()}
            gameLectorSound={gameLectorSound}
            handleShowBuyModal={() => handleOpenModal()}
          />
        );
      case gameTypes.SOUNDS:
        return (
          <SoundsGame
            data={gameData}
            handleShowResultRacoon={() => setIsResultRacoonVisible(true)}
            handleContinue={() => goBackToSubject()}
            gameLectorSound={gameLectorSound}
            handleShowBuyModal={() => handleOpenModal()}
          />
        );
      default:
        return <div>No game type provided</div>;
    }
  };

  const onVideoEnd = async () => {
    if (activeVideoStep === videoSteps && gameType === "NONE") {
      id && (await handleLessonResult(id));
      return navigate(`/subject/${subjectId}`);
    } else if (activeVideoStep === videoSteps && activeGame === false) {
      setActiveGame(true);
    } else {
      setActiveVideoStep(activeVideoStep + 1);
    }
  };

  const handleNextStep = async () => {
    const videoStepsLength = videoSteps || 0;
    if (!videoSteps) {
      return setActiveGame(true);
    }
    if (activeVideoStep >= videoStepsLength && gameType === "NONE") {
      id && (await handleLessonResult(id));
      return navigate(`/subject/${subjectId}`);
    }
    if (activeVideoStep >= videoStepsLength) {
      return setActiveGame(true);
    }
    if (videoSteps && activeVideoStep < videoStepsLength) {
      return setActiveVideoStep(activeVideoStep + 1);
    }
  };

  const handleGoToPayment = () => {
    navigate(`/payment`);
  };

  if (isOnlyGame) {
    return (
      <GameContainer
        showRacoonWithResult={isResultRacoonVisible}
        isLoading={isLoading}
        backTo={`/subject/${subjectId}`}
        isContinue={isResultRacoonVisible}
      >
        <S.Wrapper className="color-scroll">
          <S.InnerWrapper>
            <S.ContentContainer>
              {gamePicker(gameType as gameTypes)}
            </S.ContentContainer>
          </S.InnerWrapper>
        </S.Wrapper>
        <Modal
          open={isModalOpen}
          centered
          footer={false}
          onCancel={() => setIsModalOpen(false)}
        >
          <S.CmsButton
            onClick={() => handleGoToPayment()}
            style={{
              padding: "12px 20px",
              fontWeight: 700,
              fontSize: 32,
              borderRadius: 20,
            }}
          >
            {t("buy")}
          </S.CmsButton>
        </Modal>
      </GameContainer>
    );
  }
  return (
    <GameContainer
      showRacoonWithResult={isResultRacoonVisible}
      isLoading={isLoading}
      backTo={`/subject/${subjectId}`}
      isContinue={isResultRacoonVisible}
      isGame={activeGame}
    >
      <S.Wrapper className="color-scroll">
        <S.InnerWrapper>
          <S.ContentContainer>
            {!activeGame && (
              <S.ActionButtonContainer $topHeight={arrowTopHeight}>
                {showPreviousVideoButton && (
                  <S.ActionButton onClick={() => handlePreviousVideoStep()}>
                    <S.StyledNextIcon style={{ transform: "rotate(180deg)" }} />
                  </S.ActionButton>
                )}
                {activeVideoStep >= 0 && (
                  <S.ActionButton onClick={() => handleNextStep()}>
                    {nextVideoButtonText}
                  </S.ActionButton>
                )}
                {activeGame && (
                  <>
                    <button onClick={() => handleBackFromGame()}>
                      Wróć do słów
                    </button>
                    <button onClick={() => goBackToSubject()}>
                      Wróć do tematu
                    </button>
                  </>
                )}
              </S.ActionButtonContainer>
            )}
            {isShowingVideo && (
              <VideoPlayer
                activeStep={activeVideoStep}
                urls={url}
                onVideoEnd={onVideoEnd}
                setVideoTopHeight={setArrowTopHeight}
              />
            )}
            {/* {isShowingText && (
              <TextViewer activeStep={activeTextStep} values={value} />
            )} */}
            {activeGame && !isLoading && gamePicker(gameType as gameTypes)}
          </S.ContentContainer>
        </S.InnerWrapper>
      </S.Wrapper>
      <Modal
        open={isModalOpen}
        centered
        footer={false}
        onCancel={() => setIsModalOpen(false)}
      >
        <S.CmsButton
          onClick={() => handleGoToPayment()}
          style={{
            padding: "12px 20px",
            fontWeight: 700,
            fontSize: 32,
            borderRadius: 20,
          }}
        >
          {t("buy")}
        </S.CmsButton>
      </Modal>
    </GameContainer>
  );
};

export default Lesson;
