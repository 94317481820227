import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  justify-content: center;
  align-items: center;
`;

export const ResetButtonContainer = styled.div``;

export const ResetButton = styled.button`
  font-family: Nunito;
  font-weight: 700;
  display: flex;
  border: 1px solid rgb(117 93 243);
  color: rgb(117 93 243);
  width: 100%;
  height: 100%;
  max-width: 200px;
  max-height: 140px;
  border-radius: 32px;
  padding: 20px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: 400ms;
  background-color: white;
  &:hover {
    box-shadow: 0 0 12px 12px rgb(254 171 7);
  }
`;
