import { FontSizeTheme } from "./types";

export const fontThemesOptions = (lng: string) => {
  return [
    {
      value: FontSizeTheme.SMALL,
      label: lng === "en" ? "Small" : lng === "de" ? "Klein" : "Mała",
    },
    {
      value: FontSizeTheme.AVERAGE,
      label: lng === "en" ? "Medium" : lng === "de" ? "Mittel" : "Średnia",
    },
    {
      value: FontSizeTheme.BIG,
      label: lng === "en" ? "Large" : lng === "de" ? "Groß" : "Duża",
    },
  ];
};
