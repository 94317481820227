import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  @media (min-width: 768px) {
    gap: 40px;
  }
`;

export const HeaderContainer = styled.div`
  max-width: 736px;
`;

export const StyledHeader = styled.h1`
  margin: 0;
  font-size: ${(props) => props.theme.mobileHeaderFontSize};
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  @media (min-width: 768px) {
    font-size: ${(props) => props.theme.headerFontSize};
    line-height: 43px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  align-items: center;
`;

export const StyledButton = styled.button`
  background-color: rgb(117 93 243);
  color: white;
  border-radius: 32px;
  max-width: 410px;
  width: 100%;
  padding: 12px 0;
  border: 3px solid white;
  cursor: pointer;
  font-family: Nunito;
  font-size: ${(props) => props.theme.buttonFontSize};
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0.01em;
  transition: 300ms;
  &:hover {
    box-shadow: 0 0 8px rgb(117 93 243);
  }
  @media (min-width: 1200px) {
    padding: 24px 0;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
`;

export const InfoText = styled.div`
  font-family: Nunito;
  font-size: ${(props) => props.theme.mobileInfoTextFontSize};
  font-weight: 900;
  line-height: 22px;
  text-align: center;
  @media (min-width: 768px) {
    font-size: ${(props) => props.theme.infoTextFontSize};
    line-height: 37px;
  }
`;

export const FramesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const SimpleFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledImg = styled.img`
  max-width: 140px;
  @media (min-width: 1200px) and (min-height: 1024px) {
    max-width: none;
  }
`;

export const FrameContainer = styled.div``;

export const FrameText = styled.h3`
  margin: 0;
  font-family: Nunito;
  /* font-size: ${(props) => props.theme.mobileHeaderFontSize}; */
  font-size: 24px;
  font-weight: 900;
  line-height: 37px;
  letter-spacing: 0px;
  text-align: center;
  color: white;
  @media (min-width: 1200px) and (min-height: 1024px) {
    font-size: ${(props) => props.theme.headerFontSize};
    line-height: 43px;
  }
`;
