import { useEffect, useState } from "react";
import { handleLessonResult } from "../../../../api/common-api";
import { useParams } from "react-router-dom";
import Check from "../../../../assets/common/tick.jpg";
import Cross from "../../../../assets/common/cross.jpg";
import PickCorrectSound from "../../../../assets/games/zapamietaj_obrazek.mp3";
import MemorizePicture from "../../../../assets/games/wybierz_poprawny_obrazek.mp3";
import PickCorrectSoundEn from "../../../../assets/games/en/memorise_the_picture.mp3";
import MemorizePictureEn from "../../../../assets/games/en/choose_the_correct_picture.mp3";
import MemorizePictureDe from "../../../../assets/games/de/Wahle_das_richtige_Bild.-wybierz_poprawny_obrazek.mp3";
import PickCorrectSoundDe from "../../../../assets/games/de/merke_dir_das_Bild-zapamietaj_obrazek.mp3";
import ReactionSound from "../../reactionSound";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import * as S from "./MandalaGame.styles";
import * as C from "../CommonGame.styles";

interface IMandalaGameProps {
  mandalas: any[];
  handleShowResultRacoon: () => void;
  handleContinue: () => void;
  gameLectorSound: string;
  handleShowBuyModal: () => void;
}

const MandalaGame = (props: IMandalaGameProps) => {
  const { mandalas = [], handleShowResultRacoon, handleShowBuyModal } = props;
  const [timerCount, setTimerCount] = useState(3);
  const [showChoice, setShowChoice] = useState(false);
  const [showExample, setShowExample] = useState(true);
  const [isResultVisible, setResultVisible] = useState<any[]>([]);
  const [isActionDisabled, setIsActionDisabled] = useState(false);
  const [startTimer, setStartTimer] = useState(false);
  const [loadedImages, setLoadedImages] = useState<JSX.Element[]>([]);

  const { t } = useTranslation("translation", { keyPrefix: "games.mandala" });
  const { language } = i18n;

  const correctPickCorrectSounds =
    language === "pl"
      ? PickCorrectSound
      : language === "en"
      ? PickCorrectSoundEn
      : PickCorrectSoundDe;
  const correctMemorizePicture =
    language === "pl"
      ? MemorizePicture
      : language === "en"
      ? MemorizePictureEn
      : MemorizePictureDe;

  const params = useParams();
  const { id } = params;

  const correctMandala = mandalas?.find((x) => x.isExample === true);

  const handleGameResult = async (isCorrect: boolean, index: number) => {
    if (isActionDisabled) {
      return null;
    }
    if (isCorrect === true) {
      setResultVisible([index]);
      id && (await handleLessonResult(id));
      handleShowResultRacoon();
      setIsActionDisabled(true);
      handleShowBuyModal();
      ReactionSound(true);
    } else {
      setResultVisible([index]);
      ReactionSound(false);
    }
  };

  const mandalasToShow = mandalas.filter((x: any) => !x.isExample);

  useEffect(() => {
    if (startTimer === true) {
      const timer =
        timerCount > 0 &&
        setInterval(() => setTimerCount(timerCount - 1), 1000);
      ///@ts-ignore
      return () => clearInterval(timer);
    }
  }, [timerCount, startTimer]);

  useEffect(() => {
    if (timerCount === 0) {
      setShowExample(false);
      setShowChoice(true);
    }
  }, [timerCount]);

  function loadImage(src: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    });
  }

  async function loadImages(imageObjects: any[]): Promise<void> {
    try {
      const images = await Promise.all(
        imageObjects.map((obj) => loadImage(obj.imgSrc))
      );
      setLoadedImages(
        images.map((img, index) => (
          <S.PickImgContainer
            key={index}
            isOffset={index % 2 === 1}
            onClick={() =>
              handleGameResult(imageObjects[index].isCorrect, index)
            }
            isSelected={isResultVisible.includes(index)}
            isActionDisabled={isActionDisabled}
          >
            <S.StyledPickImg src={imageObjects[index].imgSrc} loading="lazy" />
            {isResultVisible.includes(index) && (
              <S.Result
                src={imageObjects[index].isCorrect ? Check : Cross}
                loading="lazy"
              />
            )}
          </S.PickImgContainer>
        ))
      );
    } catch (error) {
      console.error("Error loading images:", error);
    }
  }
  // mandalasToShow.map((w: any, index: number) => {
  //   const visibleResults = isResultVisible.includes(index);
  //   const resultImage = w.isCorrect ? Check : Cross;
  //   return (
  //     <S.PickImgContainer
  //       isOffset={index % 2 === 1}
  //       key={index}
  //       onClick={() => handleGameResult(w.isCorrect, index)}
  //       isSelected={isResultVisible.includes(index)}
  //       isActionDisabled={isActionDisabled}
  //     >
  //       <S.StyledPickImg src={w.imgSrc} loading="lazy" />
  //       {visibleResults && (
  //         <S.Result src={resultImage} rel="preload" loading="lazy" />
  //       )}
  //     </S.PickImgContainer>
  //   );
  // });

  useEffect(() => {
    loadImages(mandalasToShow);
  }, [mandalasToShow]);

  return (
    <>
      {!showChoice && (
        <C.Wrapper>
          <div style={{ display: "none" }}>
            <audio
              controls
              src={correctPickCorrectSounds}
              autoPlay
              onEnded={() => setStartTimer(true)}
            />
          </div>
          {showExample && (
            <>
              <S.TimerContainer>
                <S.Timer>
                  {t("time")}: {timerCount}
                </S.Timer>
              </S.TimerContainer>
              <S.ImgContainer isExample>
                <S.StyledImg
                  src={correctMandala.imgSrc}
                  rel="preload"
                  loading="lazy"
                />
              </S.ImgContainer>
            </>
          )}
        </C.Wrapper>
      )}
      {showChoice && (
        <C.Wrapper>
          <C.TitleContainer>
            <C.StyledTitle>{t("pickCorrect")}</C.StyledTitle>
          </C.TitleContainer>
          <div style={{ display: "none" }}>
            <audio controls src={correctMemorizePicture} autoPlay />
          </div>

          <C.ContentContainer>{loadedImages}</C.ContentContainer>
        </C.Wrapper>
      )}
    </>
  );
};

export default MandalaGame;
