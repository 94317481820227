import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 8px;
`;

export const Header = styled.h3`
  margin: 0;
  font-family: Nunito;
  font-size: 24px;
`;

export const Body = styled.span`
  font-family: Nunito;
  font-size: 18px;
`;

export const StyledButton = styled.button`
  background-color: rgb(117 93 243);
  color: white;
  border-radius: 32px;
  max-width: 410px;
  width: 100%;
  padding: 12px 0;
  border: 3px solid white;
  cursor: pointer;
  font-family: Nunito;
  font-size: ${(props) => props.theme.buttonFontSize};
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0.01em;
  transition: 300ms;
  &:hover&:not([disabled]) {
    box-shadow: 0 0 8px rgb(117 93 243);
  }
  @media (min-height: 500px) {
    padding: 24px 0;
  }
`;
