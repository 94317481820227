import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  max-height: 70vh;
`;

export const HeaderContainer = styled.div`
  margin-bottom: 20px;
  @media (min-height: 600px) {
    margin-bottom: 40px;
  }
`;

export const StyledHeader = styled.h2`
  margin: 0;
  font-family: Nunito;
  font-size: ${(props) => props.theme.headerFontSize};
  font-weight: 900;
  line-height: 43px;
  letter-spacing: 0px;
  text-align: center;
`;

export const CategoriesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding-bottom: 16px;
  width: 100%;
  overflow: auto;
  @media (min-width: 1024px) {
    justify-content: center;
  }
`;
