import { instance } from "./common-api";

export const getCategoriesData = async (): Promise<any> => {
  const res = await instance.get(`/cms/categories`);
  return res.data;
};

interface INewCategory {
  data: {
    name: string;
    language: string;
  };
  file: any;
}

export const createNewCategory = async (params: INewCategory): Promise<any> => {
  const formData = new FormData();

  const { data, file } = params;
  formData.append("data", JSON.stringify(data));
  formData.append("file", file);

  const res = await instance.post(`/category`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res.data;
};

export const getSubjectDetails = async (id: string): Promise<any> => {
  const res = await instance.get(`/subject/details/${id}`);
  return res.data;
};

export const getCategoryDetails = async (id: string): Promise<any> => {
  const res = await instance.get(`/category/${id}`);
  return res.data;
};

export const updateCategoryById = async (
  data: {
    id: string;
    name: string;
  },
  file: any
): Promise<any> => {
  const formData = new FormData();
  const { id } = data;

  if (file) {
    formData.append("file", file);
  }

  formData.append("data", JSON.stringify(data));

  const res = await instance.post(`/cms/category/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res.data;
};

export const deleteCategory = async (id: string) => {
  const res = await instance.delete(`/cms/category/${id}`);
  return res.data;
};

export const getCategoryLevelDetails = async (id: string): Promise<any> => {
  const res = await instance.get(`/category/category-level/${id}`);
  return res.data;
};

export const deleteCategoryLevel = async (id: string) => {
  const res = await instance.delete(`/cms/category-level/${id}`);
  return res.data;
};

export const updateCategoryLevelById = async (
  data: {
    id: string;
    name?: string;
  },
  file?: any
): Promise<any> => {
  const formData = new FormData();
  const { id } = data;

  if (file) {
    formData.append("file", file);
  }

  formData.append("data", JSON.stringify(data));

  const res = await instance.post(`/cms/category-level/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return res.data;
};

export const deleteSubject = async (id: string) => {
  const res = await instance.delete(`/cms/subject/${id}`);
  return res.data;
};

export const deleteLesson = async (id: string): Promise<any> => {
  const res = await instance.delete(`/cms/lesson/${id}`);
  return res.data;
};

export const updateSubjectById = async (
  data: {
    id: string;
    name: string;
  },
  file: any
): Promise<any> => {
  const formData = new FormData();
  const { id } = data;

  if (file) {
    formData.append("file", file);
  }

  formData.append("data", JSON.stringify(data));

  const res = await instance.post(`/cms/subject/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return res.data;
};

export const getLessonById = async (id: string): Promise<any> => {
  const res = await instance.get(`/cms/lesson/${id}`);
  return res.data;
};

export const getLessonLocalisation = async (id: string): Promise<any> => {
  const res = await instance.get(`/lesson/localisation/${id}`);
  return res.data;
};

export const updateLessonById = async (
  data: {
    id: string;
    name: string;
    value: string[];
    isForFree: boolean;
  },
  newLessonImage?: any,
  newGameLector?: any,
  newMovies?: any
): Promise<any> => {
  const formData = new FormData();
  const { id } = data;

  if (newLessonImage) {
    formData.append("files", newLessonImage, "newLessonImage");
  }

  if (newGameLector) {
    formData.append("files", newGameLector, "newGameLector");
  }

  if (newMovies && newMovies.length > 0) {
    for (let i = 0; i < newMovies.length; i++) {
      formData.append("files", newMovies[i], `newMovie${i}`);
    }
  }

  formData.append("data", JSON.stringify(data));

  const res = await instance.post(`/cms/lesson/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res.data;
};

interface INewLesson {
  name: string;
  subjectId: string;
  value: string[];
  gameType: string;
  isForFree: boolean;
}

export const createNewPickCorrectGame = async (
  data: INewLesson,
  file: any,
  gameLectorSound: any,
  movies: any
): Promise<any> => {
  const formData = new FormData();

  formData.append("data", JSON.stringify(data));
  formData.append("files", file, "lessonImage");

  if (gameLectorSound) {
    formData.append("files", gameLectorSound, "gameLectorSound");
  }

  if (movies.length > 0) {
    for (let i = 0; i < movies.length; i++) {
      formData.append("files", movies[i], `lessonMovie_${i}`);
    }
  }

  const res = await instance.post(`/cms/pickCorrect`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return res.data;
};

export const createNewPairTogetherGame = async (
  data: any,
  files: any,
  file: any,
  gameLectorSound: any,
  files2: any,
  movies: any
): Promise<any> => {
  const formData = new FormData();

  formData.append("data", JSON.stringify(data));

  if (movies.length > 0) {
    for (let i = 0; i < movies.length; i++) {
      formData.append("files", movies[i], `lessonMovie_${i}`);
    }
  }

  if (files2.length > 0) {
    for (let i = 0; i < files2.length; i++) {
      formData.append("files", files2[i], `filesInsteadOfString_${i}`);
    }
  }

  for (let i = 0; i < files.length; i++) {
    formData.append("files", files[i]);
  }

  formData.append("files", file, "lessonImage");

  if (gameLectorSound) {
    formData.append("files", gameLectorSound, "gameLectorSound");
  }

  const res = await instance.post(`/cms/pairTogether`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res.data;
};

export const createNewMandalasGame = async (
  data: any,
  files: any,
  file: any,
  gameLectorSound: any,
  movies: any
): Promise<any> => {
  const formData = new FormData();

  formData.append("data", JSON.stringify(data));

  if (movies.length > 0) {
    for (let i = 0; i < movies.length; i++) {
      formData.append("files", movies[i], `lessonMovie_${i}`);
    }
  }

  for (let i = 0; i < files.length; i++) {
    formData.append("files", files[i]);
  }

  formData.append("files", file, "lessonImage");

  if (gameLectorSound) {
    formData.append("files", gameLectorSound, "gameLectorSound");
  }

  const res = await instance.post(`/cms/mandalas`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res.data;
};

export const createNewCorrectOrderGame = async (
  data: any,
  files: any,
  file: any,
  gameLectorSound: any,
  movies: any
): Promise<any> => {
  const formData = new FormData();

  formData.append("data", JSON.stringify(data));

  if (movies.length > 0) {
    for (let i = 0; i < movies.length; i++) {
      formData.append("files", movies[i], `lessonMovie_${i}`);
    }
  }

  for (let i = 0; i < files.length; i++) {
    formData.append("files", files[i]);
  }

  formData.append("files", file, "lessonImage");

  if (gameLectorSound) {
    formData.append("files", gameLectorSound, "gameLectorSound");
  }

  const res = await instance.post(`/cms/correctOrder`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res.data;
};

export const createNewSoundsGame = async (
  data: any,
  files: any,
  file: any,
  gameLectorSound: any,
  movies: any
): Promise<any> => {
  const formData = new FormData();

  formData.append("data", JSON.stringify(data));

  if (movies.length > 0) {
    for (let i = 0; i < movies.length; i++) {
      formData.append("files", movies[i], `lessonMovie_${i}`);
    }
  }

  for (let i = 0; i < files.length; i++) {
    formData.append("files", files[i]);
  }

  if (gameLectorSound) {
    formData.append("files", gameLectorSound, "gameLectorSound");
  }

  if (file) {
    formData.append("files", file, "lessonImage");
  }

  const res = await instance.post(`/cms/sounds`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res.data;
};
