import { useEffect, useState } from "react";
import Loader from "../common/loader/loader";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteCategoryLevel,
  getCategoryLevelDetails,
  updateCategoryLevelById,
} from "../../api/cms";
import GameContainer from "../layout/GameContainer";
import { Modal, Popconfirm } from "antd";
import * as S from "./cms.styles";

const CategoryLevelForm = () => {
  const navigate = useNavigate();

  const params = useParams();

  const { id: categoryId } = params;

  const [name, setName] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [categoryLevels, setAllCategoryLevels] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  // const handleChange
  const handleFileChange = (index: number, event: any) => {
    const newFiles: any = { ...selectedFiles };
    newFiles[index] = event.target.files[0];
    setSelectedFiles(newFiles);
  };

  const handleDeleteCategoryLevel = async (id: string) => {
    if (!name || !categoryId) {
      return;
    }
    setIsLoading(true);
    await deleteCategoryLevel(id);
    setIsLoading(false);
    navigate(`/cms/category-levels/${categoryId}`);
    await fetchCategoryLevelDetails(categoryId);
  };

  const fetchCategoryLevelDetails = async (id: string) => {
    try {
      const res = await getCategoryLevelDetails(id);
      const { name, levels } = res;
      setName(name);
      setAllCategoryLevels(levels);
    } catch (error: any) {
      console.log({ error });
    }
  };

  useEffect(() => {
    if (categoryId) {
      fetchCategoryLevelDetails(categoryId);
    }
  }, []);

  const isAddNewCategoryButtonDisabled = !name;

  const deleteCategoryLevelText = isLoading ? <Loader /> : "Usuń";
  const updateCategoryLevelText = isLoading ? <Loader /> : "Aktualizuj";

  const updateCategoryLevel = async (id: string, index: number, e: any) => {
    if (!id || !selectedFiles) {
      return;
    }
    setIsLoading(true);
    await updateCategoryLevelById({ id }, selectedFiles[index]);
    const newFiles = { ...selectedFiles };
    delete newFiles[index];
    setSelectedFiles(newFiles);
    setIsLoading(false);
  };

  const isUpdateButtonDisabled = (index: number) =>
    selectedFiles && selectedFiles[index] ? false : true;

  const openModal = (id: string, name: string) => {
    setSelectedCategoryId(id);
    setSelectedCategoryName(name);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedCategoryName("");
    setSelectedCategoryId("");
    setIsModalOpen(false);
  };

  const updateCategoryLevelName = async (id: string, name: string) => {
    if (!id || !setSelectedCategoryName) {
      return;
    }
    setIsLoading(true);
    await updateCategoryLevelById({ id, name });
    categoryId && fetchCategoryLevelDetails(categoryId);
    closeModal();
    setIsLoading(false);
  };

  return (
    <GameContainer>
      <Modal
        onOk={() =>
          updateCategoryLevelName(selectedCategoryId, selectedCategoryName)
        }
        open={isModalOpen}
        onCancel={() => closeModal()}
        okText={"Zmien nazwę"}
        cancelText={"Anuluj"}
        okButtonProps={{
          disabled: selectedCategoryName === "",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
          <label>Podaj nowy numer kategorii(liczbę)</label>
          <S.Input
            value={selectedCategoryName}
            onChange={(e: any) => setSelectedCategoryName(e.target.value)}
          />
        </div>
      </Modal>
      <S.Wrapper>
        <S.Title>Poziomy {name}</S.Title>
        {categoryLevels.map((c: any, index: number) => (
          <div
            key={index}
            style={{ display: "flex", gap: "8px", alignItems: "center" }}
          >
            <S.Button onClick={() => openModal(c.id, c.level)}>Edytuj</S.Button>
            <S.Name>Poziom {c.level}</S.Name>
            {/* <S.Input
              key={index}
              onChange={(e: any) => handleFileChange(index, e)}
              value={c.level}
            /> */}
            {isLoading ? (
              <Loader />
            ) : (
              <S.Input
                key={index}
                onChange={(e: any) => handleFileChange(index, e)}
                type="file"
              />
            )}
            <S.Button
              disabled={isUpdateButtonDisabled(index)}
              onClick={(e: any) => updateCategoryLevel(c.id, index, e)}
            >
              {updateCategoryLevelText}
            </S.Button>
            <Popconfirm
              title="Usunąć poziom?"
              onConfirm={() => handleDeleteCategoryLevel(c.id)}
            >
              <S.Button disabled={isAddNewCategoryButtonDisabled}>
                {deleteCategoryLevelText}
              </S.Button>
            </Popconfirm>
          </div>
        ))}
      </S.Wrapper>
    </GameContainer>
  );
};

export default CategoryLevelForm;
