import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../App";
import { getProfileDetails } from "../../api/profile";
import { useNavigate } from "react-router-dom";
import * as S from "./ProfileAvatar.styles";

const ProfileAvatar = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const {
    currentProfileId,
    profilePicture,
    setProfilePicture,
    currentProfile,
    setCurrentProfile,
  } = authContext;

  const getProfileData = useCallback(async () => {
    if (!profilePicture) {
      setIsLoading(true);
      const res = await getProfileDetails(currentProfileId);
      const { name, picture } = res;
      setCurrentProfile(name);
      setProfilePicture(picture);
      setIsLoading(false);
    }
  }, [
    setIsLoading,
    setCurrentProfile,
    setProfilePicture,
    currentProfileId,
    profilePicture,
  ]);

  useEffect(() => {
    getProfileData();
  }, [profilePicture, getProfileData]);

  if (!currentProfileId) {
    return null;
  }

  if (isLoading) {
    return null;
  }

  const handleNavigateToProfile = () => navigate("/profile");
  return (
    <S.Wrapper>
      <S.NameContainer>
        <S.Name>{currentProfile}</S.Name>
      </S.NameContainer>
      <S.AvatarWrapper onClick={() => handleNavigateToProfile()}>
        <S.Avatar src={profilePicture} />
      </S.AvatarWrapper>
    </S.Wrapper>
  );
};

export default ProfileAvatar;
