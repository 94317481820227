import { useNavigate } from "react-router-dom";
import GameContainer from "../components/layout/GameContainer";
import Frame1 from "../assets/start-page/Frame_1.svg";
import Frame2 from "../assets/start-page/Frame_2.svg";
import Frame3 from "../assets/start-page/Frame_3.svg";
import Frame2En from "../assets/start-page/en/Frame_2.svg";
import Frame3En from "../assets/start-page/en/Frame_3.svg";
import Frame2De from "../assets/start-page/de/Frame_2.svg";
import Frame3De from "../assets/start-page/de/Frame_3.svg";
import useMediaQuery from "../hooks/useMediaQuery";
import { useContext, useEffect } from "react";
import { AuthContext } from "../App";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import * as S from "./StartPage.styles";

const StartPage = () => {
  const context = useContext(AuthContext);
  const { isUserLoggedIn } = context;
  const navigate = useNavigate();

  const navigateToLogin = () => navigate("/login");
  const navigateToRegister = () => navigate("/register");

  const isTablet = useMediaQuery("(min-width: 768px)");
  const isHigherThanHorizontalPhone = useMediaQuery("(min-height: 700px)");

  useEffect(() => {
    if (isUserLoggedIn) {
      navigate("/profile");
    }
  }, [isUserLoggedIn, navigate]);

  const { t } = useTranslation("translation", { keyPrefix: "start" });
  const { language } = i18n;

  const properFrame2 =
    language === "en" ? Frame2En : language === "de" ? Frame2De : Frame2;
  const properFrame3 =
    language === "en" ? Frame3En : language === "de" ? Frame3De : Frame3;

  return (
    <GameContainer isNav={false} showRacoon showLogo>
      <S.Wrapper>
        <S.HeaderContainer>
          <S.StyledHeader>{t("welcome")}</S.StyledHeader>
        </S.HeaderContainer>
        <S.ButtonsContainer>
          <S.StyledButton onClick={() => navigateToLogin()}>
            {t("login")}
          </S.StyledButton>
          <S.StyledButton onClick={() => navigateToRegister()}>
            {t("register")}
          </S.StyledButton>
        </S.ButtonsContainer>
        <S.InfoContainer>
          <S.InfoText>{t("games")}</S.InfoText>
          {isTablet && isHigherThanHorizontalPhone && (
            <S.FramesContainer>
              <S.SimpleFrame>
                <S.FrameContainer>
                  <S.StyledImg src={Frame1} loading="lazy" />
                </S.FrameContainer>
                <S.FrameText>{t("maths")}</S.FrameText>
              </S.SimpleFrame>
              <S.SimpleFrame>
                <S.FrameContainer style={{ marginBottom: "13px" }}>
                  <S.StyledImg src={properFrame2} loading="lazy" />
                </S.FrameContainer>
                <S.FrameText>{t("science")}</S.FrameText>
              </S.SimpleFrame>
              <S.SimpleFrame>
                <S.FrameContainer>
                  <S.StyledImg src={properFrame3} loading="lazy" />
                </S.FrameContainer>
                <S.FrameText>{t("reading")}</S.FrameText>
              </S.SimpleFrame>
            </S.FramesContainer>
          )}
        </S.InfoContainer>
      </S.Wrapper>
    </GameContainer>
  );
};

export default StartPage;
