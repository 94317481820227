import { useEffect, useState } from "react";
import { getSubjectData } from "../../api/common-api";
import { useNavigate } from "react-router-dom";
import GameContainer from "../layout/GameContainer";
import * as S from "./cms.styles";

const Cms = () => {
  const [allSubjects, setAllSubjects] = useState<any[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    const getSubjects = async () => {
      const res = await getSubjectData();
      setAllSubjects(res);
    };
    getSubjects();
  }, []);

  const handleNavigateToSubject = (id: string) => {
    navigate(`/cms/subject/${id}`);
  };

  const handleNavigate = (to: string) => {
    navigate(`/cms/${to}`);
  };

  return (
    <GameContainer backTo="/">
      <S.Wrapper>
        <S.Title>Admin CMS</S.Title>
        <S.Content>
          <S.Container>
            <S.Button onClick={() => handleNavigate("categories")}>
              Kategorie
            </S.Button>
            <S.Button onClick={() => handleNavigate("category-levels")}>
              Poziomy kategorii
            </S.Button>
            <S.Button onClick={() => handleNavigate("subjects")}>
              Tematy
            </S.Button>
          </S.Container>
          <div>
            <div>Podgląd</div>
            <S.Group>
              <div>
                <div>
                  <b>Kategorie do nauki</b>
                </div>
                <div style={{ maxHeight: 400, overflow: "auto" }}>
                  {allSubjects.map((c: any, index: number) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        gap: "8px",
                        marginBottom: 8,
                        width: "100%",
                      }}
                    >
                      <div style={{ flex: 0.5 }}>{c.categoryName}</div>
                      <div style={{ flex: 0.1 }}>{c.level}</div>
                      <div style={{ flex: 1 }}>
                        {c.subjects.map((a: any, index: number) => (
                          <S.SingleSubject
                            key={index}
                            onClick={() => handleNavigateToSubject(a.id)}
                          >{`${a.name}`}</S.SingleSubject>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </S.Group>
          </div>
        </S.Content>
      </S.Wrapper>
    </GameContainer>
  );
};

export default Cms;
