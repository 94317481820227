import positive1 from "../../assets/reactions/positive/bardzo_dobrze_female.mp3";
import positive2 from "../../assets/reactions/positive/doskonale_female.mp3";
import positive3 from "../../assets/reactions/positive/Rick_Allen_Kid_Saying_Yeah_Excited.wav";
import positive4 from "../../assets/reactions/positive/super_robota_female.mp3";
import positive5 from "../../assets/reactions/positive/super_robota_man.mp3";
import positive6 from "../../assets/reactions/positive/wspaniale.mp3";
import positive7 from "../../assets/reactions/positive/Yeah_Cheering_Childish.wav";
import positive1En from "../../assets/reactions/positive/en/Rick_Allen_Kid Saying_Yeah_Excited.wav";
import positive2En from "../../assets/reactions/positive/en/Yeah_Cheering_Childish.wav";
import positive3En from "../../assets/reactions/positive/en/excellent_male.mp3";
import positive4En from "../../assets/reactions/positive/en/fantastic_male.mp3";
import positive5En from "../../assets/reactions/positive/en/good_job_male.mp3";
import positive6En from "../../assets/reactions/positive/en/good_job_female.mp3";
import positive7En from "../../assets/reactions/positive/en/well_done_female.mp3";
import positive1De from "../../assets/reactions/positive/de/Du_hast_dich_selbst_ubertroffen.mp3";
import positive2De from "../../assets/reactions/positive/de/Rick_Allen_Kid_Saying_Yeah_Excited.wav";
import positive3De from "../../assets/reactions/positive/de/Yeah_Cheering_Childish.wav";
import positive4De from "../../assets/reactions/positive/de/fantastisch_female.mp3";
import positive5De from "../../assets/reactions/positive/de/fantastisch_male.mp3";
import positive6De from "../../assets/reactions/positive/de/gut_gemacht_female.mp3";
import positive7De from "../../assets/reactions/positive/de/gut_gemacht_male.mp3";
import positive8De from "../../assets/reactions/positive/de/toll.mp3";
import negative1 from "../../assets/reactions/negative/ooo.mp3";
import negative2 from "../../assets/reactions/negative/ojoj.mp3";
import negative3 from "../../assets/reactions/negative/ojejku.mp3";
import negative4 from "../../assets/reactions/negative/sprobuj_jeszcze_raz.mp3";
import negative5 from "../../assets/reactions/negative/zdarza_sie_nie_rezygnuj.mp3";
import negative6 from "../../assets/reactions/negative/zdarza_sie.mp3";
import negative1En from "../../assets/reactions/negative/en/Apple_Hill_Studios_Kids_Voices_Oopsie_Child.wav";
import negative2En from "../../assets/reactions/negative/en/Artlist_Original_Funny_Brass_Trombone_One_Shot_Oopsie.wav";
import negative3En from "../../assets/reactions/negative/en/Dauzkobza_Domestic_Door_Buzzer.wav";
import negative4En from "../../assets/reactions/negative/en/take_another_shot_female.mp3";
import negative5En from "../../assets/reactions/negative/en/take_another_shot_male.mp3";
import negative6En from "../../assets/reactions/negative/en/try_again_female.mp3";
import negative7En from "../../assets/reactions/negative/en/try_again_male.mp3";
import negative1De from "../../assets/reactions/negative/de/Apple_Hill_Studios_Kids_Voices_Oopsie_Child.wav";
import negative2De from "../../assets/reactions/negative/de/Artlist_Original_Funny_Brass_Trombone_One_Shot_Oopsie.wav";
import negative3De from "../../assets/reactions/negative/de/Dauzkobza_Domestic_Door_Buzzer.wav";
import negative4De from "../../assets/reactions/negative/de/Fast_geschafft_mach_s_noch_mal.mp3";
import negative5De from "../../assets/reactions/negative/de/es_passiert_gib_nicht_auf.mp3";
import i18n from "../../i18n";

const positiveReactions = [
  positive1,
  positive2,
  positive3,
  positive4,
  positive5,
  positive6,
  positive7,
];

const positiveEnReactions = [
  positive1En,
  positive2En,
  positive3En,
  positive4En,
  positive5En,
  positive6En,
  positive7En,
];

const positiveDeReactions = [
  positive1De,
  positive2De,
  positive3De,
  positive4De,
  positive5De,
  positive6De,
  positive7De,
  positive8De,
];

const negativeReactions = [
  negative1,
  negative2,
  negative3,
  negative4,
  negative5,
  negative6,
];

const negativeEnReactions = [
  negative1En,
  negative2En,
  negative3En,
  negative4En,
  negative5En,
  negative6En,
  negative7En,
];

const negativeDeReactions = [
  negative1De,
  negative2De,
  negative3De,
  negative4De,
  negative5De,
];

const ReactionSound = (isCorrect: boolean) => {
  const { language } = i18n;

  const properPositiveReactions =
    language === "pl"
      ? positiveReactions
      : language === "en"
      ? positiveEnReactions
      : positiveDeReactions;

  const properNegativeReactions =
    language === "pl"
      ? negativeReactions
      : language === "en"
      ? negativeEnReactions
      : negativeDeReactions;

  let randomReaction = "";

  const getRandomReaction = async () => {
    const correctArray = isCorrect
      ? properPositiveReactions
      : properNegativeReactions;
    const randomIndex = Math.floor(Math.random() * correctArray.length);
    const selectedRandomItem = correctArray[randomIndex];
    randomReaction = selectedRandomItem;
  };

  getRandomReaction();

  if (isCorrect) {
    return new Audio(randomReaction).play();
  }
  return new Audio(randomReaction).play();
};

export default ReactionSound;
