import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getUserStatusInfo } from "../../api/profile";
import LogoLoader from "../common/loader/logoLoader";
import * as S from "./UserStatus.styles";

const UserStatus = () => {
  const navigate = useNavigate();

  const [userStatus, setUserStatus] = useState(undefined);
  const [expDate, setExpDate] = useState("");
  const [isAdmin, setIsAdmin] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation("translation", { keyPrefix: "payment" });

  const getStatusInfo = async () => {
    setIsLoading(true);
    const res = await getUserStatusInfo();
    const { isActive, isAdmin, formattedDate } = res;
    setIsAdmin(isAdmin);
    setUserStatus(isActive);
    setExpDate(formattedDate);
    setIsLoading(false);
  };

  useEffect(() => {
    getStatusInfo();
  }, [userStatus, expDate]);

  useEffect(() => {
    if (isAdmin !== undefined) {
      localStorage.setItem("isAdmin", isAdmin);
    }
    if (userStatus !== undefined) {
      localStorage.setItem("userStatus", userStatus);
    }
  }, [isAdmin, userStatus]);

  const storedUserStatusInfo = localStorage.getItem("userStatus");
  const storedUserStatus = JSON.parse(storedUserStatusInfo || "false");

  const userStatusText =
    userStatus === false ? `${t("inActive")}` : `${t("active")}`;

  const handleGoToPayment = () => {
    navigate(`/payment`);
  };

  const handleGoToSub = () => {
    navigate(`/subscription`);
  };

  if (isLoading) {
    return <LogoLoader />;
  }

  return (
    <S.SubscriptionInfoContainer>
      <S.StatusContainer>
        {t("status")}:{" "}
        <S.Status isActive={storedUserStatus}>{userStatusText}</S.Status>
      </S.StatusContainer>
      {/* {storedUserStatus && (
        <S.StatusContainer>
          {t("expireDate")}: <S.ExpirationDate>{expDate}</S.ExpirationDate>
        </S.StatusContainer>
      )} */}
      {!storedUserStatus ? (
        <S.StatusContainer>
          <S.BuyNowButton onClick={() => handleGoToPayment()}>
            {t("buy")}
          </S.BuyNowButton>
        </S.StatusContainer>
      ) : (
        <S.StatusContainer>
          <S.BuyNowButton onClick={() => handleGoToSub()}>
            {t("manage")}
          </S.BuyNowButton>
        </S.StatusContainer>
      )}
    </S.SubscriptionInfoContainer>
  );
};

export default UserStatus;
