import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Select } from "antd";
import GameContainer from "../layout/GameContainer";
import { v4 as uuidv4 } from "uuid";
import PairTogetherRow from "./PairTogetherRow";
import MandalaRow from "./MandalaRow";
import CorrectOrderRow from "./CorrectOrderRow";
import SoundsRow from "./SoundsRow";
import {
  createNewCorrectOrderGame,
  createNewMandalasGame,
  createNewPairTogetherGame,
  createNewPickCorrectGame,
  createNewSoundsGame,
  getLessonById,
  getLessonLocalisation,
  updateLessonById,
} from "../../api/cms";
import * as S from "./cms.styles";

export enum gameTypes {
  NONE = "NONE",
  PICK_CORRECT = "PICK_CORRECT",
  PAIR_TOGETHER = "PAIR_TOGETHER",
  MANDALAS = "MANDALAS",
  CORRECT_ORDER = "CORRECT_ORDER",
  SOUNDS = "SOUNDS",
}

export interface IImageTextPair {
  id: string;
  word?: string;
  image2?: any;
  image: any;
}

export interface IManadalaRow {
  id: string;
  isCorrect: boolean;
  image: any;
}

export interface ICorrectOrderRow {
  id: string;
  image: any;
  order: string;
}

export interface ISoundsRow {
  id: string;
  image: any;
  isCorrect: boolean;
}

const orderOptions = [
  {
    value: "1",
    label: "1",
  },
  {
    value: "2",
    label: "2",
  },
  {
    value: "3",
    label: "3",
  },
  {
    value: "4",
    label: "4",
  },
];

const inequalityOptions = [
  {
    value: true,
    label: "Większe niż",
  },
  {
    value: false,
    label: "Mniejsze niż",
  },
];

const LessonForm = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [value, setValue] = useState<any[]>([]);
  const [movies, setMovies] = useState<File[]>([]);
  const [file, setFile] = useState<File>();
  const [pickedGame, setPickedGame] = useState<gameTypes>(gameTypes.NONE);
  const [imageTextPairs, setImageTextPairs] = useState<IImageTextPair[]>([]);
  const [mandalasRow, setMandalasRow] = useState<IManadalaRow[]>([]);
  const [correctOrderRow, setCorrectOrderRow] = useState<ICorrectOrderRow[]>(
    []
  );
  const [soundsRow, setSoundsRow] = useState<ISoundsRow[]>([]);
  const [soundFile, setSoundFile] = useState<File>();
  const [gameLectorSound, setGameLectorSound] = useState<File>();

  const [inequality, setInequality] = useState(false);
  const [isArrow, setIsArrow] = useState(false);

  const [isForFree, setIsForFree] = useState(false);

  const [subjectName, setSubjectName] = useState("");
  const [subjectLevel, setSubjectLevel] = useState("");
  const [subjectCategory, setSubjectCategory] = useState("");

  let options: any[] = [];

  const handleChange = (value: any) => {
    setValue(value);
  };

  const { id: subjectId, lessonId = "" } = params;

  const fetchLocalization = async () => {
    if (subjectId) {
      const res = await getLessonLocalisation(subjectId);
      const { name, level, categoryName } = res;
      setSubjectCategory(categoryName);
      setSubjectLevel(level);
      setSubjectName(name);
    }
  };

  useEffect(() => {
    fetchLocalization();
    if (lessonId) {
      const fetchLessonDetail = async () => {
        const res = await getLessonById(lessonId);
        const { name, value, isForFree } = res;
        setName(name);
        setValue(value);
        setIsForFree(isForFree);
      };
      fetchLessonDetail();
    }
  }, [lessonId, subjectId]);

  const handleSubmit = async () => {
    if (!name || !subjectId || !file) {
      return;
    }
    setIsLoading(true);
    const pickCorrectGame = async (gameType: string) => {
      switch (gameType) {
        case "PAIR_TOGETHER":
          const files = imageTextPairs.map((a) => a.image);
          const files2 = imageTextPairs
            .map((a) => a.image2)
            .filter((a) => a !== ""); ///images instead of words *strings*
          return await createNewPairTogetherGame(
            {
              name,
              subjectId,
              value,
              gameType: pickedGame,
              mediaDetails: imageTextPairs.map((a) => a.word),
              isForFree,
            },
            files,
            file,
            gameLectorSound,
            files2,
            movies
          );
        case "MANDALAS":
          const mandalaFiles = mandalasRow.map((a) => a.image);
          return await createNewMandalasGame(
            {
              name,
              subjectId,
              value,
              gameType: pickedGame,
              mediaDetails: mandalasRow.map((a) => a.isCorrect),
              isForFree,
            },
            mandalaFiles,
            file,
            gameLectorSound,
            movies
          );
        case "CORRECT_ORDER":
          const pickCorrectFiles = correctOrderRow.map((a) => a.image);
          return await createNewCorrectOrderGame(
            {
              name,
              subjectId,
              value,
              gameType: pickedGame,
              mediaDetails: correctOrderRow.map((a) => a.order),
              isGreaterThan: inequality,
              isForFree,
              isArrow,
            },
            pickCorrectFiles,
            file,
            gameLectorSound,
            movies
          );
        case "SOUNDS":
          const soundFiles = [...soundsRow.map((a) => a.image), soundFile];
          return await createNewSoundsGame(
            {
              name,
              subjectId,
              value,
              gameType: pickedGame,
              mediaDetails: soundsRow.map((a) => a.isCorrect),
              isForFree,
            },
            soundFiles,
            file,
            gameLectorSound,
            movies
          );
        default:
          return await createNewPickCorrectGame(
            {
              name,
              subjectId,
              value,
              gameType: pickedGame,
              isForFree,
            },
            file,
            gameLectorSound,
            movies
          );
      }
    };
    pickCorrectGame(pickedGame);
    setIsLoading(false);
    navigate(`/cms/subject/${subjectId}`);
  };

  const isButtonDisabled = () => {
    if (lessonId) {
      return !name;
    } else {
      return !name || !file;
    }
  };

  const handleUpdateLesson = async () => {
    setIsLoading(true);
    if (!name || !subjectId) {
      return;
    }
    await updateLessonById(
      { id: lessonId, name, value, isForFree },
      file,
      gameLectorSound,
      movies
    );
    navigate(`/cms/subject/${subjectId}`);
    setIsLoading(false);
  };

  const handlePickGameType = (gameType: gameTypes) => {
    pickedGame === gameType
      ? setPickedGame(gameTypes.NONE)
      : setPickedGame(gameType);
  };

  const handleDeletePairTogetherFromList = (id: string) => {
    setImageTextPairs((prev) => [...prev.filter((x) => x.id !== id)]);
  };

  const handlePairTogetherData = (e: any, rowId: string) => {
    const pairTogether = [...imageTextPairs];
    pairTogether.map((a: any) => {
      if (a.id === rowId) {
        e.target && e.target?.name === "word"
          ? (a.word = e.target.value)
          : e.target && e.target?.name === "image"
          ? (a.image = e.target.files[0])
          : (a.image2 = e.target.files[0]);
      }
      return a;
    });
    setImageTextPairs(pairTogether);
  };

  const renderPairTogetherRows = () =>
    imageTextPairs.map((i, index: number) => (
      <PairTogetherRow
        key={index}
        {...i}
        index={index}
        onDelete={() => handleDeletePairTogetherFromList(i.id)}
        onChange={(e: any) => handlePairTogetherData(e, i.id)}
      />
    ));

  const handleAddPairTogetherRow = (e: any) => {
    e.preventDefault();
    const newRecord = {
      id: uuidv4(),
      word: "",
      image: "",
      image2: "",
    };
    const updatedRecords = [...imageTextPairs, newRecord];
    setImageTextPairs(updatedRecords);
  };

  const handleAddMandalaRow = (e: any) => {
    e.preventDefault();
    const newRecord = {
      id: uuidv4(),
      image: "",
      isCorrect: false,
    };
    const updatedRecords = [...mandalasRow, newRecord];
    setMandalasRow(updatedRecords);
  };

  const renderMandalaRow = () =>
    mandalasRow.map((i, index: number) => (
      <MandalaRow
        key={index}
        {...i}
        index={index}
        onDelete={() => handleDeleteMandalaRowFromList(i.id)}
        onChange={(e: any) => handleMandalaData(e, i.id)}
      />
    ));

  const handleDeleteMandalaRowFromList = (id: string) => {
    setMandalasRow((prev) => [...prev.filter((x) => x.id !== id)]);
  };

  const handleMandalaData = (e: any, rowId: string) => {
    const mandalas = [...mandalasRow];
    mandalas.map((a: any) => {
      if (a.id === rowId) {
        e.target && e.target?.name === "isCorrect"
          ? (a.isCorrect = !a.isCorrect)
          : (a.image = e.target.files[0]);
      }
      return a;
    });
    setMandalasRow(mandalas);
  };

  const handleAddCorrectOrderRow = (e: any) => {
    e.preventDefault();
    const newRecord = {
      id: uuidv4(),
      image: "",
      order: "",
    };
    const updatedRecords = [...correctOrderRow, newRecord];
    setCorrectOrderRow(updatedRecords);
  };

  const renderCorrectOrderRows = () =>
    correctOrderRow.map((i, index: number) => (
      <CorrectOrderRow
        key={index}
        {...i}
        options={availableCorrectOrderOptions}
        index={index}
        onDelete={() => handleDeleteCorrectOrderRow(i.id)}
        onChange={(e: any) => handleCorrectOrderData(e, i.id)}
      />
    ));

  const handleDeleteCorrectOrderRow = (id: string) => {
    setCorrectOrderRow((prev) => [...prev.filter((x) => x.id !== id)]);
  };

  const handleCorrectOrderData = (e: any, rowId: string) => {
    const correctOrder = [...correctOrderRow];
    correctOrder.map((a: any) => {
      if (a.id === rowId) {
        e.target && e.target?.name === "image"
          ? (a.image = e.target.files[0])
          : (a.order = e.value);
      }
      return a;
    });
    setCorrectOrderRow(correctOrder);
  };

  const usedOptions = correctOrderRow.map((x: any) => x.order);

  const availableCorrectOrderOptions = orderOptions.filter((s: any) =>
    usedOptions ? !usedOptions.includes(s.value) : orderOptions
  );

  const handleAddSoundsRow = (e: any) => {
    e.preventDefault();
    const newRecord = {
      id: uuidv4(),
      image: "",
      isCorrect: false,
    };
    const updatedRecords = [...soundsRow, newRecord];
    setSoundsRow(updatedRecords);
  };

  const renderSoundsRows = () =>
    soundsRow.map((i, index: number) => (
      <SoundsRow
        key={index}
        {...i}
        index={index}
        onDelete={() => handleDeleteSoundsRow(i.id)}
        onChange={(e: any) => handleSoundsData(e, i.id)}
      />
    ));

  const handleDeleteSoundsRow = (id: string) => {
    setSoundsRow((prev) => [...prev.filter((x) => x.id !== id)]);
  };

  const handleSoundsData = (e: any, rowId: string) => {
    const soundsData = [...soundsRow];
    soundsData.map((a: any) => {
      if (a.id === rowId) {
        e.target && e.target?.name === "isCorrect"
          ? (a.isCorrect = !a.isCorrect)
          : (a.image = e.target.files[0]);
      }
      return a;
    });
    setSoundsRow(soundsData);
  };

  return (
    <GameContainer isLoading={isLoading}>
      <S.Wrapper>
        <S.Title>
          {subjectCategory} {subjectLevel} - {subjectName}
        </S.Title>
        <S.Title>Dodaj nową lekcje</S.Title>
        <S.Group>
          <S.Row>
            <S.Label>Nazwa</S.Label>
            <S.Input
              value={name}
              onChange={(e: any) => setName(e.target.value)}
            />
          </S.Row>
          <S.Row>
            <S.Label>{lessonId ? "Nowy obrazek" : "Obrazek"}</S.Label>
            <S.Input
              onChange={(e: any) => setFile(e.target.files[0])}
              type="file"
            />
          </S.Row>
          <S.Row>
            <S.Label>
              {lessonId ? "Nowy lektor do gry" : "Dodaj lektora do gry"}
            </S.Label>
            <input
              type="file"
              onChange={(e: any) => setGameLectorSound(e.target.files[0])}
            />
          </S.Row>
          <S.Row>
            <S.Label>Słówka</S.Label>
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Wpisz słówka"
              onChange={handleChange}
              options={options}
              maxTagCount={5}
              value={value}
            />
          </S.Row>
          <S.Row>
            <div>
              <S.Label>{lessonId ? "Nowe filmiki" : "Filmiki"}</S.Label>
              <input
                type="file"
                onChange={(e: any) => setMovies(e.target.files)}
                multiple
              />
            </div>
          </S.Row>
          <S.Row>
            <S.Label>Darmowa?</S.Label>
            <input
              name="isForFree"
              type="checkbox"
              checked={isForFree}
              onChange={(e: any) => setIsForFree(!isForFree)}
              style={{ width: "20px", height: "20px" }}
            />
          </S.Row>
          {!lessonId && (
            <>
              <S.Row>
                <S.Label>Wybierz typ gry</S.Label>
              </S.Row>
              <S.GameTypesContainer>
                <S.Button
                  onClick={() => handlePickGameType(gameTypes.PICK_CORRECT)}
                >
                  Pick correct
                </S.Button>
                <S.Button
                  onClick={() => handlePickGameType(gameTypes.PAIR_TOGETHER)}
                >
                  Pair together
                </S.Button>
                <S.Button
                  onClick={() => handlePickGameType(gameTypes.MANDALAS)}
                >
                  Mandalas
                </S.Button>
                <S.Button
                  onClick={() => handlePickGameType(gameTypes.CORRECT_ORDER)}
                >
                  Correct order
                </S.Button>
                <S.Button onClick={() => handlePickGameType(gameTypes.SOUNDS)}>
                  Sounds
                </S.Button>
              </S.GameTypesContainer>
            </>
          )}
          {!lessonId && <S.Title>Wybrano: {pickedGame}</S.Title>}
          {pickedGame === gameTypes.PAIR_TOGETHER && (
            <S.Container>
              <S.Button onClick={(e: any) => handleAddPairTogetherRow(e)}>
                Dodaj nową parę
              </S.Button>
              <S.Container>{renderPairTogetherRows()}</S.Container>
            </S.Container>
          )}
          {pickedGame === gameTypes.MANDALAS && (
            <S.Container>
              <S.Button onClick={(e: any) => handleAddMandalaRow(e)}>
                Dodaj nową mandale
              </S.Button>
              <S.Container>{renderMandalaRow()} </S.Container>
            </S.Container>
          )}
          {pickedGame === gameTypes.CORRECT_ORDER && (
            <S.Container>
              <S.Button onClick={(e: any) => handleAddCorrectOrderRow(e)}>
                Dodaj
              </S.Button>
              <S.Text>Rodzaj nierówności</S.Text>
              <Select
                options={inequalityOptions}
                style={{ width: 150 }}
                onChange={(e: any) => setInequality(e)}
                defaultValue={inequalityOptions.find((f) => f.value === false)}
              />
              <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                <S.Text>Lub strzałka</S.Text>
                <input
                  name="isArrow"
                  type="checkbox"
                  checked={isArrow}
                  onChange={(e: any) => setIsArrow(!isArrow)}
                  style={{ width: "20px", height: "20px" }}
                />
              </div>
              <S.Container>{renderCorrectOrderRows()}</S.Container>
            </S.Container>
          )}
          {pickedGame === gameTypes.SOUNDS && (
            <S.Container>
              <S.Text>Dodaj dzwięk</S.Text>
              <input
                type="file"
                onChange={(e: any) => setSoundFile(e.target.files[0])}
              />
              <S.Button onClick={(e: any) => handleAddSoundsRow(e)}>
                Dodaj zdjęcia
              </S.Button>
              <S.Container>{renderSoundsRows()}</S.Container>
            </S.Container>
          )}
        </S.Group>
        <S.Group>
          {lessonId ? (
            <div>
              <S.Button
                onClick={() => handleUpdateLesson()}
                disabled={isButtonDisabled()}
              >
                Aktualizuj
              </S.Button>
            </div>
          ) : (
            <div>
              <S.Button
                onClick={() => handleSubmit()}
                disabled={isButtonDisabled()}
              >
                Dodaj
              </S.Button>
            </div>
          )}
        </S.Group>
      </S.Wrapper>
    </GameContainer>
  );
};

export default LessonForm;
