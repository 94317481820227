import { useCallback, useContext, useEffect, useState } from "react";
import i18n from "../../i18n";
import { AuthContext } from "../../App";
import { useLocation, useNavigate } from "react-router-dom";
import { setProfileDefaultLanguage } from "../../api/profile";
import * as S from "./LocaleSwitcher.styles";

const languagesFlags: any[] = [
  {
    lng: "pl",
    flag: <S.PlFlag />,
    name: "Polski",
  },
  {
    lng: "en",
    flag: <S.EnFlag />,
    name: "British English",
  },
  {
    lng: "de",
    flag: <S.DeFlag />,
    name: "Deutsch",
  },
];

const LocaleSwitcher = () => {
  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isUserLoggedIn, currentProfileId, currentProfileLanguage } = context;
  const { language } = i18n;

  const [currentLng, setCurrentLng] = useState(language);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHovered, setIsHovered] = useState<any>(undefined);

  const handleHover = (index: any) => {
    setIsHovered(index);
  };

  const isSettingPage = pathname === "/settings";

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng).then(async () => {
      currentProfileId &&
        (await setProfileDefaultLanguage(currentProfileId, lng));
      if (isSettingPage) {
        return;
      } else if (isUserLoggedIn) {
        return navigate("/profile");
      } else if (isUserLoggedIn) {
        return navigate("/profile");
      }
    });
  };

  const handleClose = () => setIsModalOpen(false);

  const handleOpenModal = (e: any) => {
    setIsModalOpen(!isModalOpen);
  };

  const handleChangeLanguage = (lng: string) => {
    changeLanguage(lng);
    setCurrentLng(lng);
    setIsModalOpen(false);
  };

  const renderFlag = useCallback(() => {
    switch (currentLng) {
      case "pl": {
        return <S.PlFlag />;
      }
      case "en": {
        return <S.EnFlag />;
      }
      case "de": {
        return <S.DeFlag />;
      }
      default: {
        return <S.PlFlag />;
      }
    }
  }, [currentLng]);

  useEffect(() => {
    setCurrentLng(language);
  }, [language]);

  useEffect(() => {
    if (currentProfileLanguage) {
      setCurrentLng(currentProfileLanguage);
      i18n.changeLanguage(currentProfileLanguage);
    }
  }, [currentProfileLanguage]);

  return (
    <S.Wrapper onClick={(e) => handleOpenModal(e)}>
      {isModalOpen && (
        <S.LanguagePickerWrapper>
          <S.LanguagePickerContainer onClick={(e) => e.stopPropagation()}>
            <S.CloseContainer onClick={handleClose}>
              {/* <S.CloseIcon /> */}X
            </S.CloseContainer>
            {languagesFlags.map((language, index: number) => {
              const { lng, flag, name } = language;
              return (
                <S.LanguageContainer
                  key={index}
                  onClick={() => {
                    handleChangeLanguage(lng);
                  }}
                  onMouseOver={() => handleHover(index)}
                  onMouseLeave={() => handleHover(undefined)}
                >
                  <S.FlagWrapper isHovered={isHovered === index}>
                    {flag}
                  </S.FlagWrapper>
                  <S.LanguageInfoContainer>
                    <S.LanguageName>{name}</S.LanguageName>
                  </S.LanguageInfoContainer>
                </S.LanguageContainer>
              );
            })}
          </S.LanguagePickerContainer>
        </S.LanguagePickerWrapper>
      )}
      {renderFlag()}
    </S.Wrapper>
  );
};

export default LocaleSwitcher;
