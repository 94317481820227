import { useNavigate } from "react-router-dom";
import * as S from "./lessonContainer.styles";
import { useTranslation } from "react-i18next";

export interface IGameProps {
  id: string;
  name: string;
  index: number;
  progress: boolean;
  imgSrc: string;
}

const OnlyGameContainer = (props: IGameProps) => {
  const { index, id: lessonId, progress, imgSrc } = props;
  const navigate = useNavigate();

  const backgroundColor = "rgba(255, 184, 0, 1)";
  const color = "rgba(21, 58, 92, 1)";
  const progressValue = progress ? 100 : 0;

  const { t } = useTranslation("translation", { keyPrefix: "lesson" });

  return (
    <S.Wrapper onClick={() => navigate(`/game/${lessonId}`)}>
      <S.InnerWrapper>
        <S.ImageBackground src={imgSrc} alt="" />
        <S.NameContainer $isGame>
          <S.Name>
            {index + 1}. {t("game")}
          </S.Name>
        </S.NameContainer>
        <S.ProgressContainer
          background={backgroundColor}
          progress={progressValue}
          color={color}
        >
          {progress && <S.Progress>100%</S.Progress>}
        </S.ProgressContainer>
      </S.InnerWrapper>
    </S.Wrapper>
  );
};

export default OnlyGameContainer;
