import { ICorrectOrderRow } from "./lessonForm";
import Select from "react-select";
import * as S from "./cms.styles";

interface ICorrectOrderRowExtended extends ICorrectOrderRow {
  index: number;
  onDelete: (index: number) => void;
  onChange: (e: any, rowId: string) => void;
  options: any[];
}

const CorrectOrderRow = (props: ICorrectOrderRowExtended) => {
  const { id, index, image, onDelete, onChange, options = [] } = props;
  return (
    <S.GameRowContainer>
      <input
        name="image"
        defaultValue={image}
        onChange={(e: any) => onChange(e, id)}
        type="file"
      />
      <S.Text>Kolejność</S.Text>
      <Select
        options={options}
        onChange={(e: any) => onChange(e, id)}
        placeholder="Wybierz kolejność"
      />
      <S.DeleteButton type="button" onClick={() => onDelete(index)}>
        usuń
      </S.DeleteButton>
    </S.GameRowContainer>
  );
};

export default CorrectOrderRow;
