import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  background-color: white;
  padding: 20px 40px;
  border-radius: 24px;
  position: relative;
  @media (min-width: 768px) and (min-height: 600px) {
    padding: 40px 80px;
  }
`;

export const ProfileImage = styled.img`
  width: 70%;
  height: 70%;
`;

export const NewProfileButton = styled.button`
  background-color: rgb(117 93 243);
  border: none;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  transition: 400ms;
  &:hover {
    box-shadow: 0 0 12px 12px rgb(254 171 7);
  }
  @media (min-width: 700px) and (min-height: 600px) {
    width: 140px;
    height: 140px;
  }
  @media (min-width: 1200px) {
    width: 200px;
    height: 200px;
    padding: 20px;
  }
`;

export const Profiles = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
`;

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const ProfileBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: 400ms;
  @media (min-width: 700px) and (min-height: 600px) {
    width: 140px;
    height: 140px;
  }
  @media (min-width: 1200px) {
    width: 200px;
    height: 200px;
  }
  &:hover {
    box-shadow: 0 0 12px 12px rgb(254 171 7);
  }
`;

export const NameContainer = styled.div``;

export const Name = styled.h3`
  margin: 0;
  font-family: Nunito;
  font-size: ${(props) => props.theme.headerFontSize};
  font-weight: 900;
  line-height: 43px;
  text-align: center;
`;

export const SubscriptionInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  gap: 8px;
`;

export const StatusContainer = styled.div``;

export const Status = styled.span<{ isActive: boolean }>`
  font-size: ${(props) => props.theme.labelFontSize};
  font-weight: 600;
  color: ${({ isActive }) => (isActive ? "green" : "red")};
`;

export const ExpirationDate = styled.span`
  font-weight: 600;
`;

export const BuyNowButton = styled.button`
  background-color: #f1c40f;
  font-size: ${(props) => props.theme.buttonFontSize};
`;

export const BirdContainer = styled.div`
  position: absolute;
  top: -98px;
  right: 60px;
`;

export const StyledImage = styled.img`
  max-width: 100px;
  max-height: 106px;
`;

export const AddNewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const PlusImage = styled.img`
  max-width: 30px;
  max-height: 30px;
`;

export const AddNewProfileText = styled.span`
  font-family: Nunito;
  font-size: ${(props) => props.theme.labelFontSize};
  font-weight: 800;
  line-height: 24px;
  text-align: center;
  color: white;
`;

export const EditButton = styled.button`
  font-family: Nunito;
  color: white;
  display: flex;
  justify-content: center;
  border: none;
  cursor: pointer;
  font-size: ${(props) => props.theme.buttonFontSize};
  font-weight: 800;
  align-items: center;
  background-color: rgb(117 93 243);
  border-radius: 8px;
  padding: 2px 4px;
  transition: 400ms;
  &:hover {
    box-shadow: 0 0 12px 12px rgb(254 171 7);
  }
`;

export const CmsButton = styled.button`
  font-family: Nunito;
  color: white;
  display: flex;
  justify-content: center;
  border: none;
  cursor: pointer;
  font-size: ${(props) => props.theme.buttonFontSize};
  font-weight: 800;
  align-items: center;
  background-color: rgb(117 93 243);
  border-radius: 8px;
  padding: 2px 4px;
  transition: 400ms;
  &:hover {
    box-shadow: 0 0 12px 12px rgb(254 171 7);
  }
`;
