import { useEffect, useState } from "react";
import { ICategory, ISelectOption } from "../../utils/types";
import { useNavigate } from "react-router-dom";
import GameContainer from "../layout/GameContainer";
import Select from "react-select";
import * as S from "./cms.styles";
import { languages } from "../../utils/languagues";
import { Popconfirm } from "antd";
import {
  createNewCategory,
  deleteCategory,
  getCategoriesData,
} from "../../api/cms";

const CategoryList = () => {
  const navigate = useNavigate();

  const [newCategoryName, setNewCategoryName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setAllCategories] = useState<ICategory[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<ISelectOption>({
    value: "pl",
    label: "Polski",
  });
  const [file, setFile] = useState<File>();
  const getCategories = async () => {
    const res = await getCategoriesData();
    setAllCategories(res);
  };

  const handleAddNewCategory = async () => {
    setIsLoading(true);
    await createNewCategory({
      data: { name: newCategoryName, language: selectedLanguage?.value },
      file,
    });
    await getCategories();
    setNewCategoryName("");
    setIsLoading(false);
  };

  const handleDeleteCategory = async (id: string) => {
    setIsLoading(true);
    await deleteCategory(id);
    await getCategories();
    setNewCategoryName("");
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getCategories();
    setIsLoading(false);
  }, []);

  const isAddNewCategoryButtonDisabled = !newCategoryName || !file;

  const handleNavigateToCategory = (id: string) => {
    navigate(`/cms/categories/${id}`);
  };

  return (
    <GameContainer isLoading={isLoading} backTo="/cms">
      <S.Wrapper>
        <S.Title>Nowa kategoria</S.Title>
        <S.Group>
          <S.Row>
            <S.Label>Nazwa:</S.Label>
            <S.Input
              value={newCategoryName}
              onChange={(e: any) => setNewCategoryName(e.target.value)}
            />
          </S.Row>
          <S.Row>
            <S.Label>Obrazek:</S.Label>
            <S.Input
              onChange={(e: any) => setFile(e.target.files[0])}
              type="file"
            />
          </S.Row>
          <S.Row>
            <S.Label>Język:</S.Label>
            <Select
              ///@ts-ignore ??? TODO
              options={languages || []}
              value={selectedLanguage}
              onChange={(e: any) => setSelectedLanguage(e)}
              placeholder="Wybierz język"
            />
          </S.Row>
        </S.Group>
        <S.Button
          onClick={() => handleAddNewCategory()}
          disabled={isAddNewCategoryButtonDisabled}
        >
          Zatwierdź
        </S.Button>
        <S.Group>
          {categories.map((c: ICategory, index: number) => (
            <S.RowContainer key={index}>
              <S.Name>{c.name}</S.Name>
              <Popconfirm
                title="Usunąć kategorie?"
                onConfirm={() => handleDeleteCategory(c.id)}
                okText="Yes"
                cancelText="No"
              >
                <S.Button>Usuń</S.Button>
              </Popconfirm>
              <S.Button onClick={() => handleNavigateToCategory(c.id)}>
                Edytuj
              </S.Button>
            </S.RowContainer>
          ))}
        </S.Group>
      </S.Wrapper>
    </GameContainer>
  );
};

export default CategoryList;
