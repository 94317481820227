import axios from "axios";

const PORT = process.env.REACT_APP_SERVER_PORT
  ? `:${process.env.REACT_APP_SERVER_PORT}`
  : "";
const BASE_URL = process.env.REACT_APP_SERVER_IP
  ? `${process.env.REACT_APP_SERVER_IP}${PORT}/api/v1`
  : "http://localhost:4000/api/v1";

export const instance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

// instance.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (error) {
//     // console.log({ error });
//     if (error.response.status === 400) {
//       return Promise.reject({
//         message: "You've recieved an error!",
//       });
//     }
//     if (error.response.status === 401) {
//       return Promise.reject({
//         message: "You've recieved an error!",
//       });
//     }
//     return Promise.reject(error);
//   }
// );

axios.defaults.withCredentials = true;

export const login = async (params: {
  email: string;
  password: string;
}): Promise<any> => {
  try {
    const res = await instance.post(`/auth/login`, { ...params });
    return res.data;
  } catch (error: any) {
    return {
      isError: true,
      message: error.response?.data || "An error occured during login",
    };
  }
};

export const register = async (params: {
  email: string;
  password: string;
  defaultLng: string;
}): Promise<any> => {
  try {
    const res = await instance.post(`/auth/register`, { ...params });
    return res.data;
  } catch (error: any) {
    return {
      isError: true,
      message: error.response?.data || "An error occured during register",
    };
  }
};

export const verifyUser = async (verificationCode: string) => {
  try {
    const res = await instance.post(`user/verify`, { verificationCode });
    return res.data;
  } catch (error: any) {
    return {
      isError: true,
      message: error.response?.data || "An error occured during confirmation",
    };
  }
};

export const changePassword = async (params: {
  password: string;
  newPassword: string;
  verificationCode: string;
}): Promise<any> => {
  try {
    const res = await instance.post(`/auth/changePassword`, { ...params });
    return res.data;
  } catch (error: any) {
    return {
      isError: true,
      message: error.response.data,
    };
  }
};

export const logout = async (): Promise<any> => {
  try {
    const res = await instance.post(`/auth/logout`);
    return res.data;
  } catch (error: any) {
    return console.log({ error });
  }
};
interface INewCategoryLevel {
  data: {
    categoryId: string;
    level: string;
  };
  file: any;
}

export const createNewCategoryLevel = async (
  params: INewCategoryLevel
): Promise<any> => {
  const formData = new FormData();
  const { data, file } = params;

  formData.append("data", JSON.stringify(data));
  formData.append("file", file);

  const res = await instance.post(`/category/level`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res.data;
};

export const getCmsData = async (): Promise<any> => {
  const res = await instance.get(`/cms`);
  return res.data;
};

export const getCategoryLevelsData = async (): Promise<any> => {
  const res = await instance.get(`/cms/category-levels`);
  return res.data;
};

export const getSubjectData = async (): Promise<any> => {
  const res = await instance.get(`/cms/subjects`);
  return res.data;
};

interface INewSubject {
  name: string;
  categoryLevelId: string;
  file: any;
}

export const createNewSubject = async (params: INewSubject): Promise<any> => {
  const formData = new FormData();

  const { name, categoryLevelId, file } = params;

  const data = { name, categoryLevelId };

  formData.append("data", JSON.stringify(data));
  formData.append("file", file);

  const res = await instance.post(`/subject`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res.data;
};

export const getGameDataByLessonId = async (id: string): Promise<any> => {
  const res = await instance.get(`/lesson/game/${id}`);
  return res.data;
};

export const getCategoryLevelsById = async (id: string): Promise<any> => {
  const res = await instance.get(`/category/user/${id}`);
  return res.data;
};

export const getCategoryLevelSubjectsById = async (
  id: string
): Promise<any> => {
  const res = await instance.get(`/category/user/category-level/${id}`);
  return res.data;
};

export const getLessonsBySubjectId = async (id: string): Promise<any> => {
  const res = await instance.get(`/category/user/subject/${id}`);
  return res.data;
};

export const getLessonDetailsById = async (id: string): Promise<any> => {
  try {
    const res = await instance.get(`/category/user/lesson/${id}`);
    return res.data;
  } catch (error: any) {
    return {
      isError: true,
      message: error.response.data,
    };
  }
};

interface INewPaymentSession {
  option: {
    priceId: string;
    payDays: number;
    currency: string;
    price: string;
  };
}

export const createNewPaymentSession = async (
  params: INewPaymentSession
): Promise<any> => {
  const res = await instance.post(`/payment/new`, { ...params });
  return res.data;
};

export const getPricesOptions = async (id: string): Promise<any> => {
  if (!id) {
    return;
  }
  const res = await instance.get(`/payment/options/${id}`);
  return res.data || [];
};

export const getUserSession = async (): Promise<any> => {
  try {
    const res = await instance.get(`/auth/session`);
    return res.data;
  } catch (error: any) {
    return {
      isError: true,
      message: error.response.data,
    };
  }
};

export const handleLessonResult = async (id: string): Promise<any> => {
  const res = await instance.post(`/lesson/result/${id}`);
  return res.data;
};

export const initPasswordChange = async (): Promise<any> => {
  const res = await instance.post(`/auth/initPasswordChange`);
  return res.data;
};

export const initPasswordReset = async (email: string): Promise<any> => {
  const res = await instance.post(`/auth/initPasswordReset`, { email });
  return res.data;
};
