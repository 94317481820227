import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import Register from "./components/register/register";
import Profile from "./components/profile/profile";
import NewProfile from "./components/profile/new-profile/new-profile";
import Login from "./components/login/login";
import Cms from "./components/cms/cms";
import SubjectForm from "./components/cms/SubjectForm";
import LessonForm from "./components/cms/lessonForm";
import CategoryList from "./components/cms/categoryList";
import CategoryForm from "./components/cms/categoryForm";
import CategoryLevelsList from "./components/cms/categoryLevelsList";
import CategoryLevelForm from "./components/cms/categoryLevelForm";
import SubjectList from "./components/cms/subjectList";
import PickCategory from "./components/games/pickCategoryForm";
import PickCategoryLevel from "./components/games/pickCategoryLevelForm";
import PickSubject from "./components/games/pickSubjectForm";
import PickLesson from "./components/games/pickLessonForm";
import Lesson from "./components/games/lesson";
import { ToastContainer } from "react-toastify";
import WelcomePage from "./pages/WelcomePage";
import Payment from "./components/payment/Payment";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentExecute from "./components/payment/PaymentExecute";
import FailurePayment from "./components/payment/FailurePayment";
import SuccessPayement from "./components/payment/SuccessPayment";
import { createContext, useEffect, useState } from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import StartPage from "./pages/StartPage";
import { getUserSession } from "./api/common-api";
import { ThemeProvider } from "styled-components";
import { themeSettings } from "./utils/themeSettings";
import { FontSizeTheme } from "./utils/types";
import ProtectedRoute from "./utils/protectedRoute";
import LoggedInRoute from "./utils/loggedInRoute";
import SettingsPage from "./components/settings/Settings";
import Subscription from "./components/payment/Subscription";
import Confirmation from "./components/Confirmation/Confirmation";
import ValidateAccont from "./components/ValidateAccount/ValidateAccount";
import PasswordChange from "./components/login/PasswordChange";
import ForgotPassword from "./components/login/ForgotPassword";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import * as S from "./App.styles";

const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

export const AuthContext = createContext<any>({});

const App = () => {
  const [currentUser, setCurrentUser] = useState();
  const [isUserLoggedIn, setIsLoggedIn] = useState(false);
  const [currentProfileId, setCurrentProfileId] = useState();
  const [profilePicture, setProfilePicture] = useState();
  const [currentProfile, setCurrentProfile] = useState();
  const [currentProfileFontSize, setCurrentProfileFontSize] = useState(
    FontSizeTheme.AVERAGE
  );
  const [currentProfileLanguage, setCurrentProfileLanguage] = useState();

  const getSession = async () => {
    try {
      const res = await getUserSession();
      const {
        user,
        isLoggedIn,
        currentProfileId,
        currentProfileFontSize,
        currentProfileLanguage,
      } = res;
      setCurrentUser(user);
      setIsLoggedIn(isLoggedIn);
      setCurrentProfileId(currentProfileId);
      setCurrentProfileFontSize(currentProfileFontSize);
      setCurrentProfileLanguage(currentProfileLanguage);
      const { role = "" } = user;
      localStorage.setItem("isLoggedIn", isLoggedIn);
      localStorage.setItem("isAdmin", role === "ADMIN" ? "true" : "false");
    } catch (error: any) {
      console.log("error", { error });
      localStorage.clear();
    }
  };

  const storedLoggedInInfo = localStorage.getItem("isLoggedIn");
  const storedAdminInfo = localStorage.getItem("isAdmin");

  const storedIsLoggedIn = JSON.parse(storedLoggedInInfo || "false");
  const storedIsAdminInfo = JSON.parse(storedAdminInfo || "false");

  useEffect(() => {
    getSession();
  }, []);

  const value = {
    currentUser,
    isUserLoggedIn,
    currentProfileId,
    profilePicture,
    setProfilePicture,
    currentProfile,
    setCurrentProfile,
    setCurrentProfileId,
    setIsLoggedIn,
    setCurrentProfileFontSize,
    currentProfileLanguage,
    setCurrentProfileLanguage,
  };

  const isAdmin = storedIsLoggedIn && storedIsAdminInfo;

  const protectedRouteProps = {
    navigateTo: "/" as string,
    isAdmin,
  };

  const loggedInRouteProps = {
    navigateTo: "/" as string,
    isLoggedIn: isUserLoggedIn || storedIsLoggedIn,
  };

  return (
    <AuthContext.Provider value={{ ...value }}>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={themeSettings(currentProfileFontSize)}>
          <S.Wrapper>
            <ToastContainer />
            <BrowserRouter>
              <Elements stripe={stripePromise}>
                <Routes>
                  <Route path="/" element={<WelcomePage />} />
                  <Route path="/start" element={<StartPage />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                  <Route
                    path="/email-confirmation/:verificationCode"
                    element={<Confirmation />}
                  />
                  <Route
                    path="/validate-account/:verificationCode"
                    element={<ValidateAccont />}
                  />
                  <Route
                    path="/change-password/:verificationCode"
                    element={<PasswordChange />}
                  />
                  <Route path="/reset-password" element={<ForgotPassword />} />
                  <Route element={<LoggedInRoute {...loggedInRouteProps} />}>
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/profile/new" element={<NewProfile />} />
                    <Route path="/profile/:id" element={<NewProfile />} />
                    <Route path="/settings" element={<SettingsPage />} />
                    <Route path="/payment" element={<Payment />} />
                    <Route path="/payment/:id" element={<PaymentExecute />} />
                    <Route path="/success" element={<SuccessPayement />} />
                    <Route path="/failure" element={<FailurePayment />} />
                    <Route path="/subscription" element={<Subscription />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/category" element={<PickCategory />} />
                    <Route
                      path="/category/:id"
                      element={<PickCategoryLevel />}
                    />
                    <Route
                      path="/category-level/:id"
                      element={<PickSubject />}
                    />
                    <Route path="/subject/:id" element={<PickLesson />} />
                    <Route
                      path="/lesson/:id"
                      element={<Lesson isOnlyGame={false} />}
                    />
                    <Route path="/game/:id" element={<Lesson isOnlyGame />} />
                  </Route>
                  <Route element={<ProtectedRoute {...protectedRouteProps} />}>
                    <Route path="/cms" element={<Cms />} />
                    <Route path="/cms/categories" element={<CategoryList />} />
                    <Route
                      path="/cms/categories/:id"
                      element={<CategoryForm />}
                    />
                    <Route
                      path="/cms/category-levels"
                      element={<CategoryLevelsList />}
                    />
                    <Route
                      path="/cms/category-levels/:id"
                      element={<CategoryLevelForm />}
                    />
                    <Route path="/cms/subjects" element={<SubjectList />} />
                    <Route path="/cms/subject/:id" element={<SubjectForm />} />
                    <Route
                      path="/cms/subject/:id/lesson"
                      element={<LessonForm />}
                    />
                    <Route
                      path="/cms/subject/:id/lesson/:lessonId"
                      element={<LessonForm />}
                    />
                  </Route>
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </Elements>
            </BrowserRouter>
          </S.Wrapper>
        </ThemeProvider>
      </I18nextProvider>
    </AuthContext.Provider>
  );
};

export default App;
