import positive from "../../assets/reactions/positive_match.wav";
import negative from "../../assets/reactions/wrong_match.wav";

const GameReactionSound = (isCorrect: boolean) => {
  if (isCorrect) {
    return new Audio(positive).play();
  }
  return new Audio(negative).play();
};

export default GameReactionSound;
