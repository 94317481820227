import { useState } from "react";
import { useParams } from "react-router-dom";
import { handleLessonResult } from "../../../../api/common-api";
import Check from "../../../../assets/common/tick.jpg";
import Cross from "../../../../assets/common/cross.jpg";
import ReactionSound from "../../reactionSound";
import GameSound from "../../../../assets/games/wybierz_poprawne_slowo.mp3";
import GameSoundEn from "../../../../assets/games/en/Choose_the_correct_word.mp3";
import GameSoundDe from "../../../../assets/games/de/Wahle_das_richtige_Wort-wybierz_poprawne_slowo.mp3";
import * as S from "./PickCorrectGame.styles";
import * as C from "../CommonGame.styles";
import i18n from "../../../../i18n";
import { useTranslation } from "react-i18next";

interface IPickCorrectGameProps {
  words: string[];
  handleShowResultRacoon: () => void;
  handleContinue: () => void;
  gameLectorSound: string;
  handleShowBuyModal: () => void;
}

const PickCorrectGame = (props: IPickCorrectGameProps) => {
  const {
    words = [],
    handleShowResultRacoon,
    gameLectorSound,
    handleShowBuyModal,
  } = props;

  const { t } = useTranslation("translation", {
    keyPrefix: "games.pickCorrectWord",
  });

  const { language } = i18n;

  const correctGameSound =
    language === "pl"
      ? GameSound
      : language === "en"
      ? GameSoundEn
      : GameSoundDe;

  const [isResultVisible, setResultVisible] = useState<any[]>([]);
  const [isActionDisabled, setIsActionDisabled] = useState(false);

  const [isLectorPlaying, setIsLectorPlaying] = useState(false);

  const params = useParams();
  const { id } = params;

  const handleGameResult = async (wordLessonId: string, index: number) => {
    if (isActionDisabled) {
      return null;
    }
    if (wordLessonId === id) {
      setResultVisible([index]);
      await handleLessonResult(id);
      handleShowResultRacoon();
      setIsActionDisabled(true);
      handleShowBuyModal();
      ReactionSound(true);
    } else {
      setResultVisible([index]);
      ReactionSound(false);
    }
  };

  const resultBackgroundColor = (isCorrect: boolean) =>
    isCorrect ? "rgba(226, 255, 189, 1)" : "#FFE7E7";
  const renderWords = () =>
    words.map((w: any, index: number) => {
      const isSelectedLessonCorrect = w.lessonId === id;
      const visibleResults = isResultVisible.includes(index);
      const backgroundColor = resultBackgroundColor(isSelectedLessonCorrect);
      const resultImage = isSelectedLessonCorrect ? Check : Cross;
      if (index % 2 === 1) {
        return (
          <C.WordContainer
            key={index}
            onClick={() => handleGameResult(w.lessonId, index)}
          >
            {/* <S.EmptyContainer /> */}
            <S.WordContainer
              isResult={visibleResults}
              resultBackgroundColor={backgroundColor}
              isSelected={visibleResults}
              isActionDisabled={isActionDisabled}
            >
              <S.Word>{w.word}</S.Word>
              {visibleResults && <S.Result src={resultImage} />}
            </S.WordContainer>
          </C.WordContainer>
        );
      } else {
        return (
          <C.WordContainer
            key={index}
            onClick={() => handleGameResult(w.lessonId, index)}
          >
            <S.WordContainer
              isResult={visibleResults}
              resultBackgroundColor={backgroundColor}
              isSelected={visibleResults}
              isActionDisabled={isActionDisabled}
            >
              <S.Word>{w.word}</S.Word>
              {visibleResults && <S.Result src={resultImage} />}
            </S.WordContainer>
          </C.WordContainer>
        );
      }
    });

  return (
    <C.Wrapper>
      <C.TitleContainer>
        <C.StyledTitle>{t("pickCorrect")}</C.StyledTitle>
      </C.TitleContainer>
      {!isActionDisabled && (
        <div style={{ display: "none" }}>
          <audio
            controls
            src={correctGameSound}
            autoPlay
            onEnded={() => setIsLectorPlaying(true)}
          />
        </div>
      )}
      {isLectorPlaying && (
        <div style={{ display: "none" }}>
          <audio controls src={gameLectorSound} autoPlay />
        </div>
      )}
      <C.ContentContainer>{renderWords()}</C.ContentContainer>
    </C.Wrapper>
  );
};

export default PickCorrectGame;
