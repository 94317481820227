import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  overflow: auto;
  max-height: 80vh;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  @media (min-height: 600px) {
    margin-bottom: 40px;
    gap: 16px;
  }
`;

export const StyledHeader = styled.h1`
  margin: 0;
  font-family: Nunito;
  font-size: ${(props) => props.theme.mobileBigHeaderFontSize};
  font-weight: 900;
  text-align: center;
  @media (min-width: 1200px) {
    font-size: ${(props) => props.theme.bigHeaderFontSize};
  }
`;

export const StyledSubHeader = styled.h3`
  margin: 0;
  font-family: Nunito;
  font-size: ${(props) => props.theme.headerFontSize};
  font-weight: 900;
  text-align: center;
`;

export const LevelsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  overflow-x: auto;
  width: 100%;
  padding-bottom: 12px;
  margin: 0 auto;
`;
