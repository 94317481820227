import { useNavigate } from "react-router-dom";
import GameContainer from "../layout/GameContainer";
import { useTranslation } from "react-i18next";
import * as S from "./Payment.styles";

const FailurePayment = () => {
  const navigate = useNavigate();
  const handleBackToProfile = () => {
    navigate("/profile");
  };

  const { t } = useTranslation("translation", { keyPrefix: "failureForm" });

  return (
    <GameContainer isNav={false}>
      <S.Wrapper>
        <div>{t("noWork")}</div>
        <S.StyledButton onClick={() => handleBackToProfile()}>
          {t("goBack")}
        </S.StyledButton>
      </S.Wrapper>
    </GameContainer>
  );
};

export default FailurePayment;
