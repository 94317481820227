import { useStripe } from "@stripe/react-stripe-js";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const PaymentExecute = () => {
  const stripe = useStripe();
  const { id } = useParams() as {
    id: string;
  };
  const navigate = useNavigate();

  useEffect(() => {
    stripe
      ?.redirectToCheckout({ sessionId: id })
      .then(() => {
        navigate(`/profile`); ///change to success page -> then profile
      })
      .catch(() => {
        navigate(`/profile`); ///change to fail page -> then profile
      });
  }, [id, stripe]);

  return null;
};

export default PaymentExecute;
