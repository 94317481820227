import { useNavigate } from "react-router-dom";
import LevelOne from "../../assets/categories/LevelOne.svg";
import { useTranslation } from "react-i18next";
import * as S from "./categoryContainer.styles";

export interface ICategoryLevelProps {
  id: string;
  level: number;
  imgSrc: string;
}

const CategoryLevelContainer = (props: ICategoryLevelProps) => {
  const { level = 1, id, imgSrc } = props;
  const navigate = useNavigate();

  const { t } = useTranslation("translation", { keyPrefix: "categoryLevel" });

  return (
    <S.Wrapper onClick={() => navigate(`/category-level/${id}`)}>
      <S.ImageContainer>
        <S.StyledImage src={imgSrc || LevelOne} />
      </S.ImageContainer>
      <S.NameContainer>
        <S.Name>
          {t("level")} {level}
        </S.Name>
      </S.NameContainer>
    </S.Wrapper>
  );
};

export default CategoryLevelContainer;
