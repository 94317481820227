import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GameContainer from "../../layout/GameContainer";
import useMediaQuery from "../../../hooks/useMediaQuery";
import LightBird from "../../../assets/common/LightBrid.svg";
import PinkBird from "../../../assets/common/PinkBird.svg";
import szop from "../../../assets/profile/1 szop.jpg";
import lew from "../../../assets/profile/2 lew.jpg";
import pingwin from "../../../assets/profile/3 pingwin.jpg";
import lama from "../../../assets/profile/4 lama.jpg";
import mis from "../../../assets/profile/5 mis.jpg";
import lisek from "../../../assets/profile/6 lisek.jpg";
import piesek from "../../../assets/profile/7 piesek.jpg";
import kotek from "../../../assets/profile/8 kotek.jpg";
import { useTranslation } from "react-i18next";
import { Popconfirm, Select } from "antd";
import { fontThemesOptions } from "../../../utils/fontThemes";
import {
  createNewProfile,
  deleteProfile,
  getProfileDetails,
  updateProfile,
} from "../../../api/profile";
import i18n from "../../../i18n";
import { FontSizeTheme } from "../../../utils/types";
import * as S from "./new-profile.styles";

const genderOptions: any[] = [
  {
    value: "boy",
    label: "Chłopiec",
  },
  {
    value: "girl",
    label: "Dziewczynka",
  },
];

const NewProfile = () => {
  const params = useParams();
  const { id } = params;

  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState<{ value: string; label: string }>();
  const [defaultFontSize, setDefaultFontSize] = useState<any>();
  const [activeStep, setActiveStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const { t } = useTranslation("translation", { keyPrefix: "profile" });
  const { language } = i18n;

  const handleUpdateProfile = async (picture: string) => {
    if (!id) {
      return null;
    }
    setIsLoading(true);
    await updateProfile(id, {
      name,
      age,
      gender: gender?.value || "",
      picture,
      defaultFontSize: defaultFontSize || "",
    });
    setIsLoading(false);
    navigate("/profile");
  };

  const handleAddNewProfile = async (picture: string) => {
    setIsLoading(true);
    await createNewProfile({
      name,
      age,
      gender: gender?.value || "",
      picture,
      defaultFontSize: defaultFontSize || "",
    });
    setIsLoading(false);
    navigate("/profile");
  };

  const buttonText = id ? `${t("next")}` : `${t("add")}`;
  const isSaveDisabled = !name || !age;

  const getProfileData = async () => {
    if (!id) {
      return null;
    }
    setIsLoading(true);
    const res = await getProfileDetails(id);
    const { name, gender, age, defaultFontSize } = res;
    setName(name);
    setGender(genderOptions.find((x) => x.value === gender));
    setAge(age);
    setDefaultFontSize(
      fontThemesOptions(language)?.find((x) => x.value === defaultFontSize)
        ?.value || FontSizeTheme.SMALL
    );
    setIsLoading(false);
  };
  useEffect(() => {
    getProfileData();
  }, [id]);

  const isDesktop = useMediaQuery("(min-width: 1200px)");
  const isMinimumHeight500 = useMediaQuery("(min-height: 500px)");

  const handleDeleteProfile = async () => {
    if (!id) {
      return null;
    }
    setIsLoading(true);
    await deleteProfile(id);
    setIsLoading(false);
    navigate("/profile");
  };

  const renderSteps = (activeStep: number) => {
    switch (activeStep) {
      case 2:
        return (
          <>
            {isDesktop && isMinimumHeight500 && (
              <S.SecondaryBirdContainer>
                <S.StyledImage src={PinkBird} />
              </S.SecondaryBirdContainer>
            )}
            <S.TitleContainer>
              <S.Title>
                {name}, {t("chooseFriend")}
              </S.Title>
            </S.TitleContainer>
            <S.FriendsContainer>
              <S.NewProfileButton
                onClick={() =>
                  id ? handleUpdateProfile(szop) : handleAddNewProfile(szop)
                }
              >
                <S.StyledFriendImage src={szop} />
              </S.NewProfileButton>
              <S.NewProfileButton
                onClick={() =>
                  id ? handleUpdateProfile(lew) : handleAddNewProfile(lew)
                }
              >
                <S.StyledFriendImage src={lew} />
              </S.NewProfileButton>
              <S.NewProfileButton
                onClick={() =>
                  id
                    ? handleUpdateProfile(pingwin)
                    : handleAddNewProfile(pingwin)
                }
              >
                <S.StyledFriendImage src={pingwin} />
              </S.NewProfileButton>
              <S.NewProfileButton
                onClick={() =>
                  id ? handleUpdateProfile(lama) : handleAddNewProfile(lama)
                }
              >
                <S.StyledFriendImage src={lama} />
              </S.NewProfileButton>
              <S.NewProfileButton
                onClick={() =>
                  id ? handleUpdateProfile(mis) : handleAddNewProfile(mis)
                }
              >
                <S.StyledFriendImage src={mis} />
              </S.NewProfileButton>
              <S.NewProfileButton
                onClick={() =>
                  id ? handleUpdateProfile(lisek) : handleAddNewProfile(lisek)
                }
              >
                <S.StyledFriendImage src={lisek} />
              </S.NewProfileButton>
              <S.NewProfileButton
                onClick={() =>
                  id ? handleUpdateProfile(piesek) : handleAddNewProfile(piesek)
                }
              >
                <S.StyledFriendImage src={piesek} />
              </S.NewProfileButton>
              <S.NewProfileButton
                onClick={() =>
                  id ? handleUpdateProfile(kotek) : handleAddNewProfile(kotek)
                }
              >
                <S.StyledFriendImage src={kotek} />
              </S.NewProfileButton>
            </S.FriendsContainer>
          </>
        );
      default:
        return (
          <>
            {isDesktop && (
              <S.BirdContainer>
                <S.StyledImage src={LightBird} />
              </S.BirdContainer>
            )}
            <S.TitleContainer>
              <S.Title>{t("add")}</S.Title>
            </S.TitleContainer>
            <S.InputsContainer>
              <S.Row>
                <S.Label>{t("childName")}</S.Label>
                <S.Input
                  value={name}
                  onChange={(e: any) => setName(e.target.value)}
                />
              </S.Row>
              <S.Row>
                <S.Label>{t("age")}</S.Label>
                <S.Input
                  value={age}
                  onChange={(e: any) => setAge(e.target.value)}
                />
              </S.Row>
              <S.Row>
                <S.Label>{t("fontSize")}</S.Label>
                <Select
                  options={fontThemesOptions(language)}
                  placeholder={"Wybierz"}
                  className="customSelect"
                  onChange={(e: any) => setDefaultFontSize(e)}
                  defaultValue={defaultFontSize}
                  dropdownStyle={{
                    right: "20px",
                  }}
                  style={{
                    maxWidth: "372px",
                    width: "100%",
                    padding: isMinimumHeight500 ? "20px 16px" : "10px 8px",
                    borderRadius: "8px",
                    border: "3px solid rgba(224, 224, 224, 1)",
                  }}
                />
              </S.Row>
            </S.InputsContainer>
            <S.Actions>
              <S.SaveButton
                disabled={isSaveDisabled}
                onClick={() => setActiveStep(2)}
                isDisabled={isSaveDisabled}
              >
                {buttonText}
              </S.SaveButton>
              {id && (
                <Popconfirm
                  title="Usunąć profil?"
                  onConfirm={() => handleDeleteProfile()}
                >
                  <S.DeleteButton>{t("deleteProfile")}</S.DeleteButton>
                </Popconfirm>
              )}
            </S.Actions>
          </>
        );
    }
  };

  return (
    <GameContainer showLogo isLoading={isLoading}>
      <S.Wrapper className="color-scroll">{renderSteps(activeStep)}</S.Wrapper>
    </GameContainer>
  );
};

export default NewProfile;
