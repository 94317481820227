import { useEffect, useState } from "react";
import Loader from "../common/loader/loader";
import { useNavigate, useParams } from "react-router-dom";
import { getCategoryDetails, updateCategoryById } from "../../api/cms";
import GameContainer from "../layout/GameContainer";
import * as S from "./cms.styles";

const CategoryForm = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { id: categoryId } = params;

  const [name, setName] = useState("");
  const [file, setFile] = useState<File>();
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdateCategory = async () => {
    if (!name || !categoryId) {
      return;
    }
    setIsLoading(true);
    await updateCategoryById({ id: categoryId, name }, file);
    setIsLoading(false);
    navigate("/cms/categories");
  };

  const fetchCategoryDetails = async (id: string) => {
    const res = await getCategoryDetails(id);
    const { name } = res;
    setName(name);
  };

  useEffect(() => {
    if (categoryId) {
      fetchCategoryDetails(categoryId);
    }
  }, [categoryId]);

  const isAddNewCategoryButtonDisabled = !name;
  const addNewButtonText = isLoading ? <Loader /> : "Aktualizuj";

  return (
    <GameContainer backTo="/cms/categories">
      <S.Wrapper>
        <S.Title>Edytuj {name}</S.Title>
        <S.Group>
          <S.Row>
            <S.Label>Nazwa:</S.Label>
            <S.Input
              value={name}
              onChange={(e: any) => setName(e.target.value)}
            />
          </S.Row>
          <S.Row>
            <S.Label>Nowy obrazek</S.Label>
            <S.Input
              onChange={(e: any) => setFile(e.target.files[0])}
              type="file"
            />
          </S.Row>
        </S.Group>
        <S.Button
          onClick={() => handleUpdateCategory()}
          disabled={isAddNewCategoryButtonDisabled}
        >
          {addNewButtonText}
        </S.Button>
      </S.Wrapper>
    </GameContainer>
  );
};

export default CategoryForm;
