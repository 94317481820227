import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 32px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: 300ms;
  background-color: white;
  padding: 6px;
  width: 100%;
  max-height: 150px;
  max-width: 112px;
  @media (min-height: 700px) {
    max-width: 234px;
    max-height: none;
  }
  &:hover {
    box-shadow: 0 12px 12px 0 rgba(85, 122, 31, 1);
  }
  box-shadow: 0px 4px 4px 0px rgba(85, 122, 31, 1);
  margin: 0 auto;
  @media (min-width: 1024px) {
    margin: 0;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledImage = styled.img`
  border-radius: 32px 32px 0 0;
  height: 100px;
  min-width: 100px;
  width: 100%;
  object-fit: cover;
  @media (min-height: 700px) {
    height: 212px;
  }
`;

export const NameContainer = styled.div`
  display: flex;
  height: 94px;
  align-items: center;
`;

export const Name = styled.h3`
  margin: 0;
  font-family: Nunito;
  font-size: ${(props) => props.theme.mobileTileFontSize};
  font-weight: 900;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: center;
  @media (min-width: 768px) and (min-height: 600px) {
    font-size: ${(props) => props.theme.tileFontSize};
  }
`;
