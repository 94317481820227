import styled from "styled-components";

export const BottomContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 32px;
  width: 100%;
  margin-bottom: 16px;
`;

export const StyledImg = styled.img`
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 32px;
  border: 6px solid transparent;
`;
export const ImgContainer = styled.div<{
  isCorrect?: boolean;
  isActionDisabled: boolean;
}>`
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 186px;
  max-height: 186px;
  cursor: ${({ isActionDisabled }) => (isActionDisabled ? "auto" : "pointer")};
  border: ${({ isCorrect }) =>
    isCorrect ? "6px solid #ffb800;" : "6px solid white;"};
  box-shadow: 0px 4px 4px 0px rgba(85, 122, 31, 1);
  border-radius: 32px;
  position: relative;
  background-color: white;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
  @media (min-width: 1024px) {
    max-width: 200px;
    max-height: 200px;
  }
  @media (min-width: 1300px) {
    max-width: 400px;
    max-height: 400px;
  }
`;

export const EmptyContainer = styled.div`
  padding-top: 60px;
  width: 70px;
`;

export const ResultsContainer = styled.div``;

export const Result = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 32px;
  opacity: 0.9;
`;

export const SoundContainer = styled.div`
  display: flex;
  justify-content: center;
`;
