import { useNavigate } from "react-router-dom";
import * as S from "./subjectContainer.styles";

export interface ISubjectProps {
  id: string;
  name: string;
  imgSrc: string;
  index: number;
  progress: number;
}

const SubjectContainer = (props: ISubjectProps) => {
  const { name, id, index, progress = 0, imgSrc } = props;
  const navigate = useNavigate();

  const showProgress = progress >= 20;
  const backgroundColor =
    progress < 100 ? "rgba(155, 81, 224, 1)" : "rgba(255, 184, 0, 1)";
  const color = progress < 90 ? "white" : "rgba(21, 58, 92, 1)";
  const marginRight = progress < 90 ? "0" : "18px";

  return (
    <S.Wrapper onClick={() => navigate(`/subject/${id}`)}>
      <S.InnerWrapper>
        <S.ImageBackground src={imgSrc} alt="" />
        <S.NameContainer>
          <S.Name>
            {index + 1}. {name}
          </S.Name>
        </S.NameContainer>
        <S.ProgressContainer
          background={backgroundColor}
          progress={progress}
          color={color}
        >
          {showProgress && (
            <S.Progress $marginRight={marginRight}>{progress}%</S.Progress>
          )}
        </S.ProgressContainer>
      </S.InnerWrapper>
    </S.Wrapper>
  );
};

export default SubjectContainer;
