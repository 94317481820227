import { useState } from "react";
import { useParams } from "react-router-dom";
import { handleLessonResult } from "../../../../api/common-api";
import Check from "../../../../assets/common/tick.jpg";
import Cross from "../../../../assets/common/cross.jpg";
import ReactionSound from "../../reactionSound";
import i18n from "../../../../i18n";
import GameSound from "../../../../assets/games/Dopasuj_dzwiek_do_obrazka.mp3";
import GameSoundEn from "../../../../assets/games/en/match_the_sound_to_the_picture.mp3";
import GameSoundDe from "../../../../assets/games/de/Ordne_den_Gerauschen_das_passende_Bild zu.-dopasuj_dzwiek_do_obrazka.mp3";
import * as S from "./SoundsGame.styles";
import * as C from "../CommonGame.styles";
import { useTranslation } from "react-i18next";
interface ISoundsGameProps {
  data: any;
  handleShowResultRacoon: () => void;
  handleContinue: () => void;
  gameLectorSound: string;
  handleShowBuyModal: () => void;
}

const SoundsGame = (props: ISoundsGameProps) => {
  const {
    data: { images = [], movie = "" },
    handleShowResultRacoon,
    handleShowBuyModal,
  } = props;
  const [resultContainer, setResultContainer] = useState<any[]>([]);
  const [isActionDisabled, setIsActionDisabled] = useState(false);

  const { t } = useTranslation("translation", { keyPrefix: "games.sounds" });

  const params = useParams();
  const { id } = params;

  const { language } = i18n;

  const correctGameSound =
    language === "pl"
      ? GameSound
      : language === "en"
      ? GameSoundEn
      : GameSoundDe;

  const handleGameResults = async (isCorrect: boolean, index: number) => {
    if (isActionDisabled) {
      return null;
    }
    if (isCorrect) {
      setResultContainer([index]);
      id && (await handleLessonResult(id));
      handleShowResultRacoon();
      setIsActionDisabled(true);
      handleShowBuyModal();
      ReactionSound(true);
    } else {
      setResultContainer([index]);
      ReactionSound(false);
    }
  };

  const renderImages = () =>
    images.map((w: any, index: number) => {
      const visibleResults = resultContainer.includes(index);
      const resultImage = w.isCorrect ? Check : Cross;
      if (index % 2 === 1) {
        return (
          <S.ImgContainer
            key={index}
            isActionDisabled={isActionDisabled}
            isCorrect={resultContainer.includes(index)}
            onClick={() => handleGameResults(w.isCorrect, index)}
          >
            <S.EmptyContainer />
            <S.StyledImg src={w.imgSrc} />
            {visibleResults && <S.Result src={resultImage} />}
          </S.ImgContainer>
        );
      } else {
        return (
          <S.ImgContainer
            key={index}
            isActionDisabled={isActionDisabled}
            isCorrect={resultContainer.includes(index)}
            onClick={() => handleGameResults(w.isCorrect, index)}
          >
            <S.StyledImg src={w.imgSrc} />
            {visibleResults && <S.Result src={resultImage} />}
          </S.ImgContainer>
        );
      }
    });
  return (
    <C.Wrapper>
      <C.TitleContainer>
        <C.StyledTitle>{t("match")}</C.StyledTitle>
      </C.TitleContainer>
      <C.ColumnContentContainer>
        {!isActionDisabled && (
          <div style={{ display: "none" }}>
            <audio controls src={correctGameSound} autoPlay />
          </div>
        )}
        <S.BottomContainer>{renderImages()}</S.BottomContainer>
        <S.SoundContainer>
          <audio controls src={movie?.url} />
        </S.SoundContainer>
      </C.ColumnContentContainer>
    </C.Wrapper>
  );
};

export default SoundsGame;
