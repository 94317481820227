import styled from "styled-components";
import { TiArrowRightThick } from "react-icons/ti";

export const Wrapper = styled.div`
  padding: 8px;
  position: relative;
`;

export const GameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  margin: 0 auto;
  gap: 50px;
`;

export const UpperContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

export const EmptyImgContainer = styled.div<{ isSelected?: boolean }>`
  display: flex;
  max-width: 100px;
  max-height: 100px;
  width: 100%;
  height: 100%;
  border: ${({ isSelected }) =>
    isSelected ? "6px solid #ffb800;" : "6px solid white;"};
  background-color: white;
  cursor: pointer;
  border-radius: 32px;
  position: relative;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
  @media (min-width: 768px) and (min-height: 600px) {
    max-width: 160px;
    max-height: 160px;
  }
  @media (min-width: 1024px) {
    max-width: 180px;
    max-height: 180px;
  }
  @media (min-width: 1200px) {
    max-width: 248px;
    max-height: 248px;
    min-width: 180px;
  }
`;

export const StyledImg = styled.img`
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 32px;
  border: 6px solid transparent;
`;

export const ComparisonContainer = styled.div``;

export const BottomCotainer = styled.div`
  display: flex;
  gap: 34px;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const ImgContainer = styled.div<{ isSelected?: boolean }>`
  display: flex;
  max-width: 100px;
  max-height: 100px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: ${({ isSelected }) =>
    isSelected ? "6px solid #ffb800;" : "6px solid white;"};
  box-shadow: 0px 4px 4px 0px rgba(85, 122, 31, 1);
  border-radius: 32px;
  position: relative;
  background-color: white;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
  @media (min-width: 768px) and (min-height: 600px) {
    max-width: 160px;
    max-height: 160px;
  }
  @media (min-width: 1024px) {
    max-width: 180px;
    max-height: 180px;
  }
  @media (min-width: 1200px) {
    max-width: 248px;
    max-height: 248px;
    min-width: 200px;
  }
`;
export const SignContainer = styled.div``;

export const StyledSign = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  @media (min-width: 1024px) {
    min-width: 130px;
  }
`;

export const StyledArrow = styled(TiArrowRightThick)`
  color: rgb(117 93 243);
  width: 80px;
  height: 80px;
`;
