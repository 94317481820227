import { useEffect, useState } from "react";
import { handleLessonResult } from "../../../../api/common-api";
import { useParams } from "react-router-dom";
import ReactionSound from "../../reactionSound";
import majoritySign from "../../../../assets/games/majority_sign.png";
import minoritySign from "../../../../assets/games/minority_sign.png";
import GameReactionSound from "../../gameReactionSound";
import * as S from "./CorrectOrderGame.styles";
import * as C from "../CommonGame.styles";
interface ICorrectOrderGameProps {
  data: any[];
  handleShowResultRacoon: () => void;
  handleContinue: () => void;
  gameLectorSound: string;
  handleShowBuyModal: () => void;
}

interface ISelectedData {
  order: number;
  imgSrc: string;
}

const seletedDataInitialState = {
  order: 0,
  imgSrc: "",
};
const CorrectOrderGame = (props: ICorrectOrderGameProps) => {
  const {
    data = [],
    handleShowResultRacoon,
    gameLectorSound,
    handleShowBuyModal,
  } = props;
  const [selectedData, setSelectedData] = useState<ISelectedData>(
    seletedDataInitialState
  );
  const [src1, setSrc1] = useState("");
  const [src2, setSrc2] = useState("");
  const [src3, setSrc3] = useState("");
  const [src4, setSrc4] = useState("");

  const [gameData, setGameData] = useState<any[]>(data);

  const [isActionDisabled, setIsActionDisabled] = useState(false);

  const params = useParams();
  const { id } = params;

  const isGreaterThan = (data[0] && data[0].isGreaterThan) || false;
  const isArrow = (data[0] && data[0].isArrow) || false;

  const showThirdValue = data && data.length === 3;
  const showFourthValue = data && data.length > 3;

  const properSign = isArrow ? (
    <S.SignContainer>
      <S.StyledArrow />
    </S.SignContainer>
  ) : isGreaterThan ? (
    <S.SignContainer>
      <S.StyledSign src={majoritySign} />
    </S.SignContainer>
  ) : (
    <S.SignContainer>
      <S.StyledSign src={minoritySign} />
    </S.SignContainer>
  );

  const handleSelectImage = (imgSrc: string, order: number) => {
    if (selectedData.order === order) {
      setSelectedData(seletedDataInitialState);
    } else {
      setSelectedData({ order, imgSrc });
    }
  };

  const handleOrder = (properOrder: number) => {
    if (isActionDisabled) {
      return null;
    }
    if (selectedData.order === 0) {
      return;
    }
    if (selectedData.order === 1 && properOrder === 1) {
      setSrc1(selectedData.imgSrc);
      setGameData(gameData.filter((x) => x.order !== 1));
      GameReactionSound(true);
      setSelectedData(seletedDataInitialState);
    } else if (selectedData.order === 2 && properOrder === 2) {
      setSrc2(selectedData.imgSrc);
      setGameData(gameData.filter((x) => x.order !== 2));
      GameReactionSound(true);
      setSelectedData(seletedDataInitialState);
    } else if (selectedData.order === 3 && properOrder === 3) {
      setSrc3(selectedData.imgSrc);
      setGameData(gameData.filter((x) => x.order !== 3));
      GameReactionSound(true);
      setSelectedData(seletedDataInitialState);
    } else if (selectedData.order === 4 && properOrder === 4) {
      setSrc4(selectedData.imgSrc);
      setGameData(gameData.filter((x) => x.order !== 4));
      GameReactionSound(true);
      setSelectedData(seletedDataInitialState);
    } else if (selectedData.order !== properOrder) {
      setSelectedData(seletedDataInitialState);
      GameReactionSound(false);
    }
  };

  const handleGameResult = async () => {
    id && (await handleLessonResult(id));
    handleShowResultRacoon();
    setIsActionDisabled(true);
    handleShowBuyModal();
    ReactionSound(true);
  };

  useEffect(() => {
    const max2 = data && data.length === 2;
    const max3 = data && data.length === 3;
    const max4 = data && data.length === 4;
    if (max4 && src1 && src2 && src3 && src4) {
      handleGameResult();
    }
    if (max3 && src1 && src2 && src3) {
      handleGameResult();
    }
    if (max2 && src1 && src2) {
      handleGameResult();
    }
  }, [src1, src2, src3, src4]);

  return (
    <S.Wrapper>
      <C.TitleContainer>
        {/* <C.StyledTitle>Ustaw w odpowiedniej kolejności</C.StyledTitle> */}
      </C.TitleContainer>
      {!isActionDisabled && (
        <div style={{ display: "none" }}>
          <audio controls src={gameLectorSound} autoPlay />
        </div>
      )}
      <C.ColumnContentContainer>
        <S.UpperContainer>
          <S.EmptyImgContainer
            onClick={() => handleOrder(1)}
            isSelected={src1 !== ""}
          >
            {src1 && <S.StyledImg src={src1} />}
          </S.EmptyImgContainer>
          <S.ComparisonContainer>{properSign}</S.ComparisonContainer>
          <S.EmptyImgContainer
            onClick={() => handleOrder(2)}
            isSelected={src2 !== ""}
          >
            {src2 && <S.StyledImg src={src2} />}
          </S.EmptyImgContainer>
          {showThirdValue && (
            <>
              <S.ComparisonContainer>{properSign}</S.ComparisonContainer>
              <S.EmptyImgContainer
                onClick={() => handleOrder(3)}
                isSelected={src3 !== ""}
              >
                {src3 && <S.StyledImg src={src3} />}
              </S.EmptyImgContainer>
            </>
          )}
          {showFourthValue && (
            <>
              <S.ComparisonContainer>{properSign}</S.ComparisonContainer>
              <S.EmptyImgContainer
                onClick={() => handleOrder(3)}
                isSelected={src3 !== ""}
              >
                {src3 && <S.StyledImg src={src3} />}
              </S.EmptyImgContainer>
              <S.ComparisonContainer>{properSign}</S.ComparisonContainer>
              <S.EmptyImgContainer
                onClick={() => handleOrder(4)}
                isSelected={src4 !== ""}
              >
                {src4 && <S.StyledImg src={src4} />}
              </S.EmptyImgContainer>
            </>
          )}
        </S.UpperContainer>
        <S.BottomCotainer>
          {gameData &&
            gameData.length > 0 &&
            gameData.map((a: any, index: number) => (
              <S.ImgContainer
                key={index}
                onClick={() => handleSelectImage(a.imgSrc, a.order)}
                isSelected={selectedData.order === a.order}
              >
                <S.StyledImg src={a.imgSrc} />
              </S.ImgContainer>
            ))}
        </S.BottomCotainer>
      </C.ColumnContentContainer>
    </S.Wrapper>
  );
};

export default CorrectOrderGame;
