import { ProgressBar } from "react-loader-spinner";
import Logo from "../../../assets/common/Logo2.png";
import * as S from "./logoLoader.styles";

interface ILogoLoader {
  isBig?: boolean;
}

const LogoLoader = (props: ILogoLoader) => {
  const { isBig = false } = props;

  return (
    <S.Wrapper>
      <S.ImageWrapper>
        <S.StyledLogo src={Logo} $isBig={isBig} />
      </S.ImageWrapper>
      <ProgressBar
        height="80"
        width="80"
        ariaLabel="progress-bar-loading"
        wrapperStyle={{}}
        wrapperClass="progress-bar-wrapper"
        borderColor="rgb(117 93 243)"
        barColor="rgb(117 93 243)"
      />
    </S.Wrapper>
  );
};

export default LogoLoader;
