import styled from "styled-components";
import {
  BsPlayFill,
  BsPauseFill,
  BsVolumeMuteFill,
  BsFillVolumeUpFill,
} from "react-icons/bs";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  left: -72px;
  top: 50%;
  @media (min-height: 600px) {
    position: static;
    flex-direction: row;
  }
`;

export const PlayerWrapper = styled.div`
  /* @media (min-width: 768px) and (min-height: 600px) {
    margin-bottom: 20px;
  } */
`;

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 3px solid rgb(117 93 243);
  background-color: white;
  width: 60px;
  height: 60px;
  cursor: pointer;
`;

export const StyledPlayIcon = styled(BsPlayFill)`
  color: rgb(117 93 243);
  width: 30px;
  height: 30px;
`;

export const StyledPauseIcon = styled(BsPauseFill)`
  color: rgb(117 93 243);
  width: 30px;
  height: 30px;
`;

export const StyledMuteIcon = styled(BsVolumeMuteFill)`
  color: rgb(117 93 243);
  width: 30px;
  height: 30px;
`;

export const StyledVolumeUpIcon = styled(BsFillVolumeUpFill)`
  color: rgb(117 93 243);
  width: 30px;
  height: 30px;
`;
