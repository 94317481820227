import { useEffect, useState } from "react";
import { getLessonsBySubjectId } from "../../api/common-api";
import { useNavigate, useParams } from "react-router-dom";
import LessonContainer, { ILessonProps } from "./lessonContainer";
import GameContainer from "../layout/GameContainer";
import OnlyGameContainer, { IGameProps } from "./gameContainer";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import * as S from "./pickLessonForm.styles";

const PickCategoryForm = () => {
  const [lessons, setAllLessons] = useState<any[]>([]);
  const [categoryLevel, setCategoryLevel] = useState("");
  const [subjectName, setSubjectName] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [categoryLevelId, setCategoryLevelId] = useState("");
  const [games, setGames] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const lessonsAndGames = [...lessons, ...games];

  const isEmptySubcject = lessonsAndGames.length === 0;

  useEffect(() => {
    if (!isLoading && isEmptySubcject) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [lessons, games, isLoading]);

  const { t } = useTranslation("translation", { keyPrefix: "lesson" });
  const { t: profileT } = useTranslation("translation", {
    keyPrefix: "profile",
  });

  const getLessons = async () => {
    if (!id) {
      return;
    }
    setIsLoading(true);
    try {
      const res = await getLessonsBySubjectId(id);
      const {
        lessons,
        categoryLevel,
        name,
        categoryName,
        categoryLevelId,
        games,
      } = res;
      setAllLessons(lessons);
      setCategoryLevel(categoryLevel);
      setSubjectName(name);
      setCategoryName(categoryName);
      setCategoryLevelId(categoryLevelId);
      setGames(games);
    } catch (e: any) {
      console.log({ e });
    }
    setIsLoading(false);
  };

  const { id } = params;

  useEffect(() => {
    getLessons();
  }, [id]);

  const renderLessons = () =>
    lessons.map((c: ILessonProps, index: number) => (
      <LessonContainer {...c} key={index} index={index} />
    ));

  const renderGames = () =>
    games.map((c: IGameProps, index: number) => (
      <OnlyGameContainer {...c} key={index} index={index} />
    ));

  const handleGoToPayment = () => {
    navigate(`/payment`);
  };

  return (
    <GameContainer
      isLoading={isLoading}
      backTo={`/category-level/${categoryLevelId}`}
    >
      <S.Wrapper>
        <S.HeaderContainer>
          <S.StyledHeader>
            {categoryName}
            <S.SubHeadersContainer>
              <S.StyledSecondarySubHeader>
                {subjectName}
              </S.StyledSecondarySubHeader>
              <S.StyledSubHeader>
                {t("level")} {categoryLevel}
              </S.StyledSubHeader>
            </S.SubHeadersContainer>
          </S.StyledHeader>
        </S.HeaderContainer>
        <S.ContentContainer>
          {lessons.length > 0 && (
            <S.LessonsContainer className="color-scroll">
              <S.PlayWrapper>
                <S.NameContainer>
                  <S.Name>{t("lessons")}</S.Name>
                </S.NameContainer>
              </S.PlayWrapper>
              {renderLessons()}
            </S.LessonsContainer>
          )}
          {games.length > 0 && (
            <S.LessonsContainer className="color-scroll">
              <S.GameWrapper>
                <S.NameContainer>
                  <S.Name>{t("games")}</S.Name>
                </S.NameContainer>
              </S.GameWrapper>
              {renderGames()}
            </S.LessonsContainer>
          )}
        </S.ContentContainer>
      </S.Wrapper>
      <Modal
        open={isModalOpen}
        centered
        footer={false}
        onCancel={() => setIsModalOpen(false)}
      >
        <S.CmsButton
          onClick={() => handleGoToPayment()}
          style={{
            padding: "12px 20px",
            fontWeight: 700,
            fontSize: 32,
            borderRadius: 20,
          }}
        >
          {profileT("buy")}
        </S.CmsButton>
      </Modal>
    </GameContainer>
  );
};

export default PickCategoryForm;
