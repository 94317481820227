import { useEffect, useState } from "react";
import { getCategoryLevelsById } from "../../api/common-api";
import { useParams } from "react-router-dom";
import CategoryLevelContainer, {
  ICategoryLevelProps,
} from "./categoryLevelContainer";
import GameContainer from "../layout/GameContainer";
import { useTranslation } from "react-i18next";
import * as S from "./pickCategoryLevelForm.styles";

const PickCategoryLevelForm = () => {
  const [levels, setAllLevels] = useState<any[]>([]);
  const [categoryName, setCategoryName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation("translation", { keyPrefix: "categoryLevel" });

  const params = useParams();
  const { id } = params;

  const getCategoryLevels = async () => {
    if (id) {
      setIsLoading(true);
      const res = await getCategoryLevelsById(id);
      const { name, categoryLevels } = res;
      setAllLevels(categoryLevels);
      setCategoryName(name);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCategoryLevels();
  }, [id]);

  const renderCategoryLevels = () =>
    levels.map((c: ICategoryLevelProps, index: number) => (
      <CategoryLevelContainer {...c} key={index} />
    ));

  return (
    <GameContainer showFox isLoading={isLoading} backTo="/category">
      <S.Wrapper>
        <S.HeaderContainer>
          <S.StyledHeader>{categoryName}</S.StyledHeader>
          <S.StyledSubHeader>{t("chooseLevel")}</S.StyledSubHeader>
        </S.HeaderContainer>
        <S.LevelsContainer className="color-scroll">
          {renderCategoryLevels()}
        </S.LevelsContainer>
      </S.Wrapper>
    </GameContainer>
  );
};

export default PickCategoryLevelForm;
