import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 42px;
  width: 100%;
`;

interface IStyledWordProps {
  isResult: boolean;
  isSelected: boolean;
  isActionDisabled: boolean;
}

export const WordContainer = styled.div<IStyledWordProps>`
  display: flex;
  background-color: #dffcff;
  max-width: 80px;
  max-height: 80px;
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  cursor: ${({ isActionDisabled }) => (isActionDisabled ? "auto" : "pointer")};
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  border: ${({ isSelected }) =>
    isSelected ? "6px solid #ffb800" : "6px solid white"};
  box-shadow: 0px 4px 4px 0px #557a1f;
  transition: 400ms;
  position: relative;
  opacity: ${({ isResult }) => (isResult ? 0.5 : 1)};
  &:hover {
    border: ${({ isActionDisabled }) =>
      isActionDisabled ? "6px solid white" : "6px solid #ffb800"};
  }
  z-index: 1;
  @media (min-width: 600px) and (min-height: 600px) {
    max-width: 186px;
    max-height: 186px;
    min-width: 150px;
  }
  @media (min-width: 1200px) {
    max-width: 246px;
    max-height: 246px;
    min-width: 200px;
  }
  @media (min-width: 1300px) {
    max-width: 300px;
    max-height: 300px;
    min-width: 246px;
  }
`;

export const Word = styled.span`
  word-break: break-word;
  color: #153a5c;
  font-weight: 900;
  z-index: 2;
  line-height: 120%;
  text-align: center;
  font-size: ${(props) => props.theme.mobileWordFontSize};
  @media (min-width: 1024px) {
    font-size: ${(props) => props.theme.wordFontSize};
  }
`;

export const ImgContainer = styled.div<{
  isSelected: boolean;
  isMatched: boolean;
  isActionDisabled: boolean;
}>`
  cursor: ${({ isActionDisabled }) => (isActionDisabled ? "auto" : "pointer")};
  position: relative;
  border: ${({ isSelected }) =>
    isSelected ? "6px solid #ffb800" : "6px solid white"};
  box-shadow: 0px 4px 4px 0px rgba(85, 122, 31, 1);
  opacity: ${({ isMatched }) => (isMatched ? 0.5 : 1)};
  display: flex;
  flex-direction: column;
  border-radius: 32px;
  background-color: white;
  max-width: 80px;
  max-height: 80px;
  width: 100%;
  height: 100%;
  transition: 400ms;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
  &:hover {
    border: ${({ isActionDisabled }) =>
      isActionDisabled ? "6px solid white" : "6px solid #ffb800"};
  }
  @media (min-width: 600px) and (min-height: 600px) {
    max-width: 186px;
    max-height: 186px;
    min-width: 150px;
  }
  @media (min-width: 1200px) {
    max-width: 246px;
    max-height: 246px;
    min-width: 200px;
  }
  @media (min-width: 1300px) {
    max-width: 300px;
    max-height: 300px;
    min-width: 246px;
  }
`;

export const StyledImg = styled.img`
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 32px;
  border: 6px solid transparent;
`;

export const PairsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 16px;
  @media (min-width: 600px) and (max-height: 400px) {
    min-width: 340px;
  }
  @media (min-width: 768px) and (min-height: 700px) {
    gap: 48px;
  }
`;
