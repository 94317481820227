import ReactPlayer from "react-player";
import { useEffect, useRef } from "react";
import { useContainerDimensions } from "../../utils/getDimensions";
import * as S from "./videoPlayer.styles";

interface IVideoPlayerProps {
  activeStep: number;
  urls: string[];
  onVideoEnd: () => void;
  setVideoTopHeight?: (height: number) => void;
}

const VideoPlayer = (props: IVideoPlayerProps) => {
  const { activeStep, urls, onVideoEnd, setVideoTopHeight } = props;

  const elementRef = useRef(null);

  const { height } = useContainerDimensions(elementRef);

  useEffect(() => {
    if (setVideoTopHeight) {
      const dividedHeight = Math.floor(height / 2 - 30); ///video height divided by 2 - half of button height
      setVideoTopHeight(dividedHeight);
    }
  }, [setVideoTopHeight, height]);

  const urlsWithType = urls.map(() => ({
    src: urls[activeStep - 1],
    type: "video/mp4",
  }));

  return (
    <S.Wrapper>
      <S.PlayerWrapper className="player-wrapper" ref={elementRef}>
        <ReactPlayer
          className="react-player"
          // url={urls[activeStep - 1]}
          url={urlsWithType}
          type="video/mp4"
          width="100%"
          height="100%"
          onEnded={() => onVideoEnd()}
          playsinline
          // playing={isAnimationPlaying}
          // onEnded={() => setIsPlayingAnimation(false)}
          // muted={isAnimationMuted}
          controls
          config={{
            file: {
              attributes: {
                disablePictureInPicture: 1,
                autoPlay: 1,
                controlsList: ["nodownload noplaybackrate noremoteplayback"],
              },
            },
            youtube: {
              playerVars: {
                rel: 0,
                showinfo: 0,
                autoPlay: 1,
                controls: 0,
                modestbranding: 1,
              },
              embedOptions: {
                playerVars: {
                  rel: 0,
                  showinfo: 0,
                  controls: 0,
                  modestbranding: 1,
                  autoPlay: 1,
                },
              },
            },
          }}
        />
      </S.PlayerWrapper>
      {/* <S.ButtonsContainer>
        <S.ActionButton
          onClick={() => setIsAnimationPlaying(!isAnimationPlaying)}
        >
          {isAnimationPlaying ? <S.StyledPauseIcon /> : <S.StyledPlayIcon />}
        </S.ActionButton>
        <S.ActionButton onClick={() => setIsAnimationMuted(!isAnimationMuted)}>
          {isAnimationMuted ? <S.StyledMuteIcon /> : <S.StyledVolumeUpIcon />}
        </S.ActionButton>
      </S.ButtonsContainer> */}
    </S.Wrapper>
  );
};

export default VideoPlayer;
