import { useEffect, useState } from "react";
import { changePassword } from "../../api/common-api";
import { toast } from "react-toastify";
import Eye from "../../assets/common/Eye.svg";
import BrownBird from "../../assets/common/BrownBird.svg";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useTranslation } from "react-i18next";
import * as S from "./login.styles";
import { useNavigate, useParams } from "react-router-dom";
import GameContainer from "../layout/GameContainer";

const PasswordChange = () => {
  const navigate = useNavigate();
  const params: any = useParams();

  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [inputType, setInputType] = useState("password");
  const [newInputType, setNewInputType] = useState("password");
  const [isPasswordInputFocused, setIsPasswordInputFocused] = useState(false);
  const [isNewPasswordInputFocused, setIsNewPasswordInputFocused] =
    useState(false);

  const { verificationCode } = params;

  // useEffect(() => {
  //   if (!verificationCode) {
  //     return navigate("/");
  //   }
  // }, []);

  const clearInputs = () => {
    setPassword("");
    setNewPassword("");
  };

  const handleChangePassword = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    const res = await changePassword({
      password,
      newPassword,
      verificationCode,
    });
    if (res.isError) {
      clearInputs();
      setIsLoading(false);
      return toast(res.message);
    } else {
      clearInputs();
      setIsLoading(false);
      toast("Password changed");
      return navigate("/login");
    }
  };

  const handleChangeInputType = () =>
    inputType === "password" ? setInputType("text") : setInputType("password");

  const handleChangeNewInputType = () =>
    newInputType === "password"
      ? setNewInputType("text")
      : setNewInputType("password");

  const isButtonDisabled = !password || !newPassword || isLoading;

  const isTablet = useMediaQuery("(min-width: 768px)");
  const isHigherThanHorizontalPhone = useMediaQuery("(min-height: 700px)");

  const { t } = useTranslation("translation", {
    keyPrefix: "changePasswordForm",
  });

  return (
    <GameContainer backTo="/">
      <S.Wrapper onSubmit={(e) => handleChangePassword(e)}>
        {isTablet && isHigherThanHorizontalPhone && (
          <S.BirdContainer>
            <S.StyledImage src={BrownBird} />
          </S.BirdContainer>
        )}
        <S.TitleContainer>
          <S.Title>{t("changePassword")}</S.Title>
        </S.TitleContainer>
        <S.InputsContainer>
          <S.Row>
            <S.Label>{t("password")}</S.Label>
            <S.PassowrdInputContainer isFocused={isPasswordInputFocused}>
              <S.PasswordInput
                onFocus={() => setIsPasswordInputFocused(true)}
                onBlur={() => setIsPasswordInputFocused(false)}
                value={password}
                onChange={(e: any) => setPassword(e.target.value)}
                type={inputType}
              />
              <S.StyledEye src={Eye} onClick={() => handleChangeInputType()} />
            </S.PassowrdInputContainer>
          </S.Row>
          <S.Row>
            <S.Label>{t("newPassword")}</S.Label>
            <S.PassowrdInputContainer isFocused={isNewPasswordInputFocused}>
              <S.PasswordInput
                onFocus={() => setIsNewPasswordInputFocused(true)}
                onBlur={() => setIsNewPasswordInputFocused(false)}
                value={newPassword}
                onChange={(e: any) => setNewPassword(e.target.value)}
                type={newInputType}
              />
              <S.StyledEye
                src={Eye}
                onClick={() => handleChangeNewInputType()}
              />
            </S.PassowrdInputContainer>
          </S.Row>
        </S.InputsContainer>
        <S.Actions>
          <S.StyledButton
            type="submit"
            disabled={isButtonDisabled}
            isDisabled={isButtonDisabled}
          >
            {t("ctaText")}
          </S.StyledButton>
        </S.Actions>
      </S.Wrapper>
    </GameContainer>
  );
};

export default PasswordChange;
