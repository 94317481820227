import { useNavigate } from "react-router-dom";
import GameContainer from "../layout/GameContainer";
import { useTranslation } from "react-i18next";
import * as S from "./Payment.styles";

const SuccessPayment = () => {
  const navigate = useNavigate();
  const handleBackToProfile = () => {
    navigate("/settings");
  };

  const { t } = useTranslation("translation", { keyPrefix: "congratsForm" });

  return (
    <GameContainer isNav={false}>
      <S.Wrapper>
        <div style={{ marginBottom: 16 }}>{t("congratulation")}</div>
        <S.StyledButton onClick={() => handleBackToProfile()}>
          {t("goBack")}
        </S.StyledButton>
      </S.Wrapper>
    </GameContainer>
  );
};

export default SuccessPayment;
