import { useEffect, useState } from "react";
import {
  createNewCategoryLevel,
  getCategoryLevelsData,
} from "../../api/common-api";
import { ICategory, ISelectOption } from "../../utils/types";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import GameContainer from "../layout/GameContainer";
import * as S from "./cms.styles";

const CategoryLevelsList = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [categories, setAllCategories] = useState<ICategory[]>([]);
  const [categoryLevels, setAllCategoryLevels] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<ISelectOption>();
  const [newCategoryLevel, setNewCategoryLevel] = useState("");
  const [file, setFile] = useState<File>();

  const categoriesOptions = categories.map((c: ICategory) => ({
    value: c.id,
    label: c.name,
  }));

  const getData = async () => {
    const res = await getCategoryLevelsData();
    const { categories, categoryLevels } = res;
    setAllCategories(categories);
    setAllCategoryLevels(categoryLevels);
  };

  const handleAddNewCategoryLevel = async () => {
    if (!selectedCategory?.value || !newCategoryLevel) {
      return;
    }
    setIsLoading(true);
    await createNewCategoryLevel({
      data: { categoryId: selectedCategory?.value, level: newCategoryLevel },
      file,
    });
    await getData();
    setNewCategoryLevel("");
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getData();
    setIsLoading(false);
  }, []);

  const isNewCategoryLevelButtonDisabled =
    !selectedCategory || !newCategoryLevel || !file;

  const handleNavigateToCategoryLevels = (id: string) => {
    navigate(`/cms/category-levels/${id}`);
  };

  return (
    <GameContainer isLoading={isLoading} backTo="/cms">
      <S.Wrapper>
        <S.Title>Dodaj poziomy</S.Title>
        <S.Group>
          <S.Row>
            <S.Label>Kategoria</S.Label>
            <Select
              options={categoriesOptions}
              value={selectedCategory}
              onChange={(e: any) => setSelectedCategory(e)}
              placeholder="Wybierz kategorie"
            />
          </S.Row>
          <S.Row>
            <S.Label>Numer</S.Label>
            <S.Input
              value={newCategoryLevel}
              onChange={(e: any) => setNewCategoryLevel(e.target.value)}
            />
          </S.Row>
          <S.Row>
            <S.Label>Obrazek:</S.Label>
            <S.Input
              onChange={(e: any) => setFile(e.target.files[0])}
              type="file"
            />
          </S.Row>
        </S.Group>
        <S.Button
          onClick={() => handleAddNewCategoryLevel()}
          disabled={isNewCategoryLevelButtonDisabled}
        >
          Dodaj
        </S.Button>
        <S.Title>Poziomy kategorii</S.Title>
        <S.Group>
          <div>
            {categoryLevels.map((c: any, index: number) => (
              <div key={index} style={{ display: "flex", gap: "8px" }}>
                <S.Name>{c.categoryName}</S.Name>
                <S.Name>{c.levels.map((a: any) => a.level).join(", ")}</S.Name>
                <S.Button onClick={() => handleNavigateToCategoryLevels(c.id)}>
                  Edytuj
                </S.Button>
              </div>
            ))}
          </div>
        </S.Group>
      </S.Wrapper>
    </GameContainer>
  );
};

export default CategoryLevelsList;
