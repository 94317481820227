import { useNavigate } from "react-router-dom";
import GameContainer from "../components/layout/GameContainer";
import Logo from "../assets/common/Logo2.png";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../App";
import * as S from "./welcome-page.styles";

const WelcomePage = () => {
  const context = useContext(AuthContext);
  const [timerCount, setTimerCount] = useState(1);
  const { isUserLoggedIn } = context;
  const navigate = useNavigate();
  const handleOnClick = () => {
    navigate(isUserLoggedIn ? "/profile" : "/start");
  };

  useEffect(() => {
    const timer =
      timerCount > 0 && setInterval(() => setTimerCount(timerCount - 1), 1000);
    ///@ts-ignore
    return () => clearInterval(timer);
  }, [timerCount]);

  useEffect(() => {
    if (timerCount === 0) {
      handleOnClick();
    }
  }, [timerCount]);

  return (
    <GameContainer isNav={false}>
      <S.Wrapper onClick={() => handleOnClick()}>
        <S.LogoContainer>
          <S.Logo src={Logo} />
        </S.LogoContainer>
      </S.Wrapper>
    </GameContainer>
  );
};

export default WelcomePage;
