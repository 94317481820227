import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const HeaderContainer = styled.div``;

export const StyledHeader = styled.h3`
  margin: 0;
  font-size: 24px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StyledButton = styled.button<{ isDisabled?: boolean }>`
  font-family: Nunito;
  font-weight: 700;
  display: flex;
  border: none;
  width: 100%;
  height: 100%;
  max-width: 140px;
  max-height: 140px;
  border-radius: 32px;
  padding: 8px;
  cursor: ${({ isDisabled }) => (isDisabled ? "auto" : "pointer")};
  align-items: center;
  justify-content: center;
  opacity: ${({ isDisabled }) => (isDisabled ? "0.3" : "1")};
  transition: 400ms;
  background-color: rgb(117 93 243);
  color: white;
  margin: 0 auto;
  &:hover&:not([disabled]) {
    box-shadow: 0 0 12px 12px rgb(254 171 7);
  }
  @media (min-width: 1200px) {
    max-width: 200px;
    max-height: 200px;
    padding: 20px;
  }
`;
