import styled from "styled-components";
import Cover from "../../assets/common/Background.svg";
import { PiGearSixFill } from "react-icons/pi";
import { FaHome } from "react-icons/fa";

export const Wrapper = styled.div<{ $isGame?: boolean }>`
  display: flex;
  border-radius: 10px;
  position: relative;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 90%;
  height: ${({ $isGame }) => ($isGame ? "100%" : "auto")};
  @media (min-width: 768px) {
    width: 96%;
  }
`;

// export const Img = styled.img`
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
//   opacity: 0.9;
// `;

export const Background = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0.9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: url(${Cover});
  background-repeat: repeat-y;
  background-size: cover;
  background-position: center center;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
  gap: 8px;
  max-width: 74px;
  max-height: 74px;
  @media (min-width: 768px) {
    top: 30px;
    left: 48px;
  }
`;

export const ContinueNav = styled(Nav)`
  top: 50%;
  right: 0;
  left: auto;
`;

export const NavButton = styled.button`
  background-color: #f1c40f;
`;

export const BackButton = styled.img<{ isRotated?: boolean }>`
  cursor: pointer;
  max-height: 42px;
  transform: ${({ isRotated }) => (isRotated ? "rotate(180deg)" : 0)};
  @media (min-width: 768px) and (min-height: 600px) {
    max-width: 100%;
    max-height: 100%;
    max-height: 74px;
    max-width: 74px;
  }
  transition: 400ms;
  border-radius: 50%;
  &:hover {
    filter: drop-shadow(0 0 12px rgb(254 171 7));
  }
`;

export const HomeButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: white;
  max-height: 36px;
  min-height: 36px;
  min-width: 36px;
  transition: 400ms;
  border-radius: 50%;
  &:hover {
    box-shadow: 0 0 8px 8px rgb(254 171 7);
  }
  @media (min-width: 768px) and (min-height: 600px) {
    max-width: 100%;
    max-height: 100%;
    max-height: 64px;
    max-width: 64px;
    min-width: 64px;
    min-height: 64px;
  }
`;

export const HomeButton = styled(FaHome)`
  cursor: pointer;
  max-height: 42px;
  min-height: 42px;
  color: rgb(117 93 243);
  @media (min-width: 768px) and (min-height: 600px) {
    max-width: 100%;
    max-height: 100%;
    min-width: 74px;
    max-height: 74px;
    max-width: 74px;
  }
`;

export const CloseButton = styled(NavButton)`
  margin-right: 20px;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RacoonContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
`;

export const StyledImage = styled.img`
  max-height: 20vh;
  @media (min-width: 1024px) {
    max-height: none;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  z-index: 2;
  position: absolute;
  top: -26px;
`;

export const Logo = styled.img`
  max-height: 20vh;
`;

export const FoxContainer = styled.div`
  position: absolute;
  bottom: -6px;
  left: 0;
`;

export const BubbleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 5;
  top: -52px;
  right: 182px;
  width: 100px;
  height: 100px;
  @media (min-width: 1024px) {
    top: -126px;
    right: 300px;
  }
`;

export const SpeachBubbleContainer = styled.div`
  display: flex;
  position: absolute;
  width: 126px;
  height: 226px;
  top: -60px;
  right: 124px;
  justify-content: center;
  @media (min-width: 1024px) {
    width: 370px;
    height: 226px;
    top: -126px;
    right: 300px;
  }
`;

export const SpeachBubbleText = styled.span`
  display: flex;
  align-items: center;
  z-index: 1;
  color: #153a5c;
  text-align: center;
  font-size: 16px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 900;
  max-height: calc(100% - 165px);
  margin-bottom: 10px;
  @media (min-width: 1024px) {
    font-size: 56px;
    max-height: calc(100% - 50px);
  }
`;

export const SpeachBubble = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  @media (min-width: 1024px) {
    max-height: none;
  }
`;

export const RacoonWithSpeachBubbleContainer = styled.div`
  display: flex;
  position: relative;
`;

export const AvatarAndLocaleContainer = styled.div`
  display: flex;
  gap: 16px;
  position: absolute;
  align-items: center;
  top: 20px;
  right: 20px;
  z-index: 2;
  @media (min-width: 768px) {
    top: 30px;
    right: 48px;
  }
`;

export const GearIcon = styled(PiGearSixFill)`
  bottom: 16px;
  width: 60px;
  height: 60px;
  color: rgb(117 93 243);
  cursor: pointer;
  transition: 400ms;
  border-radius: 50%;
  &:hover {
    filter: drop-shadow(0 0 8px rgb(254 171 7));
  }
`;

export const LogoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  bottom: 100px;
  color: rgb(117 93 243);
  cursor: pointer;
`;

export const LogoutButton = styled.button`
  font-family: Nunito;
  color: white;
  display: flex;
  justify-content: center;
  border: none;
  cursor: pointer;
  font-size: ${(props) => props.theme.buttonFontSize};
  font-weight: 800;
  align-items: center;
  background-color: rgb(117 93 243);
  border-radius: 8px;
  padding: 2px 4px;
  transition: 400ms;
  &:hover {
    box-shadow: 0 0 12px 12px rgb(254 171 7);
  }
`;
