import styled from "styled-components";

export const Wrapper = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  gap: 20px;
  border-radius: 24px;
  width: 100%;
  padding: 16px;
  max-width: 280px;
  position: relative;
  @media (min-width: 768px) and (min-height: 600px) {
    padding: 40px;
    max-width: 650px;
  }
  @media (min-height: 500px) {
    gap: 32px;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 24px;
  justify-content: center;
`;

export const Label = styled.label`
  font-family: Nunito;
  font-size: ${(props) => props.theme.labelFontSize};
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  white-space: nowrap;
  min-width: 60px;
  max-width: 70px;
  width: 100%;
  white-space: break-spaces;
`;

export const TitleContainer = styled.div`
  display: flex;
`;

export const Title = styled.h1`
  margin: 0;
  font-family: Nunito;
  font-size: ${(props) => props.theme.headerFontSize};
  font-weight: 900;
  line-height: 43px;
  text-align: center;
`;

export const PassowrdInputContainer = styled.div<{ isFocused?: boolean }>`
  display: flex;
  border: ${({ isFocused }) =>
    isFocused
      ? "3px solid rgb(117 93 243);"
      : "3px solid rgba(224, 224, 224, 1)"};
  border-radius: 8px;
  padding: 10px 8px;
  width: 100%;
  max-width: 372px;
  @media (min-height: 500px) {
    padding: 20px 16px;
  }
`;

export const EyeContainer = styled.div``;

export const StyledEye = styled.img`
  cursor: pointer;
  width: 29px;
  height: 21px;
`;

export const Input = styled.input`
  border: 3px solid rgba(224, 224, 224, 1);
  border-radius: 8px;
  padding: 10px 8px;
  font-family: Nunito;
  font-size: ${(props) => props.theme.labelFontSize};
  font-weight: 600;
  line-height: 24px;
  max-width: 372px;
  width: 100%;
  outline: none;
  &:focus {
    border: 3px solid rgb(117 93 243);
  }
  @media (min-height: 500px) {
    padding: 20px 16px;
  }
`;

export const PasswordInput = styled(Input)`
  border: none;
  border-radius: none;
  padding: 0;
  &:focus {
    border: none;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

export const StyledButton = styled.button<{ isDisabled: boolean }>`
  background-color: rgb(117 93 243);
  color: white;
  border-radius: 32px;
  max-width: 410px;
  width: 100%;
  padding: 12px 0;
  border: 3px solid white;
  cursor: ${({ isDisabled }) => (isDisabled ? "auto" : "pointer")};
  font-family: Nunito;
  font-size: ${(props) => props.theme.buttonFontSize};
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0.01em;
  transition: 300ms;
  opacity: ${({ isDisabled }) => (isDisabled ? "0.3" : "1")};
  &:hover&:not([disabled]) {
    box-shadow: 0 0 8px rgb(117 93 243);
  }
  @media (min-height: 500px) {
    padding: 24px 0;
  }
`;

export const BirdContainer = styled.div`
  position: absolute;
  top: -103px;
  right: 60px;
`;

export const StyledImage = styled.img`
  max-width: 100px;
  max-height: 106px;
`;

export const ForgotPasswordText = styled.div`
  font-family: Nunito;
  color: rgb(117 93 243);
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
`;
